import * as React from "react";

import {
	IconButton,
	Menu,
	MenuItem,
	Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import DeleteConfirmationDialog from "../DeleteConfirmationDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EditSchoolApplicationDialog from "./EditSchoolApplicationDialog";
import EditUniversityApplicationDialog from "./EditUniversityApplicationDialog";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { deleteApplication } from "../../store/universityApplication.slice";
import { deleteSchoolApplication } from "../../store/schoolApplication.slice";

export default function ActionsPopover(props) {

	const dispatch = useDispatch();

	const { application, applicationType, programById, uuid } = props;
	const applicationState = useSelector((state) => state.universityApplication);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [openDialog, setOpenDialog] = React.useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleEdit = () => {
		handleClose();
		setOpenDialog(true);
		// Add your edit logic here
	};

	const handleDelete = () => {
		handleClose();
		setOpenDeleteDialog(true);
	};

	const getDeleteConfirmationText = (application) => {
		return (
			<>
				Are you sure you want to delete the application of {" "}
				<Typography component="span" sx={{ fontWeight: "bold" }}>
					{application.student}
				</Typography> for {" "} <em>{application.university || application.school.name}</em>
				?
			</>
		);
	};

	const handleDeleteConfirm = (applicationId) => {

		if (applicationType === "university") {
			dispatch(deleteApplication(applicationId)).then((response) => {
				if (response.type === "universityApplication/deleteApplication/fulfilled") {
					setOpenDeleteDialog(false);
				}
			});
		} else {
			dispatch(deleteSchoolApplication({ id: applicationId, uuid })).then((response) => {
				if (response.type === "school_application/deleteSchoolApplication/fulfilled") {
					setOpenDeleteDialog(false);
				}
			});
		}
	};

	return (
		<React.Fragment>
			<IconButton onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<MenuItem
					onClick={handleEdit}
					sx={{ display: "flex", alignItems: "center" }}
				>
					<EditIcon sx={{ marginRight: "8px" }} />
					Edit
				</MenuItem>
				<MenuItem
					aria-hidden="false"
					onClick={handleDelete}
					sx={{ display: "flex", alignItems: "center" }}
				>
					<DeleteIcon sx={{ marginRight: "8px" }} />
					Delete
				</MenuItem>
			</Menu>
			{applicationType === "university" && (
				<EditUniversityApplicationDialog
					application={application}
					open={openDialog}
					handleClose={setOpenDialog}
				/>
			)}
			{applicationType === "school" && (
				<EditSchoolApplicationDialog
					application={application}
					open={openDialog}
					handleClose={setOpenDialog}
					programById={programById}
					uuid={uuid}
				/>
			)}
			<DeleteConfirmationDialog
				open={openDeleteDialog}
				handleClose={() => setOpenDeleteDialog(false)}
				onConfirm={() => handleDeleteConfirm(application.application_id)}
				contentText={getDeleteConfirmationText(application)}
				state={applicationState}
			/>
		</React.Fragment>
	);
}
