import * as React from "react";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import NewCountrySelect from "../common/NewCountrySelect";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { addExternalEvent } from "../../store/externalEvent.slice";
import { useDispatch } from "react-redux";

function AddExternalEventDialog(props) {
    const dispatch = useDispatch();

    const [form, setForm] = React.useState({
        name: "",
        start_date: "",
        end_date: "",
        city: "",
        country: "",
        website: "",
    });
    const [formErrors, setFormErrors] = React.useState({});
    const [isFormValid, setIsFormValid] = React.useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const errors = validateForm();
        const isValid = !Object.values(errors).some((error) => error);

        setFormErrors(errors);
        setIsFormValid(isValid);

        if (isValid) {
            dispatch(addExternalEvent({ event: form, uuid: props.uuid })).then((response) => {
                if (response.type === "externalEvent/addExternalEvent/fulfilled") {
                    handleCloseDialog();
                }
            });
        }
    };

    const validateForm = () => {
        let errors = {};
        if (!form.name.trim()) errors.name = "Name is required";
        if (!form.start_date.trim()) errors.start_date = "Start date is required";
        if (!form.end_date.trim()) errors.end_date = "End date is required";
        if (!form.city.trim()) errors.city = "City is required";
        if (!form.country.trim()) errors.country = "Country is required";
        if (form.website.trim() && !/^https?:\/\/[^\s$.?#].[^\s]*$/.test(form.website))
            errors.website = "A valid website URL is required";

        return errors;
    };

    const handleChange = (field, value) => {
        setForm({ ...form, [field]: value });

        let errors = { ...formErrors };
        if (!value.trim()) {
            errors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
        } else {
            errors[field] = "";
        }

        if (field === "website" && value.trim() && !/^https?:\/\/[^\s$.?#].[^\s]*$/.test(value)) {
            errors.website = "A valid website URL is required";
        }

        setFormErrors(errors);
        setIsFormValid(Object.values(errors).every((error) => !error));
    };

    const handleCloseDialog = () => {
        props.setOpenDialog(false);
        setForm({
            name: "",
            start_date: "",
            end_date: "",
            city: "",
            country: "",
            website: "",
        });
        setFormErrors({});
        setIsFormValid(false);
    };

    return (
        <Dialog
            open={props.open}
            onClose={handleCloseDialog}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">Add External Event</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please fill out the details below to add a new external event.
                </DialogContentText>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    {props.externalEventState.error && (
                        <Alert sx={{ width: "100%", mt: 2, mb: 2 }} severity="error">
                            {props.externalEventState.error}
                        </Alert>
                    )}
                    <Grid container spacing={2}>
                        {[
                            { label: "Event Name", field: "name", type: "text", xs: 12, required: true },
                            {
                                label: "Start Date",
                                field: "start_date",
                                type: "date",
                                xs: 6,
                                required: true,
                                inputProps: {
                                    max: form.end_date || undefined, // Disable dates after end_date
                                },
                            },
                            {
                                label: "End Date",
                                field: "end_date",
                                type: "date",
                                xs: 6,
                                required: true,
                                inputProps: {
                                    min: form.start_date || undefined, // Disable dates before start_date
                                },
                            },
                            { label: "City", field: "city", type: "text", xs: 6, required: true },
                            { label: "Country", field: "country", type: "text", xs: 6, required: true },
                            { label: "Website", field: "website", type: "url", xs: 12, required: false },
                        ].map(({ label, field, type, xs, required, inputProps }) => (
                            <Grid item xs={xs} key={field}>
                                {field !== "country" ? (
                                    <TextField
                                        required={required}
                                        fullWidth
                                        label={label}
                                        type={type}
                                        name={field}
                                        id={field}
                                        value={form[field]}
                                        onChange={(e) => handleChange(field, e.target.value)}
                                        error={Boolean(formErrors[field])}
                                        helperText={formErrors[field]}
                                        InputLabelProps={
                                            type === "date" ? { shrink: true } : undefined // Ensure proper behavior for date picker
                                        }
                                        inputProps={inputProps}
                                    />
                                ) : (
                                    <NewCountrySelect
                                        name="country"
                                        label="Country"
                                        value={form[field]}
                                        onChange={(e) => handleChange(field, e.target.value)}
                                        fullWidth
                                        required
                                    />
                                )}
                            </Grid>
                        ))}
                    </Grid>
                    <DialogActions sx={{ justifyContent: "flex-start", px: 0 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={!isFormValid}
                        >
                            Save
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            color="error"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleCloseDialog}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

AddExternalEventDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpenDialog: PropTypes.func.isRequired,
    externalEventState: PropTypes.object.isRequired,
    uuid: PropTypes.string.isRequired,
};

export default AddExternalEventDialog;
