import * as React from "react";

import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { formatDate } from "../Leads/utils";
import schoolApplicationColumns from "./schoolApplicationColumn";

export default function SchoolApplicationTable(props) {
    const { applicationState, courseById, employeeById, programById, schoolById, setUrlParams, uuid } = props;
    const [expandedRows, setExpandedRows] = React.useState([]);

    const rows = React.useMemo(() => {
        // Group applications by lead_id to ensure only one lead row per lead
        const groupedApplications = applicationState.applications.reduce((acc, application) => {
            const leadId = application.lead.id;
            if (!acc[leadId]) {
                acc[leadId] = [];
            }
            acc[leadId].push(application);
            return acc;
        }, {});

        // Flatten the grouped applications to create lead rows and corresponding application rows
        return Object.keys(groupedApplications).flatMap((leadId) => {
            const applications = groupedApplications[leadId];
            const lead = applications[0].lead; // Use the lead's name from the first application
            const leadRow = {
                id: leadId,
                student: lead.student.full_name,
                school: "",
                school_course: "",
                offer_letter: "",
                start_date: "",
                end_date: "",
                email: lead.student.email, // No email on the lead row itself
                phone_number: lead.student.phone_number, // No phone number on the lead row itself
                nationality: lead.student.nationality, // No nationality on the lead row itself
                added_by: "",
                added_at: "",
                updated_by: "",
                updated_at: "",
                status: "", // No status on the lead row itself
                application_id: "",
                isLead: true, // This is the lead row
            };

            // Generate the application rows for each application in the current lead's group
            const applicationRows = applications.map((application, index) => {
                return {
                    id: `${leadId}-${index}`,
                    parentId: leadId,
                    student: "",
                    school: schoolById.get(application.school),
                    school_course: courseById.get(application.school_course),
                    offer_letter: application.offer_letter,
                    start_date: application.start_date,
                    end_date: application.end_date || "",
                    email: "",
                    phone_number: "",
                    nationality: "",
                    added_by: employeeById.get(application.added_by).full_name,
                    added_at: formatDate(application.added_at),
                    updated_by: employeeById.get(application.updated_by).full_name,
                    updated_at: formatDate(application.updated_at),
                    status: application.status,
                    application_id: application.id,
                    isLead: false,
                };
            });

            // Return the lead row followed by the application rows
            return [leadRow, ...applicationRows];
        });
    }, [applicationState.applications, schoolById, courseById, employeeById]);

    const filteredRows = React.useMemo(() => {
        return rows.filter((row) => {
            if (row.isLead) {
                return true; // Always show lead rows
            }
            return expandedRows.includes(row.parentId);
        });
    }, [rows, expandedRows]);

    const handleExpandClick = React.useCallback(
        (leadId) => {
            setExpandedRows((prev) =>
                prev.includes(leadId)
                    ? prev.filter((id) => id !== leadId)
                    : [...prev, leadId]
            );
        },
        [setExpandedRows]
    );

    const getRowClassName = (params) => {
        return params.row.isLead ? "MuiDataGrid-row--lead" : "";
    };

    return (
        <Box sx={{ width: "100%" }}>
            <DataGrid
                rows={filteredRows}
                columns={schoolApplicationColumns(
                    expandedRows,
                    handleExpandClick,
                    programById,
                    setUrlParams,
                    uuid
                )}
                getRowClassName={getRowClassName}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            // Hide columns id, the other columns will remain visible
                            id: false,
                        },
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                autoHeight={true}
                pageSizeOptions={[10, 20, 50, 100]}
                checkboxSelection={false}
                disableRowSelectionOnClick
                sx={{
                    "& .MuiDataGrid-row": {
                        backgroundColor: "#ffffff", // White background for all rows
                    },
                    "& .MuiDataGrid-row--lead": {
                        backgroundColor: "#f5f5f5", // Different background color for lead rows
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        whiteSpace: "nowrap",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        whiteSpace: "nowrap",
                        overflow: "auto",
                    },
                }}
            />
        </Box>
    );
}
