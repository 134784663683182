import "../../styles/lead-details.css";

import * as React from "react";

import {
	Box,
	Card,
	CardContent,
	CardMedia,
	Chip,
	Grid,
	IconButton,
	Typography,
} from "@mui/material";
import {
	ENGLISH_TEST_STATUS_CHOICES,
	GRADE_SYSTEM_CHOICES,
	GRADE_TYPE_CHOICES,
} from "../../const";

import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";

/**
 * EligibilityCard is a component that displays lead eligibility details.
 *
 * @param {Object} props - Component props.
 * @param {function} props.setOpenEditEligibilityDialog - Function to toggle the Edit Eligibility Dialog.
 * @param {Object} props.lead - The lead data to display.
 */
const EligibilityCard = React.memo(({ setOpenEditEligibilityDialog, lead }) => {
	// Memoized values to avoid recalculations
	const englishTestStatus = React.useMemo(
		() => ENGLISH_TEST_STATUS_CHOICES.find((status) => status.value === lead.english_test_status),
		[lead.english_test_status]
	);

	const gradeType = React.useMemo(() => GRADE_TYPE_CHOICES[lead.grade_type], [lead.grade_type]);
	const gradeSystem = React.useMemo(
		() => GRADE_SYSTEM_CHOICES[lead.grade_system],
		[lead.grade_system]
	);

	// Helper function to render a Typography with conditional data
	const renderInfo = React.useCallback(
		(label, value, extra = "") => (
			<Typography variant="subtitle1" className="cardInfo" gutterBottom>
				{label}: {value || "N/A"} {extra}
			</Typography>
		),
		[]
	);

	return (
		<Grid item xs={12} md={6} sx={{ display: "flex" }}>
			<Card className="card" sx={{ flexGrow: 1 }}>
				<CardContent sx={{ flex: 1 }}>
					<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
						<Typography component="h2" variant="h5" className="title">
							Eligibility
						</Typography>
						<IconButton
							color="primary"
							onClick={() => setOpenEditEligibilityDialog(true)}
							aria-label="edit eligibility"
						>
							<EditIcon />
						</IconButton>
					</Box>
					{renderInfo("Grade Type", gradeType)}
					{renderInfo(
						"Grades",
						lead.gpa,
						gradeSystem ? <em>({gradeSystem})</em> : ""
					)}
					<Typography variant="subtitle1" className="cardInfo" gutterBottom>
						English Test Status:
						<Chip
							label={englishTestStatus?.label || "N/A"}
							color={englishTestStatus?.color || "default"}
							size="small"
							variant="outlined"
							sx={{ ml: 1 }}
						/>
					</Typography>
					{renderInfo("TOEFL Score", lead.toefl_score)}
					{renderInfo("IELTS Score", lead.ielts_score)}
					{renderInfo("Has Sponsorship", lead.has_sponsorship ? "Yes" : "No")}
					{lead.has_sponsorship &&
						renderInfo("Sponsorship Details", lead.sponsorship_details)}
				</CardContent>
				{lead.image && (
					<CardMedia
						component="img"
						sx={{ width: 160, display: { xs: "none", sm: "block" } }}
						image={lead.image}
						alt={lead.imageLabel || "Lead Image"}
					/>
				)}
			</Card>
		</Grid>
	);
});

EligibilityCard.propTypes = {
	setOpenEditEligibilityDialog: PropTypes.func.isRequired,
	lead: PropTypes.shape({
		grade_type: PropTypes.string,
		gpa: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		grade_system: PropTypes.string,
		english_test_status: PropTypes.string,
		toefl_score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		ielts_score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		has_sponsorship: PropTypes.bool,
		sponsorship_details: PropTypes.string,
		image: PropTypes.string,
		imageLabel: PropTypes.string,
	}).isRequired,
};

EligibilityCard.displayName = "EligibilityCard";

export default EligibilityCard;
