import { APPLICATION_STATUS_CHOICES, APPLICATION_YEAR_CHOICES, getFileNameFromUrl } from "../../const";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import { setError, updateUniversityApplication } from "../../store/universityApplication.slice";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EdvantageAutocomplete from "../common/EdvantageAutocomplete";
import React from "react";
import { Skeleton } from "@mui/material";
import { generateMenuItems } from "../../utils/Utils";
import { useEdvantageContext } from "../../EdvantageProvider";
import useUniversity from "../../hooks/useUniversity";

/**
 * A dialog to edit an application.
 *
 * @param {Object} props component props
 * @param {Object} props.application the application object to edit
 * @param {boolean} props.open whether the dialog is open or not
 * @param {Function} props.handleClose callback function to close the dialog
 *
 * @returns {React.Component} the edit application dialog component
 */
export default function EditUniversityApplicationDialog(props) {

    const { application, open, handleClose } = props;

    const dispatch = useDispatch();

    const applicationState = useSelector((state) => state.universityApplication);
    const { universities, universityStatus } = useUniversity();
    const { intakes, majorById, programById, uuid } = useEdvantageContext();

    const initialState = {
        status: application.status,
        fileName: getFileNameFromUrl(application.offer_letter),
        offer_letter: null,
        university: application.university_id,
        intake: application.intake_id,
        year: application.year,
        university_course: application.university_course,
    };

    const [form, setForm] = React.useState(initialState);
    const [formErrors, setFormErrors] = React.useState({});

    const [availableCourses, setAvailableCourses] = React.useState([]);

    React.useEffect(() => {
        const selectedUniversity = universities.find((university) => university.id === form.university);
        if (selectedUniversity) {
            const courses = selectedUniversity.courses;
            setAvailableCourses(courses.filter((course) => course.intakes.some((intake) => intake === form.intake)));
        } else {
            setAvailableCourses([]); // Handle the case where no matching university is found
        }
    }, [universities, form.university, form.intake]);

    React.useEffect(() => {
        if (open) {
            setForm({
                status: application.status,
                fileName: getFileNameFromUrl(application.offer_letter),
                offer_letter: null,
                university: application.university_id,
                intake: application.intake_id,
                year: application.year,
                university_course: application.university_course,
            });
            setFormErrors({});
            dispatch(setError(null));
        }
    }, [open, application]);

    const handleChange = (event) => {
        const { name, value } = event.target;

        const newForm = { ...form, [name]: value };
        if (name === "intake") {
            newForm.university_course = null;
        }

        setForm(newForm);

        const fieldErrors = validateFormField(name, value);
        setFormErrors((prevErrors) => {
            // If no error, remove it from the errors object
            if (!fieldErrors[name]) {
                const { [name]: removedError, ...restErrors } = prevErrors;
                return restErrors;
            }
            // Otherwise, add/retain the error
            return { ...prevErrors, ...fieldErrors };
        });
    };

    const [isFormValid, setIsFormValid] = React.useState(true);

    React.useEffect(() => {
        setIsFormValid(Object.values(formErrors).every((error) => !error));
    }, [formErrors]);

    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 5 MB
    const ALLOWED_FILE_TYPES = ["application/pdf", "image/*"];

    const validateFile = (file) => {
        if (!file) {
            return "Offer letter is required for this status";
        }
        if (file.size > MAX_FILE_SIZE) {
            return "File size exceeds the maximum limit.";
        } else if (!ALLOWED_FILE_TYPES.includes(file.type)) {
            return "Invalid file type. Please upload a PDF or image.";
        }
        return null;
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const error = file ? validateFile(file) : "Offer letter is required for this status";

        if (!error) {
            setForm((prevForm) => ({ ...prevForm, offer_letter: file, fileName: file.name }));
        } else {
            setForm((prevForm) => ({ ...prevForm, offer_letter: null }));
        }

        setFormErrors((prevErrors) => ({ ...prevErrors, offer_letter: error }));
    };

    const validateFormField = (field, value) => {
        const errors = {};

        switch (field) {
            case "status":
                if (!value) {
                    errors[field] = "Status is required";
                }
                break;
            case "intake":
                if (!value) {
                    errors[field] = "Intake is required";
                }
                break;
            case "year":
                if (!value) {
                    errors[field] = "Year is required";
                }
                break;
            case "university_course":
                if (!value) {
                    errors[field] = "Course is required";
                }
                break;
            case "offer_letter":
                if (["Conditional Offer", "Unconditional Offer"].includes(form.status) && !form.fileName) {
                    errors.offer_letter = "Offer letter is required for this status";
                }
                break;

            default:
                break;
        }
        return errors;
    };

    const handleCloseDialog = (evt, reason) => {
        if (reason === "backdropClick") {
            return;
        }

        dispatch(setError(null));
        setFormErrors({});
        handleClose(false);
    };

    const handleSave = (e) => {
        e.preventDefault();

        // Use reduce to accumulate form errors
        const newErrors = Object.keys(form).reduce((errors, field) => {
            const fieldError = validateFormField(field, form[field]);
            return { ...errors, ...fieldError }; // Merge field errors with existing errors
        }, {});

        setFormErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            return;
        }

        const formData = new FormData();
        formData.append("status", form.status);
        formData.append("university", form.university);
        formData.append("university_course", form.university_course);
        formData.append("intake", form.intake);
        formData.append("year", form.year);
        formData.append("uuid", uuid);
        if (form.offer_letter) {
            formData.append("offer_letter", form.offer_letter);
        }

        dispatch(
            updateUniversityApplication({
                id: application.application_id,
                application: formData,
            })
        ).then((response) => {
            if (response.type === "universityApplication/updateUniversityApplication/fulfilled") {
                handleCloseDialog();
            }
        });
    };

    const isFormChanged =
        form.status !== application.status ||
        form.university !== application.university_id ||
        form.intake !== application.intake_id ||
        form.year !== application.year ||
        form.university_course !== application.university_course ||
        form.offer_letter;

    const theme = useTheme();

    const shouldDisplayFileUpload =
        form.status === "Conditional Offer" ||
        form.status === "Unconditional Offer";

    return (

        <Dialog
            open={open}
            onClose={handleCloseDialog}
            sx={{
                "& .MuiDialog-paper": {
                    minWidth: {
                        xs: "100%", // full width on small screens
                        sm: "600px", // 600px on medium screens and up
                    },
                },
            }}
        >
            <DialogTitle>
                <Typography variant="body1" gutterBottom>
                    Applicant:{" "}
                    <strong>
                        {application.student || ""}
                    </strong>{" "}
                </Typography>
            </DialogTitle>
            <Divider />
            <DialogContent>
                {(applicationState.error) && (
                    <Typography
                        variant="body2"
                        color={theme.palette.error.main}
                        sx={{ mb: 2 }}
                    >
                        <em>
                            {applicationState.error}
                        </em>
                    </Typography>
                )}
                {universityStatus === "loading" ? (
                    <>
                        <Skeleton variant="rectangular" width="100%" height={56} />
                        <Skeleton variant="rectangular" width="100%" height={56} sx={{ mt: 2, mb: 2 }} />
                        <Skeleton variant="rectangular" width="100%" height={56} sx={{ mt: 2, mb: 2 }} />
                        <Skeleton variant="rectangular" width="100%" height={56} />
                    </>
                ) : (
                    <>
                        <TextField
                            select
                            fullWidth
                            label="Status"
                            name="status"
                            value={form.status}
                            onChange={handleChange}
                            error={Boolean(formErrors.status)}
                            helperText={formErrors.status}
                        >
                            {generateMenuItems(APPLICATION_STATUS_CHOICES)}
                        </TextField>
                        {shouldDisplayFileUpload && (
                            <Box mt={2} mb={2}>
                                <Button variant="contained" component="label" startIcon={<CloudUploadIcon />} fullWidth>
                                    Upload Offer Letter
                                    <input type="file" hidden onChange={handleFileChange} />
                                </Button>
                                {form.fileName && <p>{form.fileName}</p>}
                                {formErrors.offer_letter && <p style={{ fontSize: "0.75rem", color: '#d32f2f' }}>{formErrors.offer_letter}</p>}
                            </Box>
                        )}
                        <EdvantageAutocomplete
                            name="intake"
                            label="Intake"
                            value={form.intake}
                            onChange={handleChange}
                            error={Boolean(formErrors.intake)}
                            helperText={formErrors.intake}
                            options={intakes}
                            getOptionLabel={(option) => option.text}
                            getOptionValue={(newValue) => newValue.id}
                            isOptionEqualToValue={(option, value) =>
                                option.id === value
                            }
                            margin="dense"
                        />
                        <TextField
                            select
                            fullWidth
                            label="Year"
                            name="year"
                            margin="dense"
                            value={form.year}
                            onChange={handleChange}
                            error={Boolean(formErrors.year)}
                            helperText={formErrors.year}
                        >
                            {generateMenuItems(APPLICATION_YEAR_CHOICES)}
                        </TextField>
                        <EdvantageAutocomplete
                            name="university_course"
                            label="Course"
                            value={form.university_course}
                            onChange={handleChange}
                            error={Boolean(formErrors.university_course)}
                            helperText={formErrors.university_course}
                            options={availableCourses}
                            getOptionLabel={(option) =>
                                `${programById.get(option.program).name} - ${majorById.get(option.major).name}`
                            }
                            getOptionValue={(newValue) => newValue.id}
                            isOptionEqualToValue={(option, value) =>
                                option.id === value
                            }
                            margin="dense"
                            noOptionsText={
                                availableCourses.length === 0
                                    ? "No courses found for the selected intake."
                                    : "No options available"
                            }
                            required
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleCloseDialog}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleSave}
                    disabled={
                        !isFormChanged || !isFormValid ||
                        (shouldDisplayFileUpload && !form.fileName)
                    }
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
