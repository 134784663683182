import { Box, Button, Grid, IconButton, MenuItem, TextField } from "@mui/material";
import { ENGLISH_TEST_STATUS_CHOICES, GRADE_SYSTEM_CHOICES, GRADE_TYPE_CHOICES } from "../../const";
import React, { useEffect, useState } from 'react';
import { autoPopulateGPA, validateSubjectsGrades } from "./utils";
import { generateMenuItems, isValidGPA } from "../../utils/Utils";

import GenericMultiSelect from "../common/GenericMultiSelect";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useEdvantageContext } from "../../EdvantageProvider";

export default function AcademiaForm(props) {
	const { leadData, setLeadData, setIsAcademiaFormValid, isDraftLead } = props;

	const { intakes } = useEdvantageContext();

	const [showScores, setShowScores] = useState(false);
	const [showTestDate, setShowTestDate] = useState(false);
	const [formErrors, setFormErrors] = useState({});
	const [touched, setTouched] = useState({});

	const today = new Date().toISOString().split("T")[0];

	useEffect(() => {
		setShowScores(leadData.english_test_status === "Ready");
		setShowTestDate(
			["Ready", "Scheduled"].includes(leadData.english_test_status)
		);
	}, [leadData.english_test_status]);

	useEffect(() => {
		const academiaFormErrors = validateAcademiaForm();
		setIsAcademiaFormValid(Object.keys(academiaFormErrors).length === 0);
	}, [
		leadData.intakes,
		leadData.grade_type,
		leadData.grades_per_subject,
		leadData.grade_system,
		leadData.gpa,
		leadData.english_test_status,
		leadData.test_date,
		leadData.toefl_score,
		leadData.ielts_score,
		showScores,
		showTestDate,
	]); // eslint-disable-line react-hooks/exhaustive-deps

	const validateAcademiaForm = () => {
		const errors = {};
		if (!leadData.intakes.length)
			errors.intakes = "At least one intake is required";
		if (!leadData.grade_type)
			errors.grade_type = "Grade type is required";
		if (!leadData.grade_system)
			errors.grade_system = "Grade system is required";
		if (
			leadData.gpa === undefined ||
			leadData.gpa === null ||
			leadData.gpa === ""
		) {
			errors.gpa = "GPA is required";
		} else if (!isValidGPA(leadData.gpa, leadData.grade_system)) {
			errors.gpa = "GPA is not valid for the selected grading scale";
		}
		if (leadData.grade_type === "SUB" && leadData.grade_system) {
			Object.assign(errors, validateSubjectsGrades(leadData.grades_per_subject, leadData.grade_system));
		}

		if (!leadData.english_test_status)
			errors.english_test_status = "English test status is required";
		if (showTestDate && !leadData.test_date) {
			errors.test_date = "Test date is required";
		} else if (
			leadData.test_date &&
			((leadData.english_test_status === "Ready" &&
				leadData.test_date > today) ||
				(leadData.english_test_status === "Scheduled" &&
					leadData.test_date < today))
		) {
			errors.test_date = `Test date must be ${leadData.english_test_status === "Ready"
				? "today or earlier"
				: "today or later"
				}`;
		}
		// Conditionally validate TOEFL and IELTS scores if the English test status is 'Ready'
		if (leadData.english_test_status === "Ready") {
			if (!leadData.toefl_score && !leadData.ielts_score) {
				errors.toefl_score = "TOEFLscore is required";
				errors.ielts_score = "IELTS score is required";
			} else {
				if (leadData.toefl_score) {
					const toeflScore = parseInt(leadData.toefl_score, 10);
					if (isNaN(toeflScore) || toeflScore < 0 || toeflScore > 120) {
						errors.toefl_score = "TOEFL score must be a number between 0 and 120.";
					}
				}
				if (leadData.ielts_score) {
					const ieltsScore = parseFloat(leadData.ielts_score);
					if (isNaN(ieltsScore) || ieltsScore < 4.0 || ieltsScore > 9.0) {
						errors.ielts_score = "IELTS score must be a number between 4.0 and 9.0.";
					}
				}
			}
		}

		setFormErrors(errors);
		return errors;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;


		// Check if the grade_type has changed and reset the GPA if necessary
		if (name === "grade_type" && value !== leadData.grade_type) {
			const numSubjects = leadData.grades_per_subject.length;
			if (value === "SUB") {
				setLeadData({
					...leadData,
					[name]: value,
					gpa: "",
					grades_per_subject: [{ subject: "", grade: "" }],
				});
			} else {
				// Remove errors related to the subjects and grades from formErrors
				const newFormErrors = { ...formErrors };
				const newTouched = { ...touched };
				for (let i = 0; i < numSubjects; i++) {
					delete newFormErrors[`subject_${i}`];
					delete newFormErrors[`grade_${i}`];
					delete newTouched[`subject_${i}`];
					delete newTouched[`grade_${i}`];
				}
				setFormErrors(newFormErrors);
				setTouched(newTouched);
				setLeadData({ ...leadData, [name]: value, gpa: "", grades_per_subject: [] }); // Reset GPA when grade_type changes
			}
		} else {
			setLeadData({ ...leadData, [name]: value });
			// Mark the field as touched
			setTouched({ ...touched, [name]: true });
		}
	};

	// Handle adding a new subject-grade pair
	const handleAddSubject = () => {
		const new_grades_per_subject = [...leadData.grades_per_subject, { subject: "", grade: "" }];
		setLeadData({ ...leadData, grades_per_subject: new_grades_per_subject });
	};

	// Handle removing a subject-grade pair
	const handleRemoveSubject = (index) => {
		const new_grades_per_subject = [...leadData.grades_per_subject];
		new_grades_per_subject.splice(index, 1);

		// Remove the corresponding grade key from formErrors
		const newFormErrors = { ...formErrors };
		delete newFormErrors[`subject_${index}`];
		delete newFormErrors[`grade_${index}`];

		const newTouched = { ...touched };
		delete newTouched[`subject_${index}`];
		delete newTouched[`grade_${index}`];

		setLeadData({ ...leadData, grades_per_subject: new_grades_per_subject });
		setFormErrors(newFormErrors);
		setTouched(newTouched);
	};

	const handleSubjectGradeChange = (index, field, value) => {
		const updatedSubjectsGrades = [...leadData.grades_per_subject];
		updatedSubjectsGrades[index][field] = value;
		setLeadData({ ...leadData, grades_per_subject: updatedSubjectsGrades });
		setTouched({ ...touched, [`${field}_${index}`]: true });
	}

	useEffect(() => {
		if (leadData.grade_type === "SUB" && leadData.grades_per_subject.length > 0) {
			// Update leadData with the calculated GPA
			setLeadData({ ...leadData, gpa: autoPopulateGPA(leadData.grades_per_subject, leadData.grade_system) });
		}
	}, [leadData.grade_type, leadData.grades_per_subject, leadData.grade_system]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<GenericMultiSelect
					name="intakes"
					label="Intakes"
					value={leadData.intakes}
					onChange={handleChange}
					error={Boolean((touched.intakes || isDraftLead) && formErrors.intakes)}
					helperText={(touched.intakes || isDraftLead) && formErrors.intakes}
					options={intakes.filter((intake) => intake.is_active === true)}
					getOptionLabel={(option) => option.season}
					optionValueKey="id"
					required
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField
					select
					name="grade_type"
					label="Grade Type"
					value={leadData.grade_type}
					onChange={handleChange}
					error={Boolean((touched.grade_type || isDraftLead) && formErrors.grade_type)}
					helperText={(touched.grade_type || isDraftLead) && formErrors.grade_type}
					fullWidth
					required
				>
					{generateMenuItems(GRADE_TYPE_CHOICES)}
				</TextField>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField
					select
					name="grade_system"
					label="Grade System"
					value={leadData.grade_system}
					onChange={handleChange}
					error={Boolean(
						(touched.grade_system || isDraftLead) && formErrors.grade_system
					)}
					helperText={(touched.grade_system || isDraftLead) && formErrors.grade_system}
					required
					fullWidth
				>
					{generateMenuItems(GRADE_SYSTEM_CHOICES)}
				</TextField>
			</Grid>
			{leadData.grade_system && leadData.grade_type === "SUB" && leadData.grades_per_subject.map((item, index) => (
				<React.Fragment key={index}>
					<Grid item xs={6}>
						<TextField
							select
							label="Subject"
							value={item.subject}
							onChange={(e) => handleSubjectGradeChange(index, "subject", e.target.value)}
							error={Boolean(
								(touched[`subject_${index}`] || isDraftLead) &&
								formErrors[`subject_${index}`]
							)}
							helperText={
								(touched[`subject_${index}`] || isDraftLead) &&
								formErrors[`subject_${index}`]
							}
							fullWidth
						>
							{/* Replace with actual subject options */}
							<MenuItem value="Math">Math</MenuItem>
							<MenuItem value="Science">Science</MenuItem>
							<MenuItem value="English">English</MenuItem>
						</TextField>
					</Grid>
					<Grid item xs={5}>
						<TextField
							label="Grade"
							value={item.grade}
							onChange={(e) => handleSubjectGradeChange(index, "grade", e.target.value)}
							type="text"
							error={Boolean(
								(touched[`grade_${index}`] || isDraftLead) &&
								formErrors[`grade_${index}`]
							)}
							helperText={
								(touched[`grade_${index}`] || isDraftLead) &&
								formErrors[`grade_${index}`]
							}
							fullWidth
						/>
					</Grid>
					{/* Add/Remove Button */}
					<Grid item xs={1}>
						<Box
							display="flex"
							alignItems="center"
							justifyContent="flex-end"
						>
							<IconButton
								color="secondary"
								onClick={() => handleRemoveSubject(index)}
							>
								<RemoveCircleOutlineIcon sx={{ color: "#d32f2f" }} fontSize="large" />
							</IconButton>
						</Box>
					</Grid>
				</React.Fragment>
			))}
			{leadData.grade_system && leadData.grade_type === "SUB" && (
				<Button
					variant="contained"
					color="primary"
					onClick={handleAddSubject}
					sx={{ mt: 2, ml: 2 }}
					disabled={leadData.grades_per_subject.length > 5}
				>
					Add more grades
				</Button>
			)}
			<Grid item xs={12}>
				<TextField
					name="gpa"
					label="GPA"
					type="text"
					value={leadData.gpa}
					onChange={handleChange}
					error={Boolean((touched.gpa || isDraftLead) && formErrors.gpa)}
					helperText={(touched.gpa || isDraftLead) && formErrors.gpa}
					disabled={!leadData.grade_system || leadData.grade_type === "SUB"}
					autoComplete="off"
					required
					fullWidth
				/>
			</Grid>

			<Grid item xs={12}>
				<TextField
					select
					name="english_test_status"
					label="English Test Status"
					value={leadData.english_test_status}
					onChange={handleChange}
					error={Boolean(
						(touched.english_test_status || isDraftLead) &&
						formErrors.english_test_status
					)}
					helperText={
						(touched.english_test_status || isDraftLead) &&
						formErrors.english_test_status
					}
					required
					fullWidth
				>
					{ENGLISH_TEST_STATUS_CHOICES.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			{showTestDate && (
				<Grid item xs={12}>
					<TextField
						name="test_date"
						label="Test Date"
						type="date"
						value={leadData.test_date}
						onChange={handleChange}
						error={Boolean(
							(touched.test_date || isDraftLead) && formErrors.test_date
						)}
						helperText={(touched.test_date || isDraftLead) && formErrors.test_date}
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{
							min:
								leadData.english_test_status === "Scheduled"
									? today
									: undefined,
							max:
								leadData.english_test_status === "Ready"
									? today
									: undefined,
						}}
						fullWidth
					/>
				</Grid>
			)}
			{showScores && (
				<>
					<Grid item xs={12}>
						<TextField
							name="toefl_score"
							label="TOEFL Score"
							type="number"
							value={leadData.toefl_score}
							onChange={handleChange}
							error={Boolean(
								(touched.toefl_score || isDraftLead) && formErrors.toefl_score
							)}
							helperText={
								(touched.toefl_score || isDraftLead) && formErrors.toefl_score
							}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							name="ielts_score"
							label="IELTS Score"
							type="number"
							value={leadData.ielts_score}
							onChange={handleChange}
							error={Boolean(
								(touched.ielts_score || isDraftLead) && formErrors.ielts_score
							)}
							helperText={
								(touched.ielts_score || isDraftLead) && formErrors.ielts_score
							}
							fullWidth
						/>
					</Grid>
				</>
			)}
		</Grid>
	);
}
