import {
    Alert,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Snackbar,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { clearMessage, deleteSchool } from "../../store/school.slice";
import { useDispatch, useSelector } from "react-redux";

import AddSchool from "../School/AddSchool";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog";
import { FilterList } from "@mui/icons-material";
import FilterSchoolCollapse from "../School/FilterSchoolCollapse";
import { PROGRAM_CATEGORY_CHOICES } from "../../const";
import SchoolDetails from "../School/SchoolDetails";
import SchoolTable from "../School/SchoolTable";
import { useEdvantageContext } from "../../EdvantageProvider";
import useSchool from "../../hooks/useSchool";

const School = ({ isGoogleMapsScriptLoaded, urlParams, setUrlParams }) => {

    const {
        programs, programById, currencies, artifactLoading
    } = useEdvantageContext();

    useEffect(() => {
        // Disable scroll event globally when School component is active
        window.disableScroll = true;

        // Clean up when the component is unmounted
        return () => {
            window.disableScroll = false;
        };
    }, []);

    const [searchFilters, setSearchFilters] = useState({
        name: "",
        majors: [],
        programs: [],
        city: "",
        countries: [],
        is_sponsored: "",
    });
    const [filterCourse, setFilterCourse] = useState(false);
    const [filterBoxVisible, setFilterBoxVisible] = useState(false);

    const dispatch = useDispatch();
    const schoolState = useSelector((state) => state.school);
    const { schools } = useSchool();

    const [deleteUni, setDeleteUni] = React.useState(null);

    const getDeleteConfirmationText = (school) => {
        return (
            <>
                Are you sure you want to delete the school {" "}
                <Typography component="span" sx={{ fontWeight: "bold" }}>
                    {school.name}
                </Typography>?
            </>
        );
    };

    const handleDelete = (schoolId) => {
        setDeleteUni(schools.find((school) => school.id === schoolId));
    };

    const handleDeleteConfirm = (schoolId) => {
        dispatch(deleteSchool(schoolId)).then((response) => {
            if (response.type === "school/deleteSchool/fulfilled") {
                setDeleteUni(null);
            }
        });
    };

    const handleSearchChange = (field) => (event, newValue) => {
        const newSearchFilters = {
            ...searchFilters,
            [field]: ["majors", "programs", "countries"].includes(field)
                ? newValue.map((option) => option.name)
                : event.target.value,
        };
        setSearchFilters(newSearchFilters);
        setFilterCourse(
            newSearchFilters.majors.length ||
            newSearchFilters.programs.length ||
            newSearchFilters.is_sponsored !== ""
        );
    };

    const toggleFilterBox = () => {
        setFilterBoxVisible((prev) => !prev);
    };

    const handleViewDetails = (schoolId) => {
        setUrlParams(prevParams => ({
            ...prevParams,
            view: "details",
            id: schoolId,
        }));
    };

    const handleBackToTable = () => {
        setUrlParams(prevParams => {
            const { id, ...restParams } = prevParams;
            return {
                ...restParams,
                view: "table"
            };
        });
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        dispatch(clearMessage());
    };



    return (
        <Box sx={{ width: "100%", overflowX: "auto" }}>
            {schoolState.message && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={true}
                    autoHideDuration={5000}
                    onClose={handleCloseSnackbar}
                >
                    <Alert sx={{ width: "100%", mb: 2 }} severity="success">
                        {schoolState.message}
                    </Alert>
                </Snackbar>
            )}
            {urlParams.view === "table" && (
                <React.Fragment>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            startIcon={<FilterList />}
                            onClick={toggleFilterBox}
                        >
                            Filters
                        </Button>
                    </Box>
                    <FilterSchoolCollapse
                        searchFilters={searchFilters}
                        handleSearchChange={handleSearchChange}
                        filterBoxVisible={filterBoxVisible}
                    />
                    {!schoolState.loading && (
                        <SchoolTable
                            searchFilters={searchFilters}
                            schools={schools}
                            filterCourse={filterCourse}
                            onViewDetails={handleViewDetails}
                            setUrlParams={setUrlParams}
                            handleDelete={handleDelete}
                            programById={programById}
                        />
                    )}
                </React.Fragment>
            )}
            {urlParams.view === "details" && urlParams.id && schools.length > 0 && (
                <SchoolDetails
                    programs={programs.filter(program => program.category === PROGRAM_CATEGORY_CHOICES.School)}
                    currencies={currencies}
                    schoolId={urlParams.id}
                    setUrlParams={setUrlParams}
                    onBack={handleBackToTable}
                />
            )}
            {urlParams.view === "add" && (
                <AddSchool
                    isGoogleMapsScriptLoaded={isGoogleMapsScriptLoaded}
                    onBack={handleBackToTable}
                />
            )}
            {deleteUni && (
                <DeleteConfirmationDialog
                    open={true}
                    handleClose={() => setDeleteUni(null)}
                    onConfirm={() => handleDeleteConfirm(deleteUni.id)}
                    contentText={getDeleteConfirmationText(deleteUni)}
                    state={schoolState}
                />
            )}
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1000,
                }}
                open={schoolState.loading || artifactLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};

export default School;
