import { Box, Chip, IconButton, Link, Tooltip } from "@mui/material";
import { CONSULTATION_CHOICES, CONSULTATION_TYPE_DETAILS, SOURCE_CHOICES, SOURCE_DETAILS } from "../../const";

import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import { getLeadStatusChip } from "./utils";

export default function leadColumns(onEdit, onViewDetail, countriesById, employeeById, intakeById, majorById, programById, serviceById) {
	return [
		{
			field: "actions",
			headerName: "Actions",
			flex: 0.5,
			minWidth: 100,
			renderCell: (params) => (
				<IconButton color="primary" onClick={() => onEdit(params.row)}>
					<EditIcon />
				</IconButton>
			),
		},
		{
			field: "full_name",
			headerName: "Student",
			flex: 1,
			minWidth: 150,
			valueGetter: (value, row) => row.student.full_name || "",
			renderCell: (params) => (
				<Link
					component="button"
					variant="body2"
					underline="hover"
					onClick={() => onViewDetail(params.row.id)}
				>
					{params.row.student.full_name}
				</Link>
			),
		},
		{
			field: "status",
			headerName: "Status",
			flex: 1,
			minWidth: 180,
			renderCell: (params) => getLeadStatusChip(params.value),
		},
		{
			field: "phone_number",
			headerName: "Phone Number",
			flex: 1,
			minWidth: 150,
			renderCell: (params) => {
				const phoneNumber = `${params.row.student.calling_code}${params.row.student.phone_number}`;
				const whatsappUrl = `https://wa.me/${phoneNumber.replace(
					/\D/g,
					""
				)}`;

				return (
					<Link
						href={whatsappUrl}
						target="_blank"
						rel="noopener noreferrer"
						underline="hover"
					>
						{phoneNumber}
					</Link>
				);
			},
		},
		{
			field: "nationality",
			headerName: "Nationality",
			flex: 0.75,
			minWidth: 100,
			renderCell: (params) => {
				return (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',  // Vertically center
							justifyContent: 'center',  // Horizontally center
							width: '100%',
							height: '100%',
						}}
					>
						<Tooltip title={params.row.student.nationality}>
							<span>
								<ReactCountryFlag
									countryCode={params.row.student.nationality_code}
									svg
									style={{
										width: "1.5em",
										height: "1.5em",
										marginRight: "8px",
									}}
								/>
							</span>
						</Tooltip>
					</Box>
				)
			},
		},
		{
			field: "case_manager",
			headerName: "Case Manager",
			flex: 1,
			minWidth: 150,
			valueGetter: (value, row) => employeeById.get(row.case_manager).full_name,
		},
		{
			field: "consultation_type",
			headerName: "Consultation Type",
			flex: 1,
			minWidth: 150,
			renderCell: (params) => {
				const consultationType = params.value;
				const { color, icon } = CONSULTATION_TYPE_DETAILS[
					consultationType
				] || { color: "defaultColor", icon: null };

				return (
					<Chip
						label={CONSULTATION_CHOICES[consultationType]}
						icon={icon}
						size="small"
						variant="outlined"
						sx={{ color: color, borderColor: color }}
					/>
				);
			},
		},
		{
			field: "source",
			headerName: "Source",
			flex: 0.75,
			minWidth: 150,
			renderCell: (params) => {
				const source = params.value;
				const { color, icon } = SOURCE_DETAILS[
					source
				] || { color: "defaultColor", icon: null };

				return (
					<Chip
						label={SOURCE_CHOICES[source]}
						icon={icon}
						size="small"
						variant="outlined"
						sx={{ color: color, borderColor: color }}
					/>
				);
			},
		},
		{
			field: "gpa",
			headerName: "GPA",
			flex: 0.5,
			minWidth: 100,
		},
		{
			field: "english_test_status",
			headerName: "English Test Status",
			flex: 1,
			minWidth: 200,
		},
		{
			field: "toefl_score",
			headerName: "TOEFL Score",
			flex: 0.75,
			minWidth: 120,
		},
		{
			field: "ielts_score",
			headerName: "IELTS Score",
			flex: 0.75,
			minWidth: 120,
		},
		{
			field: "test_date",
			headerName: "Test Date",
			flex: 1,
			minWidth: 150,
			valueFormatter: (value) =>
				new Date(value).toLocaleDateString(),
		},
		{
			field: "intakes",
			headerName: "Intakes",
			flex: 1,
			minWidth: 200,
			valueGetter: (value) =>
				value.map((intakeId) => intakeById.get(intakeId)?.text).join(", "),
		},
		{
			field: "preferred_programs",
			headerName: "Preferred Programs",
			flex: 1,
			minWidth: 200,
			valueGetter: (value) =>
				value
					.map((programId) => programById.get(programId)?.name)
					.join(", "),
		},
		{
			field: "preferred_majors",
			headerName: "Preferred Majors",
			flex: 1,
			minWidth: 200,
			valueGetter: (value) =>
				value.map((majorId) => majorById.get(majorId)?.name).join(", "),
		},
		{
			field: "preferred_cities",
			headerName: "Preferred Cities",
			flex: 1,
			minWidth: 200,
			valueGetter: (value) => value.map(city => city.name).join(", "),
		},
		{
			field: "preferred_countries",
			headerName: "Preferred Countries",
			flex: 1,
			minWidth: 200,
			valueGetter: (value) =>
				value
					.map((countryId) => countriesById.get(countryId)?.name)
					.join(", "),
		},
		{
			field: "additional_services",
			headerName: "Additional Services",
			flex: 1,
			minWidth: 200,
			renderCell: (params) => (
				<Box
					sx={{
						height: "100%",
						display: "flex",
						alignItems: "center",
						whiteSpace: "normal",
						lineHeight: 1.5,
						overflowWrap: "anywhere",
					}}
				>
					{params.value.map((service) => serviceById.get(service)?.name).join(", ")}
				</Box>
			),
			autoHeight: true,
		},
		{
			field: "added_by",
			headerName: "Creator",
			flex: 1,
			minWidth: 150,
			valueGetter: (value, row) => employeeById.get(row.added_by)?.full_name,
		},
		{
			field: "updated_at",
			headerName: "Updated At",
			flex: 2,
			minWidth: 200,
			type: "dateTime",
			valueGetter: (value, row) => new Date(row.updated_at),
		},
	];
}
