import axios from 'axios';

// Create an Axios instance
const exchangeRateClient = axios.create({
    baseURL: process.env.REACT_APP_EXCHANGERATE_BASE_API_URL, // Base URL for the API
    timeout: 10000, // Request timeout in milliseconds
});

// Add interceptors if needed (optional)
exchangeRateClient.interceptors.request.use(
    (config) => {
        // You can add auth headers, logging, or modify config here
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

exchangeRateClient.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error('API error:', error);
        return Promise.reject(error);
    }
);

export default exchangeRateClient;
