import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { fetchWrapper } from "./wrappers/fetchWrapper";
import { setStatus as setApplicationStatus } from "./store/universityApplication.slice";
import { setCountries } from "./store/country.slice";
import { setCurrencies } from "./store/currency.slice";
import { setStatus as setDraftLeadStatus } from "./store/draftLead.slice";
import { setEmployees } from "./store/employee.slice";
import { setStatus as setExternalEventStatus } from "./store/externalEvent.slice";
import { setIntakes } from "./store/intake.slice";
import { setStatus as setLeadStatus } from "./store/lead.slice";
import { setMajors } from "./store/major.slice";
import { setPrograms } from "./store/program.slice";
import { setStatus as setSchoolApplicationStatus } from "./store/schoolApplication.slice";
import { setServices } from "./store/service.slice";
import { setStatus as setUniversityStatus } from "./store/university.slice";
import { v4 as uuidv4 } from "uuid";

const EdvantageContext = createContext();
const useEdvantageContext = () => useContext(EdvantageContext);

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;

const EdvantageProvider = ({ children }) => {
	const dispatch = useDispatch();
	const { employee: authUser } = useSelector((state) => state.auth);

	const [uuid, setUuid] = useState(() => {
		// Try to load the UUID from localStorage, if it exists
		const storedUuid = localStorage.getItem('clientUuid');
		if (storedUuid) {
			return storedUuid;
		} else {
			// If it doesn't exist, generate a new UUID and store it in localStorage
			const newUuid = uuidv4();
			localStorage.setItem('clientUuid', newUuid);
			return newUuid;
		}
	});

	const [artifactLoading, setArtifactLoading] = useState(true);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [urlParams, setUrlParams] = useState(Object.fromEntries(queryParams.entries()));
	const [highlightedElementId, setHighlightedElementId] = useState(null);

	const navigate = useNavigate();

	useEffect(() => {
		// set navigation arguments for dynamic menu on dashboard
		const pathname = location['pathname']
		const params = new URLSearchParams(urlParams).toString();
		navigate(`${pathname}?${params}`);
	}, [urlParams]);

	const {
		currencies,
		loading: currenciesLoading,
		error: currenciesError,
	} = useSelector((state) => state.currency);
	const { employees, loading: employeesLoading } = useSelector((state) => state.employee);
	const {
		intakes,
		loading: intakesLoading,
		error: intakesError,
	} = useSelector((state) => state.intake);
	const { programs, loading: programsLoading } = useSelector((state) => state.program);
	const { majors } = useSelector((state) => state.major);
	const { countries } = useSelector((state) => state.country);
	const { services } = useSelector((state) => state.service);

	const scrollToElement = (elementId) => {
		const element = document.getElementById(elementId);
		if (element) {
			element.scrollIntoView({ behavior: "smooth" });
			setHighlightedElementId(elementId);
			setTimeout(() => {
				setHighlightedElementId(null);
			}, 2000);
		}

		return element;
	};

	useEffect(() => {
		if (authUser) {
			setArtifactLoading(true);
			fetchWrapper
				.get(`${baseUrl}/fetch_artifacts?timestamp=${new Date().getTime()}`)
				.then((res) => {
					dispatch(setCountries(res.data.countries));
					dispatch(setCurrencies(res.data.currencies));
					dispatch(setEmployees(res.data.employees));
					dispatch(setIntakes(res.data.intakes));
					dispatch(setPrograms(res.data.programs));
					dispatch(setMajors(res.data.majors));
					dispatch(setServices(res.data.services));
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				})
				.finally(() => {
					setArtifactLoading(false);
				});
			dispatch(setLeadStatus("idle"));
			dispatch(setUniversityStatus("idle"));
			dispatch(setApplicationStatus("idle"));
			dispatch(setSchoolApplicationStatus("idle"));
			dispatch(setDraftLeadStatus("idle"));
			dispatch(setExternalEventStatus("idle"));
		}
	}, [dispatch, authUser]);

	// Create memoized mappings for quick lookups by ID
	const countriesById = useMemo(() => {
		if (!countries || countries.length === 0) return new Map();
		return new Map(countries.map(country => [country.id, country]));
	}, [countries]);

	const currencyById = useMemo(() => {
		if (!currencies || currencies.length === 0) return new Map();
		return new Map(currencies.map(currency => [currency.id, currency]));
	});

	const employeeById = useMemo(() => {
		if (!employees || employees.length === 0) return new Map();
		return new Map(employees.map(employee => [employee.id, employee]));
	}, [employees]);

	const intakeById = useMemo(() => {
		if (!intakes || intakes.length === 0) return new Map();
		return new Map(intakes.map(intake => [intake.id, intake]));
	}, [intakes]);

	const majorById = useMemo(() => {
		if (!majors || majors.length === 0) return new Map();
		return new Map(majors.map(major => [major.id, major]));
	}, [majors]);

	const programById = useMemo(() => {
		if (!programs || programs.length === 0) return new Map();
		return new Map(programs.map(program => [program.id, program]));
	}, [programs]);

	const serviceById = useMemo(() => {
		if (!services || services.length === 0) return new Map();
		return new Map(services.map(service => [service.id, service]));
	}, [services]);

	return (
		<EdvantageContext.Provider
			value={{
				authUser,
				uuid,
				countries,
				countriesById,
				currencies,
				currencyById,
				currenciesLoading,
				currenciesError,
				employees,
				employeeById,
				employeesLoading,
				intakes,
				intakeById,
				intakesLoading,
				intakesError,
				programs,
				programById,
				programsLoading,
				majors,
				majorById,
				services,
				artifactLoading,
				urlParams,
				setUrlParams,
				serviceById,
				highlightedElementId,
				scrollToElement,
			}}
		>
			{children}
		</EdvantageContext.Provider>
	);
};

export { EdvantageProvider, useEdvantageContext };
