import { LEAD_STATUS_CHOICES, LEAD_STATUS_COLORS } from "../../const";

import { Chip } from "@mui/material";
import React from 'react'
import { isValidGPA } from "../../utils/Utils";

export const visuallyHidden = {
    border: 0,
    margin: -1,
    padding: 0,
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    clip: 'rect(0 0 0 0)',
};

export function emptyRows(page, rowsPerPage, arrayLength) {
    return page ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
}

function descendingComparator(a, b, orderBy) {
    if (a[orderBy] === null) {
        return 1;
    }
    if (b[orderBy] === null) {
        return -1;
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export function applyFilter({ inputData, comparator, filterName }) {
    const stabilizedThis = inputData.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    inputData = stabilizedThis.map((el) => el[0]);

    if (filterName) {
        inputData = inputData.filter(
            (user) => user.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
        );
    }

    return inputData;
}

export const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
}

export const getLeadStatusChip = (status) => {
    const statusObj = LEAD_STATUS_CHOICES.find(
        (choice) => choice.value === status
    );
    const label = statusObj ? statusObj.label : status;

    // Default grey color if status is not found
    const color = LEAD_STATUS_COLORS[status] || "#B0BEC5";

    return (
        <Chip
            label={label}
            variant="outlined"
            size="small"
            style={{ color: color, borderColor: color }}
        />
    );
}

export const autoPopulateGPA = (gradesPerSubject, gradeSystem) => {
    // Define grade mappings for the A-F scale
    const gradeMap = {
        A: 4.0,
        B: 3.0,
        C: 2.0,
        D: 1.0,
        F: 0.0,
    };

    const numericToLetterGrade = (gpa) => {
        if (gpa >= 3.7) return "A";
        if (gpa >= 3.0) return "B";
        if (gpa >= 2.0) return "C";
        if (gpa >= 1.0) return "D";
        return "F";
    };

    // Convert grades to numeric values
    const numericGrades = gradesPerSubject
        .map((item) => {
            if (gradeSystem === "A-F") {
                return gradeMap[item.grade] ?? null; // Map A-F grades to numbers
            } else {
                return parseFloat(item.grade); // Use numeric grades as-is
            }
        })
        .filter((grade) => grade !== null && !isNaN(grade)); // Exclude invalid grades

    // Calculate raw GPA
    const totalGrades = numericGrades.reduce((acc, grade) => acc + grade, 0);
    const rawGpa = numericGrades.length ? totalGrades / numericGrades.length : 0;

    // Convert GPA to the selected grade system
    let finalGpa;
    switch (gradeSystem) {
        case "A-F":
            finalGpa = numericToLetterGrade(rawGpa); // Map numeric GPA to A-F
            break;
        case "100-point scale":
            finalGpa = ((rawGpa / 4.0) * 100).toFixed(2); // Scale 4.0 to 100
            break;
        case "10.0 point scale":
            finalGpa = ((rawGpa / 4.0) * 10).toFixed(2); // Scale 4.0 to 10
            break;
        case "5.0 point scale":
            finalGpa = ((rawGpa / 4.0) * 5).toFixed(2); // Scale 4.0 to 5
            break;
        case "4.0 point scale":
        default:
            finalGpa = rawGpa.toFixed(2); // Default is 4.0 scale
            break;
    }

    return finalGpa;
};

export const validateSubjectsGrades = (gradesPerSubject, gradeSystem) => {
    const errors = {};
    gradesPerSubject.forEach((item, index) => {
        if (!item.subject) errors[`subject_${index}`] = "Subject is required";
        if (!item.grade) errors[`grade_${index}`] = "Grade is required";
        if (item.grade && !isValidGPA(item.grade, gradeSystem))
            errors[`grade_${index}`] =
                "Grade is not valid for the selected grading scale";
    });
    return errors;
};
