import * as React from "react";

import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { formatDate } from "../Leads/utils";
import universityApplicationColumns from "./universityApplicationColumns";
import { useEdvantageContext } from "../../EdvantageProvider";

export default function UniversityApplicationTable(props) {
    const { applicationState, setUrlParams, universityById, universityCourseById } = props;
    const [expandedRows, setExpandedRows] = React.useState([]);

    const { intakeById, majorById, programById, employeeById } = useEdvantageContext();

    const rows = React.useMemo(() => {
        // Group applications by lead_id to ensure only one lead row per lead
        const groupedApplications = applicationState.applications.reduce((acc, application) => {
            const leadId = application.lead.id;
            if (!acc[leadId]) {
                acc[leadId] = [];
            }
            acc[leadId].push(application);
            return acc;
        }, {});

        // Flatten the grouped applications to create lead rows and corresponding application rows
        return Object.keys(groupedApplications).flatMap((leadId) => {
            const applications = groupedApplications[leadId];
            const lead = applications[0].lead; // Use the lead's name from the first application
            const leadRow = {
                id: leadId,
                student: lead.student.full_name,
                university: "",
                university_id: "",
                university_course: "",
                major_id: "",
                offer_letter: "",
                program_id: "",
                intake: "",
                intake_id: "",
                year: "",
                email: lead.student.email, // No email on the lead row itself
                phone_number: lead.student.phone_number, // No phone number on the lead row itself
                nationality: lead.student.nationality, // No nationality on the lead row itself
                added_by: "",
                added_at: "",
                updated_by: "",
                updated_at: "",
                status: "", // No status on the lead row itself
                application_id: "",
                isLead: true, // This is the lead row
            };

            // Generate the application rows for each application in the current lead's group
            const applicationRows = applications.map((application, index) => {
                const university = universityById.get(application.university_id);
                const universityCourse = universityCourseById.get(application.university_course);
                const intake = intakeById.get(application.intake_id);
                const addedBy = employeeById.get(application.added_by);
                const updatedBy = employeeById.get(application.updated_by);

                return {
                    id: `${leadId}-${index}`, // Unique ID based on lead_id and application index
                    parentId: leadId,
                    student: "", // Leave this empty for the application row
                    university: university?.name || '',
                    university_id: application.university_id,
                    university_course: application.university_course,
                    major_id: universityCourse?.major || '',
                    offer_letter: application.offer_letter || '',
                    program_id: universityCourse?.program || '',
                    intake: intake?.text || '',
                    intake_id: application.intake_id,
                    year: application.year || '',
                    email: '',
                    phone_number: '',
                    nationality: '',
                    added_by: addedBy?.full_name || '',
                    added_at: formatDate(application.added_at),
                    updated_by: updatedBy?.full_name || '',
                    updated_at: formatDate(application.updated_at),
                    status: application.status || '',
                    application_id: application.id,
                    isLead: false, // This is an application row, not a lead row
                };
            });

            // Return the lead row followed by the application rows
            return [leadRow, ...applicationRows];
        });
    }, [applicationState.applications, universityById, universityCourseById, intakeById, employeeById]);


    const filteredRows = React.useMemo(() => {
        return rows.filter((row) => {
            if (row.isLead) {
                return true; // Always show lead rows
            }
            return expandedRows.includes(row.parentId);
        });
    }, [rows, expandedRows]);

    const handleExpandClick = (leadId) => {
        setExpandedRows((prevExpandedRows) =>
            prevExpandedRows.includes(leadId)
                ? prevExpandedRows.filter((id) => id !== leadId)
                : [...prevExpandedRows, leadId]
        );
    };

    const getRowClassName = (params) => {
        return params.row.isLead ? "MuiDataGrid-row--lead" : "";
    };


    return (
        <Box sx={{ width: "100%" }}>
            <DataGrid
                rows={filteredRows}
                columns={universityApplicationColumns(
                    expandedRows,
                    handleExpandClick,
                    setUrlParams,
                    majorById,
                    programById
                )}
                getRowClassName={getRowClassName}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            // Hide columns id, the other columns will remain visible
                            id: false,
                        },
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 20, 50, 100]}
                checkboxSelection={false}
                disableRowSelectionOnClick
                sx={{
                    "& .MuiDataGrid-row": {
                        backgroundColor: "#ffffff", // White background for all rows
                    },
                    "& .MuiDataGrid-row--lead": {
                        backgroundColor: "#f5f5f5", // Different background color for lead rows
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        whiteSpace: "nowrap",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        whiteSpace: "nowrap",
                        overflow: "auto",
                    },
                }}
            />
        </Box>
    );
}
