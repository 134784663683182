import { Box, Skeleton } from "@mui/material";

import React from "react";

const LeadTableSkeleton = () => {
    return (
        <React.Fragment>
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(6, 1fr)",
                    gap: 2,
                    padding: 2,
                    borderBottom: "1px solid #e0e0e0",
                    backgroundColor: "#f5f5f5",
                }}
            >
                {Array.from({ length: 6 }).map((_, index) => (
                    <Skeleton key={index} height={40} width="100%" />
                ))}
            </Box>
            {Array.from({ length: 8 }).map((_, index) => (
                <Box
                    key={index}
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(6, 1fr)",
                        gap: 2,
                        padding: 2,
                        borderBottom: "1px solid #e0e0e0",
                    }}
                >
                    {Array.from({ length: 6 }).map((_, cellIndex) => (
                        <Skeleton
                            key={cellIndex}
                            height={30}
                            width="100%"
                        />
                    ))}
                </Box>
            ))}
        </React.Fragment>
    );
};

export default LeadTableSkeleton;
