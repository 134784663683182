import * as React from "react";

import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField
} from "@mui/material";
import { isValidCurrency, isValidFee } from "../../utils/Utils";
import { useDispatch, useSelector } from "react-redux";

import EdvantageAutocomplete from "../common/EdvantageAutocomplete";
import PropTypes from "prop-types";
import { updateSchoolCourse } from "../../store/school.slice";

function EditCourseDialog(props) {
	const dispatch = useDispatch();

	const { open, handleClose, course, programById, programs, currencies } = props;
	const schoolState = useSelector((state) => state.school);

	const initialFormState = {
		program: course.program,
		application_method: course.application_method ?? "",
		fee: course.fee ?? "",
		currency: course.currency ?? "",
	};

	const [form, setForm] = React.useState(initialFormState);
	const [formErrors, setFormErrors] = React.useState("");
	const [isFormValid, setIsFormValid] = React.useState(true);
	const [isFormChanged, setIsFormChanged] = React.useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();
		dispatch(updateSchoolCourse({ id: course.id, course: form })).then(
			(response) => {
				if (
					response.type ===
					"school/updateSchoolCourse/fulfilled"
				) {
					handleClose(false);
				}
			}
		);
	};

	const validateCourseForm = (form) => {
		const errors = {};

		// Required fields and their corresponding error messages
		const requiredFields = {
			program: "Program is required",
		};

		// Validate required fields
		for (const field in requiredFields) {
			if (!form[field]) {
				errors[field] = requiredFields[field];
			}
		}

		// Validate fee and currency together
		if ((form.fee && !form.currency) || (!form.fee && form.currency)) {
			if (!form.fee) {
				errors.fee = "Fee is required when currency is set";
			}
			if (!form.currency) {
				errors.currency = "Currency is required when fee is set";
			}
		}

		// Validate the fee field if it's present
		if (form.fee && !isValidFee(form.fee)) {
			errors.fee = "Invalid fee amount";
		}

		// Validate the currency field if it's present
		if (form.currency && !isValidCurrency(form.currency)) {
			errors.currency = "Invalid currency";
		}

		return errors;
	};

	const validateCourseField = (name, value) => {
		let error = "";
		if (!value) {
			if (name === "program") error = "Program is required";
		}

		if (name === "fee") {
			if (value && !isValidFee(value)) {
				error = "Invalid fee amount";
			} else if (value && !isValidCurrency(form.currency)) {
				setFormErrors((prevErrors) => ({
					...prevErrors,
					currency: "Currency is required",
				}));
			} else if (!value && isValidCurrency(form.currency)) {
				error = "Fee is required when currency is set";
			} else if (!value && !isValidCurrency(form.currency)) {
				setFormErrors((prevErrors) => ({
					...prevErrors,
					currency: "",
				}));
			}
		}

		if (name === "currency") {
			if (value && !isValidCurrency(value)) {
				error = "Invalid currency";
			} else if (value && !isValidFee(form.fee)) {
				setFormErrors((prevErrors) => ({
					...prevErrors,
					fee: "Fee is required when currency is set",
				}));
			} else if (!value && isValidFee(form.fee)) {
				error = "Currency is required when fee is set";
			} else if (!value && !isValidFee(form.fee)) {
				setFormErrors((prevErrors) => ({
					...prevErrors,
					fee: "",
				}));
			}
		}

		setFormErrors((prevErrors) => ({
			...prevErrors,
			[name]: error,
		}));
	};

	const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		const newForm = {
			...form,
			[name]: type === "checkbox" ? checked : value,
		};
		setForm(newForm);
		validateCourseField(name, value);
		setIsFormValid(Object.keys(validateCourseForm(newForm)).length === 0);
		setIsFormChanged(
			JSON.stringify(newForm) !== JSON.stringify(initialFormState)
		);
	};

	const handleCloseDialog = () => {
		handleClose(false);
		setForm(initialFormState);
		setFormErrors("");
		setIsFormValid(true);
		setIsFormChanged(false);
	};

	return (
		<Dialog
			open={open}
			onClose={handleCloseDialog}
			aria-labelledby="form-dialog-title"
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle id="form-dialog-title">
				Edit Course:{" "}
				<em>
					{programById.get(course.program).name}
				</em>
			</DialogTitle>
			<DialogContent>
				<Box sx={{ mt: 3 }}>
					{schoolState.error && (
						<Alert
							sx={{ width: "100%", mt: 2, mb: 2 }}
							severity="error"
						>
							{schoolState.error}
						</Alert>
					)}
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<EdvantageAutocomplete
								name="program"
								label="Program"
								value={form.program}
								onChange={handleChange}
								error={Boolean(formErrors?.program)}
								helperText={formErrors?.program}
								options={programs}
								getOptionLabel={(option) =>
									option.name
								}
								getOptionValue={(newValue) => newValue.id}
								isOptionEqualToValue={(option, value) =>
									option.id === value
								}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControl fullWidth>
								<InputLabel id="application-select-label">
									Application Method
								</InputLabel>
								<Select
									labelId="application-select-label"
									id="application-select"
									value={form.application_method}
									label="Application Method"
									name="application_method"
									onChange={handleChange}
								>
									<MenuItem key={0} value={"UCAS"}>
										UCAS
									</MenuItem>
									<MenuItem key={1} value={"Direct"}>
										Direct
									</MenuItem>
									<MenuItem key={2} value={"Pathway"}>
										Pathway
									</MenuItem>
									<MenuItem key={3} value={"Other"}>
										Other
									</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Fee"
								name="fee"
								type="number"
								value={form.fee}
								onChange={handleChange}
								error={Boolean(formErrors?.fee)}
								helperText={formErrors?.fee}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControl
								fullWidth
								error={Boolean(formErrors?.currency)}
							>
								<InputLabel id="currency-select-label">
									Currency
								</InputLabel>
								<Select
									labelId="currency-select-label"
									id="currency-select"
									value={form.currency}
									label="Currency"
									name="currency"
									onChange={handleChange}
								>
									<MenuItem value="">
										<em>None</em>
									</MenuItem>
									{currencies.map((currency) => (
										<MenuItem
											key={currency.code}
											value={currency.id}
										>
											{currency.name} ({currency.symbol})
										</MenuItem>
									))}
								</Select>
								<FormHelperText>
									{formErrors?.currency}
								</FormHelperText>
							</FormControl>
						</Grid>
					</Grid>
					<DialogActions sx={{ justifyContent: "flex-start", px: 0 }}>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							sx={{ mt: 3, mb: 2 }}
							disabled={!isFormValid || !isFormChanged}
							onClick={handleSubmit}
						>
							Save
						</Button>
						<Button
							type="button"
							variant="contained"
							color="error"
							sx={{ mt: 3, mb: 2 }}
							onClick={handleCloseDialog}
						>
							Cancel
						</Button>
					</DialogActions>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

EditCourseDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	course: PropTypes.object.isRequired,
};

export default EditCourseDialog;
