import * as React from "react";

export const prepareConvertLeadData = (row, countries) => {
    const country = countries.find(country => country.calling_code === row.student.calling_code);

    return {
        student: {
            id: "",  // Add actual ID if needed
            user: {
                first_name: row.student.first_name,
                last_name: row.student.last_name,
                email: row.student.email,
            },
            phone_country_code: country ? country.code2 : "",  // Default to empty if not found
            calling_code: row.student.calling_code,
            phone_number: row.student.phone_number,
            dob: row.student.dob,
            gender: row.student.gender,
            nationality: row.student.nationality,
        },
        ...prepareCommonLeadData(row),
    };
};

export const prepareEditDraftData = (row) => {
    return {
        id: row.id,
        first_name: row.student.first_name,
        last_name: row.student.last_name,
        email: row.student.email,
        calling_code: row.student.calling_code,
        phone_number: row.student.phone_number,
        dob: row.student.dob,
        gender: row.student.gender,
        nationality: row.student.nationality,
        ...prepareCommonLeadData(row),
    };
};

// Helper function for preparing common lead data
const prepareCommonLeadData = (row) => ({
    source: row.source,
    source_external_event: row.source_external_event,
    grade_type: row.grade_type,
    grade_system: row.grade_system,
    grades_per_subject: row.grades_per_subject,
    gpa: row.gpa,
    english_test_status: row.english_test_status,
    test_date: row.test_date,
    toefl_score: row.toefl_score || "",
    ielts_score: row.ielts_score || "",
    consultation_type: row.consultation_type,
    intakes: row.intakes,
    preferred_programs: row.preferred_programs,
    preferred_majors: row.preferred_majors,
    preferred_countries: row.preferred_countries,
    preferred_cities: row.preferred_cities,
    additional_services: row.additional_services,
    has_sponsorship: row.has_sponsorship,
    sponsorship_details: '',
    notes: row.notes,
});
