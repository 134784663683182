import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "../wrappers/fetchWrapper";

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/application`;

const initialState = {
	action: "",
	applications: [],
	loading: false,
	error: null,
	message: null,
	status: "",
};

const name = "universityApplication";

const universityApplicationSlice = createSlice({
	name: name,
	initialState,
	reducers: {
		// Optionally add some synchronous reducers if needed
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setError: (state, action) => {
			state.error = action.payload;
		},
		clearMessage: (state) => {
			state.message = null;
		},
		clearUniversityApplicationState: (state) => {
			state.action = "";
			state.applications = [];
			state.loading = false;
			state.error = null;
			state.message = null;
			state.status = "";
		},
		setStatus: (state, action) => {
			state.status = action.payload;
		},
		updateUniversityApplicationStatus: (state, action) => {
			const { id, status } = action.payload;
			const applicationIndex = state.applications.findIndex(
				(application) => application.id === id
			);
			if (applicationIndex !== -1) {
				state.applications[applicationIndex].status = status;
			}
		},
		changeUniversityApplicationState: (state, action) => {
			const { action: actionType, data } = action.payload;

			if (actionType === 'create') {
				// Add new application to the list
				state.applications = [...state.applications, data];
			} else if (actionType === 'update') {
				// Update the existing application
				const index = state.applications.findIndex(application => application.id === data.id);
				if (index !== -1) {
					state.applications[index] = data;
				}
			} else if (actionType === 'delete') {
				// Create a Set of IDs to delete for efficient lookup
				const idsToDelete = new Set(data.map(application => application.id));

				// Filter out applications with IDs in the Set
				state.applications = state.applications.filter(
					application => !idsToDelete.has(application.id)
				);
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(addApplication.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(addApplication.fulfilled, (state, action) => {
				state.action = "addApplication";
				state.loading = false;
				state.message = "Application Added Successfully";
				state.applications.push(action.payload);
			})
			.addCase(addApplication.rejected, (state, action) => {
				state.action = "addApplication";
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === 'object') {
					state.error = ['Unknown Error!'];
				} else {
					state.error = action.payload;
				}
			})
			.addCase(fetchUniversityApplications.pending, (state) => {
				state.status = 'loading';
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(fetchUniversityApplications.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.loading = false;
				state.applications = action.payload;
			})
			.addCase(fetchUniversityApplications.rejected, (state, action) => {
				state.status = 'failed';
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(deleteApplication.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(deleteApplication.fulfilled, (state, action) => {
				state.loading = false;
				state.message = "Application Deleted Successfully";
				state.applications = state.applications.filter(
					(application) => application.id !== action.payload.id
				);
			})
			.addCase(deleteApplication.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(updateUniversityApplication.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(updateUniversityApplication.fulfilled, (state, action) => {
				state.loading = false;
				state.message = "Application Updated Successfully";
				const index = state.applications.findIndex(
					(application) => application.id === action.payload.id
				);
				if (index !== -1) {
					state.applications[index] = action.payload;
				}
			})
			.addCase(updateUniversityApplication.rejected, (state, action) => {
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			});
	},
});

export const {
	changeUniversityApplicationState,
	setError,
	setLoading,
	clearMessage,
	clearUniversityApplicationState,
	setStatus,
	updateUniversityApplicationStatus,
} = universityApplicationSlice.actions;
export const universityApplicationReducer = universityApplicationSlice.reducer;

export const addApplication = createAsyncThunk(
	`${name}/addApplication`,
	async (application, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.post(
				`${baseUrl}/add`,
				application
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const fetchUniversityApplications = createAsyncThunk(
	`${name}/fetchUniversityApplications`,
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.get(
				`${baseUrl}/fetch?timestamp=${new Date().getTime()}`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const updateUniversityApplication = createAsyncThunk(
	`${name}/updateUniversityApplication`,
	async ({ id, application }, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.put(
				`${baseUrl}/update/${id}/`,
				application
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const deleteApplication = createAsyncThunk(
	`${name}/deleteApplication`,
	async (id, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.delete(
				`${baseUrl}/delete/${id}`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
