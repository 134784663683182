import exchangeRateClient from "../services/exchangeRateAPI";

const CACHE_KEY_PREFIX = 'exchange_rate_cache_';
const CACHE_EXPIRATION = 60 * 60 * 1000; // 1 hour in milliseconds
const activeFetches = new Map(); // Track active fetches

export const fetchExchangeRate = async (fromCurrency, toCurrency) => {
    const API_KEY = process.env.REACT_APP_EXCHANGERATE_API_KEY;
    const cacheKey = `${CACHE_KEY_PREFIX}${fromCurrency}_${toCurrency}`;
    const now = new Date().getTime();

    // Check cache
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    if (cachedData && cachedData.timestamp + CACHE_EXPIRATION > now) {
        return cachedData.rate;
    }

    // Check if a fetch for this pair is already in progress
    if (activeFetches.has(cacheKey)) {
        return activeFetches.get(cacheKey);
    }

    // Start a new fetch
    const fetchPromise = (async () => {
        try {
            const response = await exchangeRateClient.get(`/${API_KEY}/pair/${fromCurrency}/${toCurrency}`);
            const rate = response.data.conversion_rate;

            // Save to cache
            const cacheData = { rate, timestamp: now };
            localStorage.setItem(cacheKey, JSON.stringify(cacheData));

            return rate;
        } catch (error) {
            console.error(`Error fetching exchange rate for ${fromCurrency}-${toCurrency}:`, error);
            return null;
        } finally {
            activeFetches.delete(cacheKey); // Remove from active fetches once done
        }
    })();

    activeFetches.set(cacheKey, fetchPromise);
    return fetchPromise;
};
