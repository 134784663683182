import { Autocomplete, Link, TextField, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import React from 'react';

function StudentAutocomplete({ students, name, label, value, onChange, error, helperText, setOpenAddStudentDialog }) {

    const noOptionsMessage = (
        <Typography variant="body2" color="textSecondary">
            <em>No new student found.</em> {' '}
            <Link
                component="button"
                variant="body2"
                onClick={() => setOpenAddStudentDialog(true)}
            >
                Add a new student
            </Link>
        </Typography>
    );

    return (
        <Autocomplete
            id="student-select"
            sx={{ width: '100%' }}
            options={students.map(student => ({
                id: student.id,
                user: student.user,
                calling_code: student.calling_code,
                phone_number: student.phone_number,
                dob: student.dob,
                gender: student.gender,
                nationality: student.nationality,
                address: student.address,
                full_name: `${student.user.first_name} ${student.user.last_name}`,
            }))}
            autoHighlight
            getOptionLabel={(option) => option.full_name || `${option.user.first_name} ${option.user.last_name}`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={students.find(student => `${student.user.first_name} ${student.user.last_name}` === value) || null}
            onChange={(event, newValue) => {
                onChange({
                    target: {
                        name,
                        value: newValue ? newValue : { id: '', full_name: '', user: { first_name: '', last_name: '' } },
                        type: 'text',
                    },
                });
            }}
            renderOption={(props, option) => (
                <li style={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                    {option.full_name}
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label || 'Student'}
                    error={error || false}
                    helperText={helperText || ''}
                    autoComplete="off"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', // disable autocomplete and autofill
                    }}
                />
            )}
            noOptionsText={setOpenAddStudentDialog && noOptionsMessage}
        />
    );
}

StudentAutocomplete.propTypes = {
    students: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    setOpenAddStudentDialog: PropTypes.func,
};

export default StudentAutocomplete;
