import * as React from 'react';

import { Grid, MenuItem, TextField } from '@mui/material';

import { LEAD_STATUS_CHOICES } from '../../const';

export default function LeadStatus(props) {

    const { leadData, setLeadData, setIsFormValid } = props;
    const [formErrors, setFormErrors] = React.useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLeadData({ ...leadData, [name]: value });

        // Validate the specific field being changed
        validateField(name, value);
    };

    const validateField = (name, value) => {
        let errors = { ...formErrors };
        switch (name) {
            case 'status':
                if (value === '') {
                    errors[name] = 'Status is required';
                } else {
                    delete errors[name];
                }
                break;
            default:
                break;
        }
        setFormErrors(errors);
        setIsFormValid(Object.keys(errors).length === 0);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    select
                    margin='dense'
                    name="status"
                    label="Status"
                    value={leadData.status}
                    onChange={handleChange}
                    error={Boolean(formErrors.status)}
                    helperText={formErrors.status}
                    fullWidth
                    required
                >
                    {LEAD_STATUS_CHOICES.filter((option) => !option.is_stale).map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
        </Grid>
    );
}
