import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import React from "react";
import TextField from "@mui/material/TextField";
import { useEdvantageContext } from "../../EdvantageProvider";

function CountrySelect({
	margin,
	name,
	label,
	value,
	onChange,
	required,
	error,
	helperText,
	disabled,
	multiple,
}) {
	const { countries } = useEdvantageContext();

	return (
		<Autocomplete
			id="country-select"
			sx={{ width: "100%" }}
			options={countries.map((country) => ({
				name: country.name,
				code: country.code2,
				phone: country.code3,
			}))}
			autoHighlight
			multiple={multiple || false} // Toggle multiple select
			getOptionLabel={(option) => option.name}
			isOptionEqualToValue={(option, value) => option.name === value.name}
			value={
				multiple
					? countries.filter((country) =>
						value.includes(country.name)
					) // Handle array of values for multiple
					: countries.find((country) => country.name === value) ||
					null // Single value for non-multiple
			}
			onChange={(event, newValue) => {
				multiple
					? onChange(event, newValue)
					: onChange({
						target: {
							name,
							value: newValue ? newValue.name : "", // Single value if multiple is false
						},
					});
			}}
			renderOption={(props, option) => (
				<li
					style={{ "& > img": { mr: 2, flexShrink: 0 } }}
					{...props}
					key={option.name}
				>
					<img
						loading="lazy"
						width="20"
						style={{ marginRight: "8px" }}
						srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
						src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
						alt=""
					/>
					{option.name} ({option.code})
				</li>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					margin={margin || "none"}
					label={label || "Country"}
					required={required || false}
					error={error || false}
					helperText={helperText || ""}
					autoComplete="off"
					inputProps={{
						...params.inputProps,
						autoComplete: "off", // disable autocomplete and autofill
					}}
					disabled={disabled || false}
				/>
			)}
		/>
	);
}

CountrySelect.propTypes = {
	margin: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
	onChange: PropTypes.func.isRequired,
	required: PropTypes.bool,
	error: PropTypes.bool,
	helperText: PropTypes.string,
	disabled: PropTypes.bool,
	multiple: PropTypes.bool,
};

export default CountrySelect;
