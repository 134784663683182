import {
    Alert,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Snackbar,
    Typography
} from "@mui/material";
import { clearMessage, deleteUniversity, setError } from "../../store/university.slice";
import { useDispatch, useSelector } from "react-redux";

import AddUniversity from "../University/AddUniversity";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog";
import { FilterList } from "@mui/icons-material";
import FilterUniversityCollapse from "../University/FilterUniversityCollapse";
import React from "react";
import UniversityDetails from "../University/UniversityDetails";
import UniversityTable from "../University/UniversityTable";
import { useEdvantageContext } from "../../EdvantageProvider";
import useUniversity from "../../hooks/useUniversity";

const University = ({ isGoogleMapsScriptLoaded, urlParams, setUrlParams }) => {

    const dispatch = useDispatch();
    const { uuid, currencyById, intakeById, majorById, programById } = useEdvantageContext();
    const { universities } = useUniversity();

    const universityState = useSelector((state) => state.university);
    const [deleteUni, setDeleteUni] = React.useState(null);

    const [searchFilters, setSearchFilters] = React.useState({
        name: "",
        majors: [],
        programs: [],
        city: "",
        countries: [],
        is_sponsored: "",
    });
    const [filterCourse, setFilterCourse] = React.useState(false);
    const [filterBoxVisible, setFilterBoxVisible] = React.useState(false);

    const handleSearchChange = (field) => (event, newValue) => {
        const newSearchFilters = {
            ...searchFilters,
            [field]: ["majors", "programs", "countries"].includes(field)
                ? newValue.map((option) => option.name)
                : event.target.value,
        };
        setSearchFilters(newSearchFilters);
        setFilterCourse(
            newSearchFilters.majors.length ||
            newSearchFilters.programs.length ||
            newSearchFilters.is_sponsored !== ""
        );
    };

    const getDeleteConfirmationText = (university) => {
        return (
            <>
                Are you sure you want to delete the university {" "}
                <Typography component="span" sx={{ fontWeight: "bold" }}>
                    {university.name}
                </Typography>? <em>Deleting this university will also <strong>delete</strong> all its applications and courses</em>
            </>
        );
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        dispatch(clearMessage());
        dispatch(setError(null));
    };

    const toggleFilterBox = () => {
        setFilterBoxVisible((prev) => !prev);
    };

    const handleBackToTable = () => {
        setUrlParams(prevParams => {
            const { id, ...restParams } = prevParams;
            return {
                ...restParams,
                view: "table"
            };
        });
    };

    const handleDelete = (universityId) => {
        setDeleteUni(universities.find((university) => university.id === universityId));
    };

    const handleDeleteConfirm = (universityId) => {
        dispatch(deleteUniversity({ id: universityId, uuid })).then((response) => {
            if (response.type === "university/deleteUniversity/fulfilled") {
                setDeleteUni(null);
            }
        });
    };

    return (
        <React.Fragment>
            {(universityState.message || universityState.error) && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={true}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                >
                    <Alert sx={{ width: "100%", mb: 2 }} severity={universityState.error ? "error" : "success"}>
                        {universityState.message || universityState.error}
                    </Alert>
                </Snackbar>
            )}
            {urlParams.view === "table" && (
                <React.Fragment>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            startIcon={<FilterList />}
                            onClick={toggleFilterBox}
                        >
                            Filters
                        </Button>
                    </Box>
                    <FilterUniversityCollapse
                        searchFilters={searchFilters}
                        handleSearchChange={handleSearchChange}
                        filterBoxVisible={filterBoxVisible}
                    />
                    {!universityState.loading && (
                        <UniversityTable
                            currencyById={currencyById}
                            intakeById={intakeById}
                            majorById={majorById}
                            programById={programById}
                            searchFilters={searchFilters}
                            filterCourse={filterCourse}
                            setUrlParams={setUrlParams}
                            universities={universities}
                            handleDelete={handleDelete}
                        />
                    )}
                </React.Fragment>
            )}
            {urlParams.view === "details" && urlParams.id && universities.length > 0 && (
                <UniversityDetails
                    uuid={uuid}
                    universityId={urlParams.id}
                    setUrlParams={setUrlParams}
                    onBack={handleBackToTable}
                />
            )}
            {urlParams.view === "add" && (
                <AddUniversity
                    isGoogleMapsScriptLoaded={isGoogleMapsScriptLoaded}
                    onBack={handleBackToTable}
                />
            )}
            {deleteUni && (
                <DeleteConfirmationDialog
                    open={true}
                    handleClose={() => setDeleteUni(null)}
                    onConfirm={() => handleDeleteConfirm(deleteUni.id)}
                    contentText={getDeleteConfirmationText(deleteUni)}
                    state={universityState}
                />
            )}
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1000,
                }}
                open={universityState.loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </React.Fragment>
    );
};

export default University;
