import Avatar from "@mui/material/Avatar";
import EventIcon from "@mui/icons-material/Event";
import FaceIcon from "@mui/icons-material/Face";
import MockTestIcon from "@mui/icons-material/Assignment";
import OtherIcon from "@mui/icons-material/Help";
import PhoneIcon from "@mui/icons-material/Phone";
import React from "react";
import ReferralIcon from "@mui/icons-material/People";
import SchoolIcon from "@mui/icons-material/School";
import VideocamIcon from "@mui/icons-material/Videocam";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { changeCommentState } from "./store/comment.slice";
import { changeDraftLeadState } from "./store/draftLead.slice";
import { changeExternalEventState } from "./store/externalEvent.slice";
import { changeIntakeState } from "./store/intake.slice";
import { changeLeadState } from "./store/lead.slice";
import { changeMajorState } from "./store/major.slice";
import { changeNotificationState } from "./store/notification.slice";
import { changeProgramState } from "./store/program.slice";
import { changeSchoolApplicationState } from "./store/schoolApplication.slice";
import { changeSchoolState } from "./store/school.slice";
import { changeStudentState } from "./store/student.slice";
import { changeUniversityApplicationState } from "./store/universityApplication.slice";
import { changeUniversityState } from "./store/university.slice";

export const LOGO_SRC = "/static/images/edvantage-logo.png";

export const SOURCE_CHOICES = {
	DC: "Direct Call",
	Referral: "Referral",
	WhatsApp: "WhatsApp",
	Events: "Events",
	SV: "School visits",
	IMT: "IELTS Mock Tests",
	Other: "Other",
};

export const SOURCE_DETAILS = {
	DC: { color: "blue", icon: <PhoneIcon color="blue" /> },
	Referral: { color: "green", icon: <ReferralIcon color="green" /> },
	WhatsApp: { color: "teal", icon: <WhatsAppIcon color="teal" /> },
	Events: { color: "purple", icon: <EventIcon color="purple" /> },
	SV: { color: "orange", icon: <SchoolIcon color="orange" /> },
	IMT: { color: "red", icon: <MockTestIcon color="red" /> },
	Other: { color: "gray", icon: <OtherIcon color="gray" /> },
};

export const CONSULTATION_CHOICES = {
	Telephone: "Telephone",
	F2F: "Face 2 Face",
	VC: "Video Chat",
};

export const CONSULTATION_TYPE_DETAILS = {
	Telephone: {
		color: "#20793a",
		icon: <PhoneIcon color="#20793a" />,
	},
	F2F: {
		color: "#ba0505",
		icon: <FaceIcon color="#ba0505" />,
	},
	VC: {
		color: "#341f77",
		icon: <VideocamIcon color="#341f77" />,
	},
};

export const ENGLISH_TEST_STATUS_CHOICES = [
	{ value: "Ready", label: "Ready", color: "success" },
	{ value: "Scheduled", label: "Scheduled", color: "info" },
	{ value: "Not Scheduled", label: "Not Scheduled", color: "warning" },
	{ value: "Exempted", label: "Exempted", color: "default" },
];

export const LEAD_STATUS_CHOICES = [
	{ value: "AD", label: "Awaiting Documents", is_stale: true },
	{ value: "RC", label: "Requirements Complete", is_stale: true },
	{ value: "CP", label: "Cannot Proceed", is_stale: true },
	{ value: "Revived", label: "Revived", is_stale: true },
	{ value: "NR", label: "Not Reachable", is_stale: true },
	{ value: "Abandoned", label: "Abandoned", is_stale: true },
	{ value: "RF", label: "Require Follow up", is_stale: true },
	{ value: "Deffered", label: "Deffered", is_stale: true },
	{ value: "To Be Consulted", label: "To Be Consulted" },
	{ value: "Research Ongoing", label: "Research Ongoing" },
	{ value: "Awaiting Docs", label: "Awaiting Docs" },
	{ value: "Apps Sent", label: "Apps Sent" },
	{ value: "Awaiting Decision", label: "Awaiting Decision" },
	{ value: "Awaiting Visa Docs", label: "Awaiting Visa Docs" },
	{ value: "Visa Pending", label: "Visa Pending" },
	{ value: "Converted", label: "Converted" },
	{ value: "Needs Follow-up", label: "Needs Follow-up" },
	{ value: "On Hold", label: "On Hold" },
	{ value: "Deferred", label: "Deferred" },
	{ value: "Will Reach Out", label: "Will Reach Out" },
	{ value: "Other Agent", label: "Other Agent" },
	{ value: "Stopped Replying", label: "Stopped Replying" },
	{ value: "No Response", label: "No Response" },
	{ value: "Not Eligible", label: "Not Eligible" },
	{ value: "Unreachable", label: "Unreachable" },
];

export const LEAD_STATUS_COLORS = {
	New: "#1E88E5", // Blue
	AD: "#26C6DA", // Cyan
	RC: "#43A047", // Green
	CP: "#E53935", // Red
	Revived: "#FFB300", // Amber
	Converted: "#66BB6A", // Light Green
	NR: "#9E9E9E", // Grey
	Abandoned: "#D32F2F", // Dark Red
	RF: "#FF7043", // Deep Orange
	Deffered: "#8E24AA",
};

export const APPLICATION_STATUS_CHOICES = {
	Accepted: "Accepted",
	"Conditional Offer": "Conditional Offer",
	"Unconditional Offer": "Unconditional Offer",
	Submitted: "Submitted",
	Rejected: "Rejected",
	Withdrawn: "Withdrawn",
	Pending: "Pending",
};

export const APPLICATION_YEAR_CHOICES = {
	2014: "2014",
	2015: "2015",
	2016: "2016",
	2017: "2017",
	2018: "2018",
	2019: "2019",
	2020: "2020",
	2021: "2021",
	2022: "2022",
	2023: "2023",
	2024: "2024",
	2025: "2025",
	2026: "2026",
	2027: "2027",
	2028: "2028",
	2029: "2029",
	2030: "2030",
};

export const PARTNERSHIP_STATUS_CHOICES = [
	{ value: "Partner", label: "Partner" },
	{ value: "Non Partner", label: "Non Partner" },
	{ value: "In Process", label: "In Process" },
];

export const GRADE_TYPE_CHOICES = {
	"ALL": "Overall",
	"SUB": "Individual Subjects",
};

export const GRADE_SYSTEM_CHOICES = {
	"4.0": "4.0 Scale",
	"5.0": "5.0 Scale",
	"10.0": "10.0 Scale",
	100: "100 Point Scale",
	"A-F": "Letter Grades (A-F)",
};

export const APPLICATION_METHOD_DETAILS = {
	UCAS: { color: "#B03A2E" }, // Dark Red
	Direct: { color: "#0B5345" }, // Dark Green
	Pathway: { color: "#1A5276" }, // Dark Blue
	Other: { color: "#6C3483" }, // Dark Purple
};

export const UNIVERSITY_STATUS_DETAILS = {
	Merit: { color: "#267FC9" },
	"Non-Merit": { color: "#06751A" },
	Unknown: { color: "#84858C" },
};

export const DRAFT_LEAD_STATUS = {
	'New': {
		color: "#1E88E5", // Blue
	},
	'Not Reachable': {
		color: "#FFB300", // Amber
	},
	'Need more info': {
		color: "#FF7043", // Deep Orange
	},
	'Converted': {
		color: "#66BB6A", // Light Green
	}
};

export const GENDER_CHOICES = {
	Male: "Male",
	Female: "Female",
};

export const GENDER_DETAILS = {
	Male: {
		color: "#1E90FF",
		avatar: <Avatar alt="Male" src="/static/images/avatars/avatar_2.jpg" />,
	},
	Female: {
		color: "#FF2C97",
		avatar: <Avatar alt="Female" src="/static/images/avatars/avatar_1.jpg" />,
	},
	Unknown: {
		color: "#2E2E2F",
		avatar: <Avatar alt="Unknown" src="/static/images/avatars/user.jpg" />,
	},
};

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const GOOGLE_MAPS_LIBRARIES = ["places"];

export const PROGRAM_CATEGORY_CHOICES = {
	School: "School",
	University: "University",
};

export const INTAKE_SEASON_CHOICES = {
	SPRING: "Spring (Jan-May)",
	FALL: "Fall (Sept)",
	SUMMER: "Summer (June-Aug)",
	JANUARY: "Jan",
	SEPTEMBER: "Sept",
	NOVEMBER: "Nov",
};

export const formatFee = (fee) => {
	if (!fee) return "";

	if (fee >= 1e9) return (fee / 1e9).toFixed(1) + 'B'; // Billions
	if (fee >= 1e6) return (fee / 1e6).toFixed(1) + 'M'; // Millions
	if (fee >= 1e3) return (fee / 1e3).toFixed(1) + 'K'; // Thousands
	return fee.toString(); // If below 1,000, no suffix
};

export const getFileNameFromUrl = (url) => {
	return url ? url.split('/').pop().split('?')[0] : "";
};

export const modelStateChangeMap = {
	comment: changeCommentState,
	draftlead: changeDraftLeadState,
	employeenotification: changeNotificationState,
	externalevent: changeExternalEventState,
	intake: changeIntakeState,
	major: changeMajorState,
	program: changeProgramState,
	lead: changeLeadState,
	school: changeSchoolState,
	schoolapplication: changeSchoolApplicationState,
	student: changeStudentState,
	university: changeUniversityState,
	application: changeUniversityApplicationState,
};

export const ALLOWED_FILE_TYPES = [
	"application/pdf", // PDF files
	"image/jpeg",      // JPEG images
	"image/png",       // PNG images
	"application/msword", // DOC files
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX files
];
