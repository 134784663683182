import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';

import { fetchUniversityApplications } from '../store/universityApplication.slice';

function useApplication() {

    const dispatch = useDispatch();
    const lockRef = useRef(false);

    const applications = useSelector((state) => state.universityApplication.applications);
    const applicationStatus = useSelector((state) => state.universityApplication.status);

    useEffect(() => {
        async function onLoad() {
            if (applicationStatus === 'idle' && !lockRef.current) {
                lockRef.current = true;
                dispatch(fetchUniversityApplications()).finally(() => {
                    // Release lock after completion
                    lockRef.current = false;
                });
            }
        }

        onLoad();
    }, [applicationStatus, dispatch]);

    return {
        applications,
        applicationStatus
    };
}

export default useApplication;
