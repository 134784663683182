import {
	Alert,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
} from "@mui/material";
import {
	ENGLISH_TEST_STATUS_CHOICES,
	GRADE_SYSTEM_CHOICES,
	GRADE_TYPE_CHOICES,
	LEAD_STATUS_CHOICES,
	SOURCE_CHOICES,
} from "../../const";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { arraysEqual, generateMenuItems } from "../../utils/Utils";
import { autoPopulateGPA, validateSubjectsGrades } from "./utils";
import { fetchCitiesForCountries, setCities } from "../../store/city.slice";
import { setLoading, updateLead } from "../../store/lead.slice";
import { useDispatch, useSelector } from "react-redux";

import { Autocomplete } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EdvantageAutocomplete from "../common/EdvantageAutocomplete";
import GenericMultiSelect from "../common/GenericMultiSelect";
import ReactCountryFlag from 'react-country-flag';
import _ from "lodash";
import { fetchExternalEvents } from "../../store/externalEvent.slice";
import { useEdvantageContext } from "../../EdvantageProvider";
import useEdvantageFetch from "../../hooks/useEdvantageFetch";

/**
 * A Dialog component for editing a Lead.
 *
 * @param {Object} props The component's props.
 * @param {boolean} props.open Whether the dialog is open or not.
 * @param {Function} props.onClose The function to call when the dialog is closed.
 * @param {Object} props.lead The lead to be edited.
 *
 * @returns {JSX.Element} The Dialog component.
 */
export default function LeadEditDialog({ open, onClose, lead }) {
	const dispatch = useDispatch();

	const { data: externalEvents, state: externalEventState } = useEdvantageFetch('externalEvent', 'events', fetchExternalEvents);

	const cityState = useSelector(state => state.city);
	const cities = cityState.cities;

	const hasCitiesLoaded = lead.preferred_countries_objs.every(country => {
		return cityState.country2cities[country.code2];
	});

	useEffect(() => {
		if (lead.preferred_countries_objs && lead.preferred_countries_objs.length > 0) {
			dispatch(fetchCitiesForCountries(lead.preferred_countries_objs));
		}
	}, [dispatch, lead.preferred_countries_objs]);

	const leadState = useSelector((state) => state.lead);

	const { countries, employees, intakes, majors, programs, services, uuid } = useEdvantageContext();

	const [formState, setFormState] = useState(lead);
	const [formErrors, setFormErrors] = useState({});
	const [updatedFields, setUpdatedFields] = useState({});
	const [isLeadUpdated, setIsLeadUpdated] = useState(false);
	const [isGradesPerSubjectUpdated, setIsGradesPerSubjectUpdated] = useState(false);

	const today = useMemo(() => new Date().toISOString().split("T")[0], []);
	const isFormValid = useMemo(() => Object.keys(formErrors).length === 0, [formErrors]);

	useEffect(() => {
		if (["Ready", "Scheduled"].includes(formState.english_test_status)) {
			const testDateError = validateField(
				"test_date",
				formState.test_date
			);
			setFormErrors((prev) => {
				const updatedErrors = { ...prev };
				if (testDateError) {
					updatedErrors["test_date"] = testDateError;
				} else {
					delete updatedErrors["test_date"];
				}
				return updatedErrors;
			});
		} else {
			setFormState((prev) => ({ ...prev, test_date: null }));
			setUpdatedFields((prev) => ({ ...prev, test_date: null }));
		}

		if (formState.english_test_status !== "Ready") {
			setFormState((prev) => ({
				...prev,
				toefl_score: null,
				ielts_score: null,
			}));
			setUpdatedFields((prev) => ({
				...prev,
				toefl_score: null,
				ielts_score: null,
			}));
		}
	}, [formState.english_test_status]);

	const isValidGPA = (gpa, scale) => {
		switch (scale) {
			case "4.0":
				return gpa >= 0.0 && gpa <= 4.0;
			case "5.0":
				return gpa >= 0.0 && gpa <= 5.0;
			case "10.0":
				return gpa >= 0.0 && gpa <= 10.0;
			case "100":
				return gpa >= 0.0 && gpa <= 100;
			case "A-F":
				return /^[A-F]$/i.test(gpa);
			default:
				return false; // Unknown scale
		}
	};

	const isValidToeflScore = (score) => {
		if (score === null || score === undefined || score === "") return false;
		return score >= 0 && score <= 120;
	};

	const isValidIeltsScore = (score) => {
		if (score === null || score === undefined || score === "") return false;
		return score >= 0 && score <= 9;
	};

	const validateField = (name, value) => {
		let error;
		switch (name) {
			case "status":
				if (!value) {
					error = "Status is required";
				}
				break;
			case "case_manager":
				if (!value) {
					error = "Case Manager is required";
				}
				break;
			case "source":
				if (!value) {
					error = "Source is required";
				}
				break;
			case "grade_system":
				if (!value) {
					error = "Grade System is required";
				}
				break;
			case "gpa":
				if (!isValidGPA(value, formState.grade_system)) {
					error = "GPA is not valid for the selected grading scale";
				}
				break;
			case "english_test_status":
				if (!value) {
					error = "English Test Status is required";
				}
				break;
			case "test_date":
				if (
					["Ready", "Scheduled"].includes(
						formState.english_test_status
					)
				) {
					if (!value) {
						error = "Test Date is required";
					} else if (
						formState.english_test_status === "Ready" &&
						new Date(value) > new Date()
					) {
						error = "Test Date cannot be in the future";
					} else if (
						formState.english_test_status === "Scheduled" &&
						new Date(value) < new Date()
					) {
						error = "Scheduled Date cannot be in the past";
					}
				}
				break;
			case "toefl_score":
				if (
					formState.english_test_status === "Ready" &&
					!isValidToeflScore(value) &&
					!isValidIeltsScore(formState.ielts_score)
				) {
					error = "Invalid TOEFL score.";
				}
				break;
			case "ielts_score":
				if (
					formState.english_test_status === "Ready" &&
					!isValidIeltsScore(value) &&
					!isValidToeflScore(formState.toefl_score)
				) {
					error = "Invalid IELTS score.";
				}
				break;
			case "intakes":
				if (value.length === 0) {
					error = "At least one intake is required";
				}
				break;
			case "preferred_programs":
				if (value.length === 0) {
					error = "At least one program is required";
				}
				break;
			case "preferred_majors":
				if (value.length === 0) {
					error = "At least one major is required";
				}
				break;
			default:
				break;
		}
		return error;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		const parsedValue = ["toefl_score", "ielts_score"].includes(name)
			? parseFloat(value) || ""
			: value;

		const updateFormState = {
			...formState,
			[name]: parsedValue,
		};

		let changedFields = updatedFields;
		if (
			(Array.isArray(parsedValue) &&
				!arraysEqual(parsedValue, lead[name])) ||
			(!Array.isArray(parsedValue) && parsedValue !== lead[name])
		) {
			changedFields = { ...changedFields, [name]: parsedValue };
		} else {
			delete changedFields[name];
		}

		if (name === 'preferred_countries') {
			// Determine which countries were removed
			const removedCountryIds = formState.preferred_countries.filter(countryId => !parsedValue.includes(countryId));

			if (removedCountryIds.length) {
				// Get the country objects for the removed IDs
				const removedCountries = countries.filter(country => removedCountryIds.includes(country.id));

				// Remove cities associated with removed countries
				const filteredCities = formState.preferred_cities.filter(city => {
					// Check if the city belongs to any removed country
					return !removedCountries.some(removedCountry => removedCountry.code2 === city.countryCode);
				});

				updateFormState.preferred_cities = filteredCities;
				changedFields = { ...changedFields, preferred_cities: filteredCities };

				// Optionally dispatch action to update cities in your state
				const newCities = cities.filter(city => !removedCountries.some(removedCountry => removedCountry.code2 === city.countryCode));
				dispatch(setCities(newCities));
			} else {
				// If no countries are removed, fetch cities for the selected countries
				const selectedCountries = parsedValue.map(countryId => {
					return countries.find(c => c.id === countryId) || null; // Return the country object or null if not found
				}).filter(country => country !== null);
				dispatch(fetchCitiesForCountries(selectedCountries));
			}
		} else if (name === "grade_type") {
			if (parsedValue === "ALL") {
				updateFormState.grades_per_subject = [];
			} else {
				updateFormState.grades_per_subject = lead.grades_per_subject;
			}
		}

		setFormState(updateFormState);
		setUpdatedFields(changedFields);
		setIsLeadUpdated(Object.keys(changedFields).length > 0);

		const error = validateField(name, parsedValue);
		setFormErrors((prev) => {
			const updatedErrors = { ...prev };

			// Add or remove the error for the current field
			if (error) {
				updatedErrors[name] = error;
			} else {
				delete updatedErrors[name];

				// Handle mutual exclusivity for TOEFL and IELTS scores
				if (name === "toefl_score") {
					delete updatedErrors["ielts_score"];
				} else if (name === "ielts_score") {
					delete updatedErrors["toefl_score"];
				}
			}

			if (["source", "source_external_event"].includes(name)) {
				if (parsedValue === "Events" && !formState.source_external_event) {
					updatedErrors["source_external_event"] = "Event name is required when source is 'Events'";
				} else {
					delete updatedErrors["source_external_event"];
				}
			}

			return updatedErrors;
		});
	};

	const handleDeleteGrade = (index) => {
		const updatedGrades = formState.grades_per_subject.filter(
			(_, i) => i !== index
		);
		setFormState({
			...formState,
			grades_per_subject: updatedGrades,
		});
	};

	const handleAddGrade = () => {
		const updatedGrades = [...formState.grades_per_subject, { subject: "", grade: "" }];
		setFormState({ ...formState, grades_per_subject: updatedGrades });
	};

	const handleSave = useCallback(() => {
		if (!isFormValid) return;

		const fieldsToUpdate = { ...updatedFields };
		if (formState.source !== "Events") {
			fieldsToUpdate.source_external_event = "";
		}
		dispatch(updateLead({ id: formState.id, lead: fieldsToUpdate, uuid })).then((response) => {
			if (response.type === "lead/updateLead/fulfilled") {
				onClose();
			}
		});
	}, [isFormValid, dispatch, formState.id, updatedFields, uuid, onClose]);

	const handleSubjectGradeChange = (index, field, value) => {

		const updatedSubjectsGrades = formState.grades_per_subject.map((item, i) =>
			i === index ? { ...item, [field]: value } : item
		);
		setFormState({ ...formState, grades_per_subject: updatedSubjectsGrades });
	};

	useEffect(() => {
		if (formState.grade_type === "SUB") {
			const hasGradesPerSubjectChanged = !_.isEqual(formState.grades_per_subject, lead.grades_per_subject);
			setIsGradesPerSubjectUpdated(hasGradesPerSubjectChanged);

			// Synchronize updated fields
			setUpdatedFields(prevFields => {
				if (hasGradesPerSubjectChanged) {
					return { ...prevFields, grades_per_subject: formState.grades_per_subject };
				} else {
					const { grades_per_subject, ...rest } = prevFields;
					return rest;
				}
			});

			if (formState.grades_per_subject.length > 0) {
				const newErrors = validateSubjectsGrades(formState.grades_per_subject, formState.grade_system);
				setFormErrors(prevErrors => {
					// Filter out subject and grade keys from prevErrors
					const updatedFormErrors = Object.fromEntries(
						Object.entries(prevErrors).filter(
							([key]) => !key.startsWith('subject_') && !key.startsWith('grade_')
						)
					);

					// Merge filtered errors with newErrors
					return { ...updatedFormErrors, ...newErrors };
				});

				if (Object.keys(newErrors).length === 0) {
					setFormState({ ...formState, gpa: autoPopulateGPA(formState.grades_per_subject, formState.grade_system) });
				}
				delete formErrors.gpa;
			} else {
				setFormState({ ...formState, gpa: -1 });
				setFormErrors({ ...formErrors, gpa: "Please add a grade" });
			}
		} else {
			if (!isValidGPA(formState.gpa, formState.grade_system)) {
				setFormErrors({ ...formErrors, gpa: "GPA is not valid for the selected grading scale" });
			}
		}
	}, [formState.grades_per_subject, formState.grade_system, formState.grade_type]);

	return (
		<React.Fragment>
			<Dialog open={open} onClose={onClose}>
				<DialogTitle>
					Edit Lead for {formState.student.full_name}
				</DialogTitle>
				<DialogContent>
					{leadState.error && (
						<Alert
							sx={{ width: "100%", mt: 2, mb: 2 }}
							severity="error"
						>
							{leadState.error}
						</Alert>
					)}
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<TextField
								select
								margin="dense"
								name="status"
								label="Status"
								value={formState.status}
								onChange={handleChange}
								error={Boolean(formErrors.status)}
								helperText={formErrors.status}
								fullWidth
								required
							>
								{LEAD_STATUS_CHOICES.map((option) => (
									<MenuItem
										key={option.value}
										value={option.value}
									>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item xs={12} sm={6}>
							<EdvantageAutocomplete
								name="case_manager"
								label="Case Manager"
								value={formState.case_manager}
								margin="dense"
								onChange={(e) =>
									handleChange({ target: { name: "case_manager", value: e.target.value } })
								}
								options={employees}
								getOptionLabel={(option) => option.full_name}
								getOptionValue={(newValue) => newValue.id}
								isOptionEqualToValue={(option, value) =>
									option.id === value
								}
								required
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								select
								margin="dense"
								name="source"
								label="Source"
								value={formState.source}
								onChange={handleChange}
								error={Boolean(formErrors.source)}
								helperText={formErrors.source}
								fullWidth
								required
							>
								{generateMenuItems(SOURCE_CHOICES)}
							</TextField>
						</Grid>
						{formState.source === "Events" && (
							<Grid item xs={12} sm={6}>
								<EdvantageAutocomplete
									name="source_external_event"
									label="Event Name"
									value={formState.source_external_event}
									onChange={handleChange}
									margin="dense"
									error={Boolean(formErrors.source_external_event)}
									helperText={formErrors.source_external_event}
									options={externalEvents}
									getOptionLabel={(option) =>
										option.name
									}
									getOptionValue={(newValue) => newValue.id}
									isOptionEqualToValue={(option, value) =>
										option.id === value
									}
									loading={externalEventState.loading}
								/>
							</Grid>
						)}
						<Grid item xs={12} sm={formState.source === "Events" ? 12 : 6}>
							<FormControl
								margin="dense"
								fullWidth
								error={Boolean(formErrors.consultation_type)}
							>
								<InputLabel id="consultation-select-label">
									Consultation Type
								</InputLabel>
								<Select
									labelId="consultation-select-label"
									id="consultation-select"
									value={formState.consultation_type}
									label="Consultation Type"
									name="consultation_type"
									onChange={handleChange}
								>
									<MenuItem key={1} value={"Telephone"}>
										Telephone
									</MenuItem>
									<MenuItem key={0} value={"F2F"}>
										Face 2 Face
									</MenuItem>
									<MenuItem key={2} value={"VC"}>
										Video Chat
									</MenuItem>
								</Select>
								{formErrors.consultation_type && (
									<FormHelperText>
										{formErrors.consultation_type}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								select
								margin="dense"
								name="grade_type"
								label="Grade Type"
								value={formState.grade_type || ""}
								onChange={handleChange}
								error={Boolean(formErrors.grade_type)}
								helperText={formErrors.grade_type}
								fullWidth
							>
								{generateMenuItems(GRADE_TYPE_CHOICES)}
							</TextField>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								select
								margin="dense"
								name="grade_system"
								label="Grade System"
								value={formState.grade_system || ""}
								onChange={handleChange}
								error={Boolean(formErrors.grade_system)}
								helperText={formErrors.grade_system}
								fullWidth
							>
								{generateMenuItems(GRADE_SYSTEM_CHOICES)}
							</TextField>
						</Grid>
						{formState.grade_type === "SUB" && formState.grades_per_subject.map((item, index) => (
							<React.Fragment key={`grades-per-subject-${index}`}>
								<Grid item xs={6}>
									<TextField
										select
										margin="dense"
										label="Subject"
										fullWidth
										value={item.subject}
										onChange={(e) => handleSubjectGradeChange(index, "subject", e.target.value)}
										error={Boolean(formErrors[`subject_${index}`])}
										helperText={formErrors[`subject_${index}`]}
									>
										<MenuItem value="Math">Math</MenuItem>
										<MenuItem value="Science">Science</MenuItem>
										<MenuItem value="English">English</MenuItem>
									</TextField>
								</Grid>
								<Grid item xs={5}>
									<TextField
										margin="dense"
										label="Grade"
										type="text"
										fullWidth
										value={item.grade}
										onChange={(e) => handleSubjectGradeChange(index, "grade", e.target.value)}
										error={Boolean(formErrors[`grade_${index}`])}
										helperText={formErrors[`grade_${index}`]}
									/>
								</Grid>
								<Grid item xs={1}>
									<Tooltip title="Delete">
										<IconButton
											sx={{ top: "25%" }}
											color="error"
											onClick={() => handleDeleteGrade(index)}
										>
											<DeleteIcon />
										</IconButton>
									</Tooltip>
								</Grid>
							</React.Fragment>
						))}
						{formState.grade_type === "SUB" && (
							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item xs={4}>
										<Button
											variant="contained"
											color="primary"
											onClick={handleAddGrade}
											disabled={formState.grades_per_subject.length > 5}
										>
											{formState.grades_per_subject.length > 0 ? "Add more grades" : "Add grade"}
										</Button>
									</Grid>
								</Grid>
							</Grid>
						)}
						<Grid item xs={12}>
							<TextField
								margin="dense"
								name="gpa"
								label="GPA"
								type="text"
								fullWidth
								value={formState.gpa}
								onChange={handleChange}
								disabled={formState.grade_type === "SUB"}
								error={Boolean(formErrors.gpa)}
								helperText={formErrors.gpa}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								select
								margin="dense"
								name="english_test_status"
								label="English Test Status"
								value={formState.english_test_status}
								onChange={handleChange}
								error={Boolean(formErrors.english_test_status)}
								helperText={formErrors.english_test_status}
								fullWidth
								required
							>
								{ENGLISH_TEST_STATUS_CHOICES.map((option) => (
									<MenuItem
										key={option.value}
										value={option.value}
									>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								margin="dense"
								name="test_date"
								label="Test Date"
								type="date"
								fullWidth
								value={formState.test_date || ""}
								onChange={handleChange}
								error={Boolean(formErrors.test_date)}
								helperText={formErrors.test_date}
								InputLabelProps={{
									shrink: true,
								}}
								inputProps={{
									min:
										formState.english_test_status ===
											"Scheduled"
											? today
											: undefined,
									max:
										formState.english_test_status === "Ready"
											? today
											: undefined,
								}}
								disabled={
									!["Ready", "Scheduled"].includes(
										formState.english_test_status
									)
								}
							/>
						</Grid>
						{formState.english_test_status === "Ready" && (
							<>
								<Grid item xs={12} sm={6}>
									<TextField
										margin="dense"
										name="toefl_score"
										label="TOEFL Score"
										type="number"
										fullWidth
										value={formState.toefl_score || ""}
										onChange={handleChange}
										error={Boolean(formErrors.toefl_score)}
										helperText={formErrors.toefl_score}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										margin="dense"
										name="ielts_score"
										label="IELTS Score"
										type="number"
										fullWidth
										value={formState.ielts_score || ""}
										onChange={handleChange}
										error={Boolean(formErrors.ielts_score)}
										helperText={formErrors.ielts_score}
									/>
								</Grid>
							</>
						)}
						<Grid item xs={12}>
							<GenericMultiSelect
								name="intakes"
								label="Preferred Intakes"
								value={formState.intakes}
								onChange={handleChange}
								error={Boolean(formErrors.intakes)}
								helperText={formErrors.intakes}
								options={intakes}
								getOptionLabel={(option) => option.text}
								optionValueKey="id"
								limitTags={2}
								margin={"dense"}
							/>
						</Grid>
						<Grid item xs={12}>
							<GenericMultiSelect
								name="preferred_programs"
								label="Preferred Programs"
								value={formState.preferred_programs}
								onChange={handleChange}
								error={Boolean(formErrors.preferred_programs)}
								helperText={formErrors.preferred_programs}
								options={programs}
								getOptionLabel={(option) => option.name}
								optionValueKey="id"
								limitTags={1}
								margin={"dense"}
							/>
						</Grid>
						<Grid item xs={12}>
							<GenericMultiSelect
								name="preferred_majors"
								label="Preferred Majors"
								value={formState.preferred_majors}
								onChange={handleChange}
								error={Boolean(formErrors.preferred_majors)}
								helperText={formErrors.preferred_majors}
								options={majors}
								getOptionLabel={(option) => option.name}
								optionValueKey="id"
								limitTags={2}
								margin={"dense"}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<GenericMultiSelect
								name="preferred_countries"
								label="Preferred Countries"
								value={formState.preferred_countries}
								limitTags={1}
								onChange={handleChange}
								error={Boolean(formErrors.preferred_countries)}
								helperText={formErrors.preferred_countries}
								options={countries}
								getOptionLabel={(option) => option.name}
								optionValueKey="id"
								margin={"dense"}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Autocomplete
								multiple
								name='preferred_cities'
								options={cities}
								loading={cityState.loading} // Show loading spinner if cities are being fetched
								value={hasCitiesLoaded ? formState.preferred_cities : []}
								onChange={(event, newValue) => handleChange({ target: { name: 'preferred_cities', value: newValue } })}
								renderInput={(params) => (
									<TextField
										{...params}
										margin="dense"
										label="Preferred Cities"
										error={Boolean(formErrors.preferred_cities)}
										helperText={formErrors.preferred_cities}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<>
													{cityState.loading ? <CircularProgress color="inherit" size={20} /> : null}
													{params.InputProps.endAdornment}
												</>
											),
										}}
									/>
								)}
								renderOption={(props, option) => {
									// Destructure the key from the props
									const { key, ...restProps } = props;
									return (
										<li
											key={`${option.countryCode}-${option.name}`} // Directly assign the key
											{...restProps} // Spread remaining props
										>
											<ReactCountryFlag
												countryCode={option.countryCode}
												svg
												style={{
													width: "1.5em",
													height: "1.5em",
													marginRight: "8px",
												}}
											/>
											{option.name}
										</li>
									);
								}}
								getOptionLabel={(option) => option.name}
								isOptionEqualToValue={(option, value) => option.name === value.name}
							/>
						</Grid>
						<Grid item xs={12}>
							<GenericMultiSelect
								name="additional_services"
								label="Additional Services"
								value={formState.additional_services || []}
								onChange={handleChange}
								options={services}
								getOptionLabel={(option) => option.name}
								optionValueKey="id"
								limitTags={5}
								margin={"dense"}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions sx={{ paddingRight: 3 }}>
					<Button variant="outlined" onClick={onClose} color="error">
						Cancel
					</Button>
					<Button
						variant="contained"
						onClick={handleSave}
						color="primary"
						disabled={(!isLeadUpdated && !isGradesPerSubjectUpdated) || !isFormValid}
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
