import * as React from "react";

import {
    AppBar,
    Avatar,
    Box,
    Card,
    CardMedia,
    Divider,
    IconButton,
    InputAdornment,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    TextField,
    Toolbar,
    Typography,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/material/styles";
import { useEdvantageContext } from "../../EdvantageProvider";
import useWebSocket from "../../hooks/useWebSocket";

const RootContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    height: "100vh",
    backgroundColor: theme.palette.background.default,
}));

const ThreadList = styled(Box)(({ theme }) => ({
    width: "30%",
    borderRight: `1px solid ${theme.palette.divider}`,
    overflowY: "auto",
}));

const ChatArea = styled(Box)(() => ({
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflow: "hidden",
}));

const ChatHeader = styled(AppBar)(() => ({
    position: "sticky",
    top: 0,
    zIndex: 1,
}));

const ChatMessages = styled(Box)(() => ({
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    height: "100%",
    overflowY: "auto",
    '&::-webkit-scrollbar': {
        display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
    },
}));

const ChatInputContainer = styled(Paper)(() => ({
    display: "flex",
    alignItems: "center",
    padding: "0.5rem",
    borderTop: "1px solid #e0e0e0",
}));

function WhatsAppChat() {

    const { uuid: clientUuid } = useEdvantageContext();

    const [threads, setThreads] = useState([
        { id: 1, name: "John Doe", lastMessage: "Hey, how are you?" },
        { id: 2, name: "Jane Smith", lastMessage: "Can we meet tomorrow?" },
        { id: 3, name: "Alice Johnson", lastMessage: "Sure! Send me the details." },
    ]);
    const [selectedThread, setSelectedThread] = useState(threads[0]);
    const [messages, setMessages] = useState([
        { sender: "John Doe", text: "Hey, how are you?" },
        { sender: "You", text: "I’m good, thanks! How about you?" },
    ]);
    const [newMessage, setNewMessage] = useState("");
    const onMessageCallback = useCallback((data) => {
        console.log("Received message on client:", data);
        setMessages((prevMessages) => [...prevMessages, data.message]);
    }, []);

    const { sendMessage } = useWebSocket(`${process.env.REACT_APP_WS_URL}/whatsapp/chat/`, onMessageCallback);

    const messagesEndRef = useRef(null);

    useEffect(() => {
        // Scroll to the bottom of the chat messages
        messagesEndRef.current?.scrollIntoView({ behaviour: "smooth" });
    }, [messages]);

    const handleSelectThread = (thread) => {
        setSelectedThread(thread);
        // Replace with API call to fetch thread messages
        setMessages([
            { sender: thread.name, text: thread.lastMessage },
        ]);
    };

    const handleSendMessage = () => {
        if (newMessage.trim()) {
            setMessages((prev) => [...prev, { sender: "You", text: newMessage }]);
            setNewMessage("");
            sendMessage({ sender: "You", text: newMessage, receiver: "+447500814934" });
        }
    };

    const handleAttachFile = () => {
        alert("Attach file functionality coming soon!");
    };

    const openPreview = () => {
        console.log("Preview functionality coming soon!");
    };

    return (
        <RootContainer>
            {/* Thread List */}
            <ThreadList>
                <AppBar position="static" color="primary">
                    <Toolbar>
                        <Typography variant="h6">Chats</Typography>
                    </Toolbar>
                </AppBar>
                <List>
                    {threads.map((thread) => (
                        <React.Fragment key={thread.id}>
                            <ListItem
                                onClick={() => handleSelectThread(thread)}
                                sx={{
                                    cursor: "pointer",
                                    backgroundColor: selectedThread.id === thread.id ? "action.selected" : "inherit",
                                    "&:hover": {
                                        backgroundColor: "action.hover",
                                    },
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        <ChatBubbleOutlineIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={thread.name}
                                    secondary={thread.lastMessage}
                                />
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
            </ThreadList>

            {/* Chat Area */}
            <ChatArea>
                {/* Chat Header */}
                <ChatHeader color="primary">
                    <Toolbar>
                        <Typography variant="h6">{selectedThread.name}</Typography>
                    </Toolbar>
                </ChatHeader>

                {/* Chat Messages */}
                <ChatMessages>
                    {messages.map((msg, index) => (
                        <Box
                            key={index}
                            sx={{
                                alignSelf: msg.sender === "You" ? "flex-end" : "flex-start",
                                maxWidth: "60%", // Adjusts message bubble width
                                backgroundColor: msg.sender === "You" ? "#dcf8c6" : "#fff",
                                padding: "8px 12px",
                                borderRadius: "8px",
                                boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
                            }}
                        >
                            {msg.sender !== "You" && (
                                <Typography variant="caption" sx={{ fontWeight: "bold", display: "block" }}>
                                    {msg.sender}
                                </Typography>
                            )}
                            {msg.text && (
                                <Typography variant="body2" sx={{ marginBottom: msg.media_url ? "8px" : "0", wordBreak: "break-word", }}>
                                    {msg.text}
                                </Typography>
                            )}
                            {/* <Typography variant="body2">{msg.text}</Typography> */}
                            {/* Render Media */}
                            {msg.media_url && (
                                <Card
                                    sx={{
                                        borderRadius: "12px",
                                        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
                                        marginTop: "8px",
                                        overflow: "hidden",
                                    }}
                                >
                                    {msg.mime_type.startsWith("image/") && (
                                        <CardMedia
                                            component="img"
                                            image={msg.media_url}
                                            alt="Image"
                                            sx={{
                                                maxHeight: "300px",
                                                objectFit: "cover",
                                            }}
                                            onClick={openPreview}
                                        />
                                    )}
                                    {msg.mime_type.startsWith("video/") && (
                                        <CardMedia
                                            component="video"
                                            controls
                                            src={msg.media_url}
                                            sx={{
                                                maxHeight: "300px",
                                                objectFit: "cover",
                                            }}
                                            onClick={openPreview}
                                        />
                                    )}
                                    {!msg.mime_type.startsWith("image/") &&
                                        !msg.mime_type.startsWith("video/") && (
                                            <Box
                                                sx={{
                                                    padding: "8px",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Link
                                                    href={msg.media_url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    underline="none"
                                                    sx={{
                                                        fontWeight: "bold",
                                                        fontSize: "14px",
                                                        colour: "#007bff",
                                                        wordBreak: "break-word",
                                                    }}
                                                >
                                                    Download File
                                                </Link>
                                            </Box>
                                        )}
                                </Card>
                            )}
                        </Box>
                    ))}
                    <div ref={messagesEndRef}></div>
                </ChatMessages>

                {/* Chat Input */}
                <ChatInputContainer>
                    <TextField
                        variant="outlined"
                        placeholder="Type a message"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        fullWidth
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                                e.preventDefault(); // Prevents adding a newline
                                handleSendMessage();
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleAttachFile}>
                                        <AttachFileIcon />
                                    </IconButton>
                                    <IconButton onClick={handleSendMessage}>
                                        <SendIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </ChatInputContainer>
            </ChatArea>
        </RootContainer>
    );
}

export default WhatsAppChat;
