import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Step,
	StepLabel,
	Stepper,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
	addSchool,
	clearMessage,
	setLoading,
} from "../../store/school.slice";
import { isValidCurrency, isValidFee } from "../../utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { validateSchoolField, validateSchoolForm } from "./common";

import Alert from "@mui/material/Alert";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { PROGRAM_CATEGORY_CHOICES } from "../../const";
import SchoolCourseForm from "./SchoolCourseForm";
import SchoolForm from "./SchoolForm";
import Snackbar from "@mui/material/Snackbar";
import { useEdvantageContext } from "../../EdvantageProvider";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";

const steps = ["Add School", "Add School Courses"];

export default function AddSchool({ isGoogleMapsScriptLoaded, onBack }) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const {
		programs, programsLoading, currencies, currenciesLoading, uuid
	} = useEdvantageContext();

	const dispatch = useDispatch();
	const schoolState = useSelector((state) => state.school);

	const [activeStep, setActiveStep] = useState(0);

	const initialSchoolData = {
		name: "",
		addressLine1: "",
		addressLine2: "",
		city: "",
		postalCode: "",
		country: "",
		website: "",
		google_maps_url: "",
		google_place_id: "",
		photos: [],
	};

	const [schoolData, setSchoolData] = useState(initialSchoolData);
	const [schoolErrors, setSchoolErrors] = useState({});
	const [isSchoolFormValid, setIsSchoolFormValid] = useState(false);

	const initialCourseData = {
		program: "",
		application_method: "",
		fee: "",
		currency: "",
	};
	const [coursesData, setCoursesData] = useState([initialCourseData]);
	const [coursesErrors, setCoursesErrors] = useState([]);
	const [isCourseFormValid, setIsCourseFormValid] = useState(false);
	const [isFormDirty, setIsFormDirty] = useState(false);

	useUnsavedChangesWarning(isFormDirty);

	useEffect(() => {
		const updatedCoursesErrors = coursesData.map((course) =>
			validateSingleCourse(course)
		);
		const allValid = updatedCoursesErrors.every(
			(errors) => Object.keys(errors).length === 0
		);

		setIsCourseFormValid(allValid);
	}, [coursesData]);

	const handleNext = () => {
		if (activeStep === 0) {
			const errors = validateSchoolForm(schoolData);
			if (Object.keys(errors).length === 0) {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			} else {
				setSchoolErrors(errors);
			}
		} else if (activeStep === 1) {
			const errors = validateCoursesData();
			if (errors.length === 0) {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			} else {
				setCoursesErrors(errors);
			}
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleSchoolChange = (event) => {
		const { name, value } = event.target;
		setIsFormDirty(true);
		const newSchoolData = { ...schoolData, [name]: value };
		setSchoolData(newSchoolData);
		validateSchoolField(name, value, setSchoolErrors);

		setIsSchoolFormValid(Object.keys(validateSchoolForm(newSchoolData)).length === 0);
	};

	const handleSchoolChangeBulk = (updates) => {
		// Update schoolData with all the new values in the updates object

		const newSchoolData = { ...schoolData, ...updates };
		setIsFormDirty(true);
		setSchoolData(newSchoolData);
		const accumulatedErrors = Object.keys(updates).reduce(
			(errors, field) => {
				const fieldError = validateSchoolField(
					field,
					newSchoolData[field]
				);
				if (fieldError) {
					errors[field] = fieldError;
				}
				return errors;
			},
			{}
		);
		setSchoolErrors(accumulatedErrors);
		setIsSchoolFormValid(
			Object.keys(validateSchoolForm(newSchoolData)).length === 0
		);
	};

	const handleCourseChange = (event, index) => {
		const { name, value, type, checked } = event.target;
		const updatedCourses = [...coursesData];
		updatedCourses[index] = {
			...updatedCourses[index],
			[name]: type === "checkbox" ? checked : value,
		};
		setCoursesData(updatedCourses);
		validateCourseField(name, value, index);
	};

	const addCourse = () => {
		setCoursesData([...coursesData, initialCourseData]);
		setCoursesErrors([...coursesErrors, {}]);
	};

	// Function to delete a course
	const handleDeleteCourse = (index) => {
		const updatedCourses = coursesData.filter((_, idx) => idx !== index);
		setCoursesData(updatedCourses);
		const updatedErrors = coursesErrors.filter((_, idx) => idx !== index);
		setCoursesErrors(updatedErrors);
	};

	const handleSubmit = () => {
		dispatch(setLoading(true));
		dispatch(
			addSchool({
				address: {
					address_line_1: schoolData.addressLine1,
					address_line_2: schoolData.addressLine2,
					city: schoolData.city,
					postal_code: schoolData.postalCode,
					country: schoolData.country,
				},
				...schoolData,
				courses: coursesData,
				uuid: uuid,
			})
		).then((response) => {
			if (response.type === "school/addSchool/fulfilled") {
				setSchoolData(initialSchoolData);
				setSchoolErrors({});
				setIsSchoolFormValid(false);
				setCoursesData([initialCourseData]);
				setCoursesErrors([]);
				setIsCourseFormValid(false);
				setActiveStep(0);
			}
		});
	};

	const validateCoursesData = () => {
		const errors = coursesData.map((course) => {
			return validateSingleCourse(course);
		});
		return errors;
	};

	const validateSingleCourse = (course) => {
		const courseErrors = {};
		if (!course.program) courseErrors.program = "Program is required";
		// Validate fee and currency if one is set
		if (course.fee) {
			if (!isValidFee(course.fee)) {
				courseErrors.fee = "Invalid fee amount";
			}
			if (!course.currency || !isValidCurrency(course.currency)) {
				courseErrors.currency = "Currency is required when fee is set";
			}
		} else if (course.currency) {
			// If currency is set but fee is not, show error
			courseErrors.fee = "Fee is required when currency is set";
		}
		return courseErrors;
	};

	const validateCourseField = (name, value, index) => {
		let error = "";
		if (!value) {
			if (name === "program") error = "Program is required";
		}
		const updatedErrors = [...coursesErrors];
		if (!updatedErrors[index]) updatedErrors[index] = {};

		if (name === "fee") {
			if (value && !isValidFee(value)) {
				error = "Invalid fee amount";
			} else if (value && !isValidCurrency(coursesData[index].currency)) {
				updatedErrors[index]["currency"] = "Currency is required";
			} else if (!value && isValidCurrency(coursesData[index].currency)) {
				error = "Fee is required when currency is set";
			} else if (
				!value &&
				!isValidCurrency(coursesData[index].currency)
			) {
				updatedErrors[index]["currency"] = "";
			}
		}

		if (name === "currency") {
			if (value && !isValidCurrency(value)) {
				error = "Invalid currency";
			} else if (value && !isValidFee(coursesData[index].fee)) {
				updatedErrors[index]["fee"] =
					"Fee is required when currency is set";
			} else if (!value && isValidFee(coursesData[index].fee)) {
				error = "Currency is required when fee is set";
			} else if (!value && !isValidFee(coursesData[index].fee)) {
				updatedErrors[index]["fee"] = "";
			}
		}

		updatedErrors[index][name] = error;
		setCoursesErrors(updatedErrors);
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		dispatch(clearMessage());
	};

	return (
		<React.Fragment>
			<Button
				variant="contained"
				onClick={onBack}
				startIcon={<ArrowBackIosIcon />}
				sx={{ mt: 2, mb: 2 }}
			>
				Go Back
			</Button>
			<Box
				sx={{
					width: isSmallScreen ? "100%" : "600px",
					maxWidth: "100%",
					margin: "0 auto",
				}}
			>
				{schoolState.message && (
					<Snackbar
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={true}
						autoHideDuration={5000}
						onClose={handleCloseSnackbar}
					>
						<Alert sx={{ width: "100%", mb: 2 }} severity="success">
							{schoolState.message}
						</Alert>
					</Snackbar>
				)}
				<Stepper activeStep={activeStep} alternativeLabel>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				<Box sx={{ mt: 2, mb: 2 }}>
					{activeStep === steps.length ? (
						<React.Fragment>
							<Typography sx={{ mt: 2, mb: 1 }}>
								All steps completed - your school and
								courses have been added!
							</Typography>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									pt: 2,
								}}
							>
								<Box sx={{ flex: "1 1 auto" }} />
								<Button onClick={() => setActiveStep(0)}>
									Reset
								</Button>
							</Box>
						</React.Fragment>
					) : (
						<React.Fragment>
							{schoolState.error && (
								<Alert
									sx={{ width: "100%", mt: 2, mb: 2 }}
									severity="error"
								>
									{schoolState.error}
								</Alert>
							)}
							{activeStep === 0 && isGoogleMapsScriptLoaded ? (
								<SchoolForm
									handleChange={handleSchoolChange}
									handleChangeBulk={
										handleSchoolChangeBulk
									}
									schoolData={schoolData}
									errors={schoolErrors}
								/>
							) : (
								<SchoolCourseForm
									programs={programs.filter(program => program.category === PROGRAM_CATEGORY_CHOICES.School)}
									currencies={currencies}
									coursesData={coursesData}
									handleChange={handleCourseChange}
									handleDelete={handleDeleteCourse}
									addCourse={addCourse}
									errors={coursesErrors}
									isCourseFormValid={isCourseFormValid}
								/>
							)}
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									pt: 2,
								}}
							>
								<Button
									variant="outlined"
									color="primary"
									disabled={activeStep === 0}
									onClick={handleBack}
									sx={{ mr: 1 }}
								>
									Back
								</Button>
								<Box sx={{ flex: "1 1 auto" }} />
								{activeStep === steps.length - 1 ? (
									<Button
										variant="contained"
										color="primary"
										onClick={handleSubmit}
										disabled={!isCourseFormValid}
									>
										Submit
									</Button>
								) : (
									<Button
										variant="contained"
										color="primary"
										onClick={handleNext}
										disabled={!isSchoolFormValid}
									>
										Next
									</Button>
								)}
							</Box>
						</React.Fragment>
					)}
				</Box>
			</Box>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1000,
				}}
				open={schoolState.loading || programsLoading || currenciesLoading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</React.Fragment>
	);
}
