import {
    Alert,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Switch,
    Tooltip,
} from "@mui/material";
import { ENGLISH_TEST_STATUS_CHOICES, GRADE_SYSTEM_CHOICES, GRADE_TYPE_CHOICES } from '../../const';
import React, { useCallback, useEffect, useMemo } from 'react';
import { autoPopulateGPA, validateSubjectsGrades } from "../Leads/utils";
import { generateMenuItems, isValidGPA } from "../../utils/Utils";
import { useDispatch, useSelector } from "react-redux";

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import _ from "lodash";
import { updateLead } from "../../store/lead.slice";
import { useEdvantageContext } from "../../EdvantageProvider";

/**
 * A dialog to edit the basic information of a lead.
 *
 * @param {Object} props component props
 * @param {boolean} props.open whether the dialog is open or not
 * @param {Function} props.onClose callback function to close the dialog
 * @param {Object} props.lead the lead object to edit
 *
 * @returns {React.Component} the edit lead information dialog component
 */
const EditEligibilityDialog = (props) => {
    const { open, onClose, lead } = props;

    const dispatch = useDispatch();
    const { uuid } = useEdvantageContext();
    const leadState = useSelector((state) => state.lead);

    const initialData = useMemo(() => ({
        grade_type: lead.grade_type || '',
        grade_system: lead.grade_system || '',
        grades_per_subject: lead.grades_per_subject || [],
        gpa: lead.gpa || '',
        english_test_status: lead.english_test_status || '',
        toefl_score: lead.toefl_score || '',
        ielts_score: lead.ielts_score || '',
        has_sponsorship: lead.has_sponsorship ?? false,
        sponsorship_details: lead.sponsorship_details || '',
    }), [lead]);

    // Local state to manage form input
    const [formData, setFormData] = React.useState(initialData);
    const [formErrors, setFormErrors] = React.useState({});
    const [isFormValid, setIsFormValid] = React.useState(true);

    const isFormUnchanged = _.isEqual(formData, initialData);

    // Handle form change
    const handleChange = useCallback((e) => {
        const { name, value, checked, type } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
            ...(name === "grade_type" && {
                grades_per_subject: value === "ALL" ? [] : initialData.grades_per_subject,
            }),
        }));
    }, [initialData.grades_per_subject]);

    const handleSubjectGradeChange = useCallback((index, field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            grades_per_subject: prevData.grades_per_subject.map((item, i) =>
                i === index ? { ...item, [field]: value } : item
            ),
        }));
    }, []);

    React.useEffect(() => {
        const errors = validateEligibilityForm();
        setIsFormValid(Object.keys(errors).length === 0);
    }, [formData]);

    // Handle save action
    const handleSave = useCallback(() => {
        const updatedLeadData = {
            ...formData,
            toefl_score: formData.toefl_score ? parseInt(formData.toefl_score, 10) : null,
            ielts_score: formData.ielts_score ? parseInt(formData.ielts_score, 10) : null,
        };

        dispatch(updateLead({ id: lead.id, lead: updatedLeadData, uuid })).then((response) => {
            if (response.type === "lead/updateLead/fulfilled") {
                onClose();
            }
        });
    }, [formData, lead.id, uuid, dispatch, onClose]);

    const validateEligibilityForm = useCallback(() => {
        const errors = {};
        if (!formData.grade_type) {
            errors.grade_type = "Grade type is required";
        } else if (formData.grade_type === "SUB") {
            if (formData.grades_per_subject.length === 0) {
                errors.grades_per_subject = "At least one subject grade is required";
            } else {
                Object.assign(errors, validateSubjectsGrades(formData.grades_per_subject, formData.grade_system));
            }
        }
        if (!formData.grade_system) errors.grade_system = "Grade system is required";
        if (!formData.gpa || !isValidGPA(formData.gpa, formData.grade_system)) {
            errors.gpa = "GPA is not valid for the selected grading scale";
        }
        setFormErrors(errors);
        return errors;
    }, [formData]);

    const handleAddGrade = useCallback(() => {
        setFormData((prevData) => ({
            ...prevData,
            grades_per_subject: [...prevData.grades_per_subject, { subject: "", grade: "" }],
        }));
    }, []);

    const handleDeleteGrade = useCallback((index) => {
        setFormData((prevData) => ({
            ...prevData,
            grades_per_subject: prevData.grades_per_subject.filter((_, i) => i !== index),
        }));
    }, []);

    useEffect(() => {
        if (formData.grade_type === "SUB") {
            if (formData.grades_per_subject.length > 0) {
                setFormData({ ...formData, gpa: autoPopulateGPA(formData.grades_per_subject, formData.grade_system) });
            } else {
                setFormData({ ...formData, gpa: -1 });
            }
        }
    }, [formData.grades_per_subject, formData.grade_system, formData.grade_type]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Edit Student Eligibility</DialogTitle>
            <DialogContent>
                {leadState.error && (
                    <Alert
                        sx={{ width: "100%", mt: 2, mb: 2 }}
                        severity="error"
                    >
                        {leadState.error}
                    </Alert>
                )}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            margin="dense"
                            name="grade_type"
                            label="Grade Type"
                            value={formData.grade_type || ""}
                            onChange={handleChange}
                            error={Boolean(formErrors.grade_type)}
                            helperText={formErrors.grade_type}
                            fullWidth
                        >
                            {generateMenuItems(GRADE_TYPE_CHOICES)}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            name="grade_system"
                            label="Grade System"
                            margin="dense"
                            value={formData.grade_system}
                            onChange={handleChange}
                            error={Boolean(formErrors.grade_system)}
                            helperText={formErrors.grade_system}
                            required
                            fullWidth
                        >
                            {generateMenuItems(GRADE_SYSTEM_CHOICES)}
                        </TextField>
                    </Grid>
                    {formData.grade_type === "SUB" && formData.grades_per_subject.map((item, index) => (
                        <React.Fragment key={`grades-per-subject-${index}`}>
                            <Grid item xs={6}>
                                <TextField
                                    select
                                    margin="dense"
                                    label="Subject"
                                    fullWidth
                                    value={item.subject}
                                    onChange={(e) => handleSubjectGradeChange(index, "subject", e.target.value)}
                                    error={Boolean(formErrors[`subject_${index}`])}
                                    helperText={formErrors[`subject_${index}`]}
                                >
                                    <MenuItem value="Math">Math</MenuItem>
                                    <MenuItem value="Science">Science</MenuItem>
                                    <MenuItem value="English">English</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    margin="dense"
                                    label="Grade"
                                    type="text"
                                    fullWidth
                                    value={item.grade}
                                    onChange={(e) => handleSubjectGradeChange(index, "grade", e.target.value)}
                                    error={Boolean(formErrors[`grade_${index}`])}
                                    helperText={formErrors[`grade_${index}`]}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip title="Delete">
                                    <IconButton
                                        sx={{ top: "25%" }}
                                        color="error"
                                        onClick={() => handleDeleteGrade(index)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </React.Fragment>
                    ))}
                    {formData.grade_type === "SUB" && (
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAddGrade}
                                        disabled={formData.grades_per_subject.length > 5}
                                    >
                                        {formData.grades_per_subject.length > 0 ? "Add more grades" : "Add grade"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <TextField
                            name="gpa"
                            label="GPA"
                            type="text"
                            margin="dense"
                            value={formData.gpa}
                            onChange={handleChange}
                            error={Boolean(formErrors.gpa)}
                            helperText={formErrors.gpa}
                            disabled={!formData.grade_system || formData.grade_type === "SUB"}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            select
                            margin="dense"
                            name="english_test_status"
                            label="English Test Status"
                            value={formData.english_test_status}
                            onChange={handleChange}
                            error={Boolean(formErrors.english_test_status)}
                            helperText={formErrors.english_test_status}
                            fullWidth
                            required
                        >
                            {ENGLISH_TEST_STATUS_CHOICES.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="toefl_score"
                            label="TOEFL Score"
                            type="number"
                            margin="dense"
                            value={formData.toefl_score}
                            onChange={handleChange}
                            error={Boolean(formErrors.toefl_score)}
                            helperText={formErrors.toefl_score}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="ielts_score"
                            label="IELTS Score"
                            type="number"
                            margin="dense"
                            value={formData.ielts_score}
                            onChange={handleChange}
                            error={Boolean(formErrors.ielts_score)}
                            helperText={formErrors.ielts_score}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formData.has_sponsorship}
                                    onChange={handleChange}
                                    name="has_sponsorship"
                                    color="primary"
                                />
                            }
                            labelPlacement="start"
                            label="Has Sponsorship?"
                            sx={{ ml: 0 }}
                        />
                    </Grid>
                    {formData.has_sponsorship && (
                        <Grid item xs={12}>
                            <TextField
                                name="sponsorship_details"
                                label="Sponsorship Details"
                                margin="dense"
                                value={formData.sponsorship_details}
                                onChange={handleChange}
                                error={Boolean(formErrors.sponsorship_details)}
                                helperText={formErrors.sponsorship_details}
                                fullWidth
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions sx={{ pr: 3, pb: 2 }}>
                <Button onClick={onClose} variant='contained' color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSave} variant='contained' color="primary" disabled={isFormUnchanged || !isFormValid}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditEligibilityDialog;
