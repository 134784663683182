import * as React from "react";

import { Box, Chip, IconButton, Typography } from "@mui/material";

import AddExternalEventDialog from "./AddExternalEventDialog";
import Avatar from "@mui/material/Avatar";
import { DataGrid } from "@mui/x-data-grid";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExternalEventTableToolbar from "./ExternalEventTableToolbar";

const columns = (countryById, employeeById, handleActionClick) => [
    {
        field: "actions",
        headerName: "",
        flex: 0.5,
        renderCell: (params) => (
            <div
                style={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                }}
            >
                <IconButton
                    aria-label="edit"
                    onClick={() =>
                        handleActionClick(params.id, params.row.name, "edit")
                    }
                >
                    <EditIcon />
                </IconButton>
                <IconButton
                    aria-label="delete"
                    onClick={() =>
                        handleActionClick(params.id, params.row.name, "delete")
                    }
                >
                    <DeleteIcon />
                </IconButton>
            </div>
        ),
    },
    {
        field: "id",
        headerName: "ID",
        flex: 1,
    },
    {
        field: "name",
        headerName: "Event",
        description: "Name of the event.",
        sortable: true,
        flex: 1,
    },
    {
        field: "start_date",
        headerName: "Start Date",
        description: "Start date of the event.",
        sortable: true,
        flex: 1,
        renderCell: (params) => {
            const date = new Date(params.value);
            const formattedDate = new Intl.DateTimeFormat("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
            }).format(date);

            return (
                <Typography
                    sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center", // Vertically center the content
                        justifyContent: "flex-start", // Align text to the start of the cell
                    }}
                >
                    {formattedDate}
                </Typography>
            );
        },
    },
    {
        field: "end_date",
        headerName: "End Date",
        description: "End date of the event.",
        sortable: true,
        flex: 1,
        renderCell: (params) => {
            const date = new Date(params.value);
            const formattedDate = new Intl.DateTimeFormat("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
            }).format(date);

            return (
                <Typography
                    sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center", // Vertically center the content
                        justifyContent: "flex-start", // Align text to the start of the cell
                    }}
                >
                    {formattedDate}
                </Typography>
            );
        },
    },
    {
        field: "location",
        headerName: "Location",
        description: "Location of the event.",
        sortable: true,
        flex: 1.5,
        valueGetter: (value, row) => `${row.city}, ${countryById.get(row.country)?.name}`,
    },
    {
        field: "website",
        headerName: "Link",
        description: "URL of the event.",
        sortable: true,
        flex: 1,
    },
    {
        field: "updated_by",
        headerName: "Updated By",
        minWidth: 200,
        flex: 1,
        valueGetter: (value, row) => employeeById.get(row.updated_by)?.full_name,
        renderCell: (params) => {
            return (
                <Chip
                    avatar={
                        <Avatar src="/static/images/avatars/avatar_12.jpg" />
                    }
                    label={params.value}
                    variant="outlined"
                    size="small"
                    color="primary"
                />
            );
        },
    },
    {
        field: "updated_at",
        headerName: "Last Update Time",
        flex: 1.5,
        renderCell: (params) => {
            const formattedDate = new Date(params.value).toLocaleString(
                "en-US",
                {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                }
            );
            return (
                <Typography
                    sx={{
                        display: "flex",
                        height: "100%",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    {formattedDate}
                </Typography>
            );
        },
    },
];

export default function ExternalEventTable(props) {

    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [externalEventId, setExternalEventId] = React.useState(null);
    const [externalEventName, setExternalEventName] = React.useState("");

    const contentText = (
        <>
            Are you sure you want to delete the ExternalEvent{" "}
            <Typography component="span" sx={{ fontWeight: "bold" }}>
                {externalEventName}
            </Typography>
            ?
        </>
    );

    const handleActionClick = (id, name, action) => {
        setExternalEventId(id);
        setExternalEventName(name);
        if (action === "edit") {
            setOpenEditDialog(true);
        } else if (action === "delete") {
            setOpenDeleteDialog(true);
        }
    };

    const handleClose = (action) => {
        if (action === "edit") {
            setOpenEditDialog(false);
        } else if (action === "delete") {
            setOpenDeleteDialog(false);
        }
        setExternalEventId(null);
        setExternalEventName("");
    };

    const handleConfirmDelete = () => {
        // Logic to delete the program
        console.log("Deleting ExternalEvent with ID:", externalEventId);
    };

    return (
        <Box
            sx={{
                minHeight: 400,
                maxHeight: 700,
                width: "100%",
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                overflow: "hidden",
            }}
        >
            <DataGrid
                rows={props.externalEventState.events}
                columns={columns(props.countryById, props.employeeById, handleActionClick)}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                    sorting: {
                        sortModel: [
                            {
                                field: "start_date",
                                sort: "desc",
                            },
                        ],
                    },
                }}
                slots={{
                    noRowsOverlay: () => (
                        <Typography sx={{ textAlign: 'center', margin: '1rem' }}>
                            No events available. Please add a new event!
                        </Typography>
                    ),
                    toolbar: () => (
                        <ExternalEventTableToolbar
                            setOpenAddEventDialog={props.setOpenDialog}
                        />
                    ),
                }}
                pageSizeOptions={[5, 10, 50, 100]}
                checkboxSelection={false}
                disableRowSelectionOnClick
            />
            {externalEventName && externalEventId && (
                <AddExternalEventDialog
                    open={openEditDialog}
                    handleClose={() => handleClose("edit")}
                    ExternalEvent={{ id: externalEventId, name: externalEventName }}
                    ExternalEventState={props.externalEventState}
                />
            )}

            <DeleteConfirmationDialog
                open={openDeleteDialog}
                handleClose={() => handleClose("delete")}
                onConfirm={handleConfirmDelete}
                contentText={contentText}
                state={props.externalEventState}
            />
        </Box>
    );
}
