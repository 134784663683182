import { Box, Skeleton, Typography } from "@mui/material";

import React from "react";

export default function ExternalEventTableSkeleton() {
    return (
        <Box
            sx={{
                minHeight: 400,
                maxHeight: 700,
                width: "100%",
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                overflow: "hidden",
                padding: "16px",
            }}
        >
            {/* Skeleton for Header */}
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
                <Skeleton width="30%" />
            </Typography>

            {/* Skeleton for Table Rows */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                {Array.from({ length: 5 }).map((_, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "8px 16px",
                            backgroundColor: "#F5F5F5",
                            borderRadius: "8px",
                        }}
                    >
                        <Skeleton width="15%" />
                        <Skeleton width="25%" />
                        <Skeleton width="15%" />
                        <Skeleton width="20%" />
                        <Skeleton width="10%" />
                    </Box>
                ))}
            </Box>
        </Box>
    );
}