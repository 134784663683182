import * as React from "react";

import { Box, Button, Tooltip } from "@mui/material";
import { deleteDocument, getSignedUrls } from "../../store/document.slice";
import { formatDate, getLeadStatusChip } from "../Leads/utils";
import { useDispatch, useSelector } from "react-redux";

import ApplicationSection from "./ApplicationSection";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog";
import Divider from "@mui/material/Divider";
import DownloadIcon from "@mui/icons-material/Download";
import EditAboutLeadDialog from "./EditAboutLeadDialog";
import EditIcon from '@mui/icons-material/Edit';
import Grid from "@mui/material/Grid";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import PreviewDialog from "../Document/PreviewDialog";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import UploadDocumentsDialog from "./UploadDocumentsDialog";
import UploadIcon from "@mui/icons-material/Upload";
import { fetchSchoolApplications } from "../../store/schoolApplication.slice";
import { fetchUniversityApplications } from "../../store/universityApplication.slice";
import { useEdvantageContext } from "../../EdvantageProvider";
import useEdvantageFetch from "../../hooks/useEdvantageFetch";

function Sidebar(props) {
	const { lead, title, setUrlParams } = props;

	const dispatch = useDispatch();

	const { employeeById } = useEdvantageContext();

	const [open, setOpen] = React.useState(false);
	const [openDeleteConfirmation, setOpenDeleteConfirmation] =
		React.useState(false);
	const [openUploadDocumentsDialog, setOpenUploadDocumentsDialog] = React.useState(false);
	const [selectedDocument, setSelectedDocument] = React.useState(null);
	const documentState = useSelector((state) => state.document);

	const [openEditAboutLeadDialog, setOpenEditAboutLeadDialog] =
		React.useState(false);

	const [leadData, setLeadData] = React.useState({});
	const { data: universityApplications, state: universityApplicationState, status: universityApplicationStatus } = useEdvantageFetch('universityApplication', 'applications', fetchUniversityApplications);
	const { data: schoolApplications, state: schoolApplicationState, status: schoolApplicationStatus } = useEdvantageFetch('schoolApplication', 'applications', fetchSchoolApplications);

	const leadUniversityApplications = universityApplications.filter(
		(application) => application.lead.id === lead.id
	).map((application) => ({
		...application,
		application_id: application.id,
		lead: lead,
	}));

	const leadSchoolApplications = schoolApplications.filter(
		(application) => application.lead.id === lead.id
	).map((application) => ({
		...application,
		application_id: application.id,
		lead: lead,
		school_id: application.school,
	}));

	const handleOpen = (document) => {
		setSelectedDocument(document);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setOpenDeleteConfirmation(false);
		setSelectedDocument(null);
	};

	const handleDownload = (url) => {
		const a = document.createElement("a");
		a.href = url;
		a.download = url.split("/").pop().split("?")[0]; // Extract filename from URL
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	const handleDelete = (document) => {
		setSelectedDocument(document);
		setOpenDeleteConfirmation(true);
	};

	const getDeleteDocumentConfirmationText = (document) => {
		return (
			<>
				Are you sure you want to delete{" "}
				<Typography component="span" sx={{ fontWeight: "bold" }}>
					{document.file_name || document.document_type}
				</Typography>
				?
			</>
		);
	};

	const handleDeleteConfirm = (documentId, documentType, leadId) => {
		dispatch(
			deleteDocument({
				id: documentId,
				documentType: documentType,
				leadId: leadId,
			})
		).then((response) => {
			if (response.type === "document/deleteDocument/fulfilled") {
				setOpenDeleteConfirmation(false);
				setSelectedDocument(null);
			}
		});
	};

	React.useEffect(() => {
		if (!documentState.signedUrls[lead.id]) {
			dispatch(getSignedUrls(lead.id));
		}
	}, [dispatch, documentState.signedUrls]);

	return (
		<React.Fragment>
			<Grid item xs={12} md={4}>
				<Box sx={{ position: 'relative' }}>
					<Paper elevation={0} sx={{ p: 2, bgcolor: "grey.200" }}>
						<IconButton
							aria-label="edit"
							color="primary"
							sx={{ position: 'absolute', top: 8, right: 8 }}
							onClick={() => setOpenEditAboutLeadDialog(true)}
						>
							<EditIcon />
						</IconButton>
						<Typography variant="h6" gutterBottom>
							{title}
						</Typography>
						<Typography
							variant="subtitle1"
							color="text.secondary"
							sx={{ mb: 0 }}
						>
							Status: {getLeadStatusChip(lead.status)}
						</Typography>
						<Typography variant="subtitle1" paragraph sx={{ mb: 0 }}>
							Case Manager: {employeeById.get(lead.case_manager).full_name}
						</Typography>
						<Typography variant="subtitle1" paragraph sx={{ mb: 0 }}>
							Consultation Type: {lead.consultation_type}
						</Typography>
						<Typography variant="subtitle1" paragraph sx={{ mb: 0 }}>
							Update At: {formatDate(lead.updated_at)}
						</Typography>
					</Paper>
				</Box>
				<ApplicationSection
					applications={leadUniversityApplications}
					applicationState={universityApplicationState}
					applicationStatus={universityApplicationStatus}
					lead={lead}
					setUrlParams={setUrlParams}
					applicationType={"University"}
				/>
				<ApplicationSection
					applications={leadSchoolApplications}
					applicationState={schoolApplicationState}
					applicationStatus={schoolApplicationStatus}
					leadId={lead.id}
					setUrlParams={setUrlParams}
					applicationType={"School"}
				/>
				<Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
					Documents
					<Tooltip title="Upload Documents">
						<IconButton
							aria-hidden="false"
							color="primary"
							aria-label="upload"
							sx={{ ml: 1 }} // Optional, can control the spacing between the text and icon
							onClick={() => setOpenUploadDocumentsDialog(true)}
						>
							<UploadIcon />
						</IconButton>
					</Tooltip>
				</Typography>
				{documentState.signedUrls[lead.id] &&
					Object.keys(documentState.signedUrls[lead.id]).length !== 0 ? (
					Object.entries(documentState.signedUrls[lead.id]).map(
						([documentType, documents]) => (
							<React.Fragment key={documentType}>
								<Typography
									variant="subtitle1"
									gutterBottom
									sx={{ fontWeight: 500 }}
								>
									{documentType}{" "}
									{/* Render Document Type Label */}
								</Typography>
								<Divider
									sx={{ borderColor: "rgba(0, 0, 0, 0.87)" }}
								/>
								{documents.map((document) => (
									<Stack
										direction="row"
										alignItems="center"
										key={document.document_id} // Use a unique identifier like document_id
										sx={{ mb: 1 }}
									>
										<Button
											color="inherit"
											onClick={() => handleOpen(document)}
											sx={{
												textTransform: "none",
												display: "block",
												width: "auto",
												p: 0, // Remove default padding
												fontSize: "1rem",
												fontWeight: 400,
												lineHeight: 1.5,
												color: "#1976d2",
												"&:hover": {
													backgroundColor:
														"transparent",
													textDecoration: "underline",
												},
											}}
										>
											{document.file_name ||
												document.document_type}
										</Button>
										<Tooltip title="Download">
											<IconButton
												color="primary"
												aria-label="download"
												onClick={(e) => {
													e.stopPropagation(); // Prevents the click event from propagating to the Link
													handleDownload(
														document.signed_url
													);
												}}
											>
												<DownloadIcon />
											</IconButton>
										</Tooltip>
										<Tooltip title="Delete">
											<IconButton
												color="error"
												aria-label="delete"
												sx={{ pl: 0 }}
												onClick={(e) => {
													e.stopPropagation();
													handleDelete(document);
												}}
											>
												<HighlightOffIcon />
											</IconButton>
										</Tooltip>
									</Stack>
								))}
							</React.Fragment>
						)
					)
				) : (
					<Typography variant="body1" gutterBottom sx={{ mt: 1 }}>
						No documents found
					</Typography>
				)}
			</Grid>
			{selectedDocument && (
				<React.Fragment>
					<PreviewDialog
						open={open}
						onClose={handleClose}
						document={selectedDocument}
					/>
					<DeleteConfirmationDialog
						open={openDeleteConfirmation}
						handleClose={handleClose}
						onConfirm={() =>
							handleDeleteConfirm(
								selectedDocument.document_id,
								selectedDocument.document_type,
								lead.id
							)
						}
						contentText={getDeleteDocumentConfirmationText(
							selectedDocument
						)}
						state={documentState}
					/>
				</React.Fragment>
			)}
			<EditAboutLeadDialog
				open={openEditAboutLeadDialog}
				onClose={() => setOpenEditAboutLeadDialog(false)}
				lead={lead}
			/>
			<UploadDocumentsDialog
				open={openUploadDocumentsDialog}
				onClose={() => setOpenUploadDocumentsDialog(false)}
				leadId={lead.id}
				studentId={lead.student.id}
				setLeadData={setLeadData}
			/>

		</React.Fragment>
	);
}

Sidebar.propTypes = {
	archives: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			url: PropTypes.string.isRequired,
		})
	).isRequired,
	description: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	setUrlParams: PropTypes.func.isRequired,
};

export default Sidebar;
