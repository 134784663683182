import * as React from "react";

import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
} from "@mui/x-data-grid";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

export default function ExternalEventTableToolbar(props) {

    const handleAddClick = () => {
        props.setOpenAddEventDialog(true);
    };

    return (
        <GridToolbarContainer>
            <Button
                variant="text"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddClick}
            >
                Add Event
            </Button>
            <GridToolbarColumnsButton />
        </GridToolbarContainer>
    );
}
