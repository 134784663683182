import * as React from "react";

import { setLoading, updateSchool } from "../../store/school.slice";
import { useDispatch, useSelector } from "react-redux";
import { validateSchoolField, validateSchoolForm } from "./common";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import SchoolForm from "./SchoolForm";

function EditUniviersityDialog(props) {
	const dispatch = useDispatch();

	const schoolState = useSelector((state) => state.school);

	const initialFormState = {
		name: props.school.name,
		addressLine1: props.school.address.address_line_1,
		addressLine2: props.school.address.address_line_2,
		city: props.school.address.city,
		postalCode: props.school.address.postal_code,
		country: props.school.address.country,
		website: props.school.website ?? "",
		brochure_url: props.school.brochure_url ?? "",
		qs_world_ranking: props.school.qs_world_ranking ?? "",
		google_maps_url: props.school.google_maps_url ?? "",
		google_place_id: props.school.google_place_id ?? "",
	};

	const [form, setForm] = React.useState(initialFormState);
	const [formErrors, setFormErrors] = React.useState("");
	const [isFormValid, setIsFormValid] = React.useState(true);
	const [isFormChanged, setIsFormChanged] = React.useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();

		dispatch(setLoading(true));
		dispatch(
			updateSchool({
				id: props.school.id,
				school: {
					name: form.name,
					address: {
						address_line_1: form.addressLine1,
						address_line_2: form.addressLine2,
						city: form.city,
						postal_code: form.postalCode,
						country: form.country,
					},
					website: form.website,
					brochure_url: form.brochure_url,
					qs_world_ranking: form.qs_world_ranking,
					google_maps_url: form.google_maps_url,
					google_place_id: form.google_place_id,
				},
			})
		)
			.then((response) => {
				if (response.type === "school/updateSchool/fulfilled") {
					handleCloseDialog();
				}
			})
			.finally(() => {
				dispatch(setLoading(false));
			});
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		const newForm = { ...form, [name]: value };
		setForm(newForm);
		validateSchoolField(name, value, setFormErrors);
		setIsFormValid(
			Object.keys(validateSchoolForm(newForm)).length === 0
		);
		setIsFormChanged(
			JSON.stringify(newForm) !== JSON.stringify(initialFormState)
		);
	};

	const handleChangeBulk = (updates) => {
		// Update schoolData with all the new values in the updates object

		const newForm = { ...form, ...updates };
		setForm(newForm);
		const accumulatedErrors = Object.keys(updates).reduce(
			(errors, field) => {
				const fieldError = validateSchoolField(
					field,
					newForm[field]
				);
				if (fieldError) {
					errors[field] = fieldError;
				}
				return errors;
			},
			{}
		);
		setFormErrors(accumulatedErrors);
		setIsFormValid(
			Object.keys(validateSchoolForm(newForm)).length === 0
		);
		setIsFormChanged(
			JSON.stringify(newForm) !== JSON.stringify(initialFormState)
		);
	};

	const handleCloseDialog = () => {
		props.handleClose(false);
		setForm(initialFormState);
		setFormErrors("");
		setIsFormValid(true);
		setIsFormChanged(false);
	};

	return (
		<Dialog
			open={props.open}
			onClose={handleCloseDialog}
			aria-labelledby="form-dialog-title"
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle id="form-dialog-title">
				Edit School: <em>{props.school.name}</em>
			</DialogTitle>
			<DialogContent>
				<Box sx={{ mt: 3 }}>
					{schoolState.error && (
						<Alert
							sx={{ width: "100%", mt: 2, mb: 2 }}
							severity="error"
						>
							{schoolState.error}
						</Alert>
					)}
					<SchoolForm
						handleChange={handleChange}
						handleChangeBulk={handleChangeBulk}
						schoolData={form}
						errors={formErrors}
					/>
					<DialogActions sx={{ justifyContent: "flex-start", px: 0 }}>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							sx={{ mt: 3, mb: 2 }}
							disabled={!isFormValid || !isFormChanged}
							onClick={handleSubmit}
						>
							Save
						</Button>
						<Button
							type="button"
							variant="contained"
							color="error"
							sx={{ mt: 3, mb: 2 }}
							onClick={handleCloseDialog}
						>
							Cancel
						</Button>
					</DialogActions>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

EditUniviersityDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	school: PropTypes.object.isRequired,
};

export default EditUniviersityDialog;
