import {
    Grid,
    Skeleton,
    Typography
} from '@mui/material';

import React from 'react';

const AddDraftLeadSkeletonForm = () => (
    <>
        {/* <Skeleton variant="text" width="100%" height={30} sx={{ mb: 2 }} /> */}
        <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
            About
        </Typography>
        <Grid container spacing={2}>
            {[...Array(6)].map((_, index) => (
                <Grid item xs={12} sm={6} key={index}>
                    <Skeleton variant="rectangular" height={56} />
                </Grid>
            ))}
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
            Student
        </Typography>
        <Grid container spacing={2}>
            {[...Array(4)].map((_, index) => (
                <Grid item xs={12} sm={6} key={index}>
                    <Skeleton variant="rectangular" height={56} />
                </Grid>
            ))}
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
            Academia
        </Typography>
        <Grid container spacing={2}>
            {[...Array(3)].map((_, index) => (
                <Grid item xs={12} sm={6} key={index}>
                    <Skeleton variant="rectangular" height={56} />
                </Grid>
            ))}
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
            Preferences
        </Typography>
        <Grid container spacing={2}>
            {[...Array(4)].map((_, index) => (
                <Grid item xs={12} sm={6} key={index}>
                    <Skeleton variant="rectangular" height={56} />
                </Grid>
            ))}
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
            Additional Services
        </Typography>
        <Grid container spacing={2}>
            {[...Array(2)].map((_, index) => (
                <Grid item xs={12} key={index}>
                    <Skeleton variant="rectangular" height={56} />
                </Grid>
            ))}
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
            Notes
        </Typography>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Skeleton variant="rectangular" height={120} />
            </Grid>
        </Grid>
    </>
);

export default AddDraftLeadSkeletonForm;
