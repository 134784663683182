import { useEffect, useRef, useState } from 'react';

const useWebSocket = (url, onMessageCallback, employeeId, maxReconnectAttempts = 5, reconnectDelay = 1000) => {
    const [socket, setSocket] = useState(null);
    const [reconnectAttempts, setReconnectAttempts] = useState(0);
    const connectionInProgress = useRef(false);

    const connectWebSocket = () => {

        if (connectionInProgress.current) {
            return; // Prevent duplicate connections
        }

        // Set the lock to true to prevent multiple connections
        connectionInProgress.current = true;

        // Create WebSocket connection
        const socketInstance = new WebSocket(url);

        // Connection opened
        socketInstance.onopen = () => {
            console.log('WebSocket connection established => ', url);
            setReconnectAttempts(0); // Reset reconnect attempts on successful connection
            connectionInProgress.current = false;

            // Send the UUID to the server
            if (employeeId) {
                socketInstance.send(JSON.stringify({ type: 'register', employee_id: employeeId }));
            }
        };

        // Listen for messages
        socketInstance.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (onMessageCallback) {
                onMessageCallback(data); // Trigger the callback if provided
            }
        };

        // Handle errors
        socketInstance.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        // Connection closed
        socketInstance.onclose = (event) => {
            console.log('WebSocket connection closed', event);
            connectionInProgress.current = false; // Release the lock when connection closes
            // Attempt to reconnect if the connection was closed unexpectedly
            if (event.code !== 1000) {
                attemptReconnect();
            }
        };

        // Set the socket state
        setSocket(socketInstance);
    };

    const attemptReconnect = () => {
        if (reconnectAttempts < maxReconnectAttempts) {
            setReconnectAttempts((prevAttempts) => prevAttempts + 1);

            // Exponential backoff: delay increases with each attempt
            const delay = Math.pow(2, reconnectAttempts) * reconnectDelay;
            console.log(`Reconnecting in ${delay / 1000} seconds...`);

            setTimeout(() => {
                connectWebSocket(); // Try to reconnect
            }, delay);
        } else {
            console.log('Max reconnect attempts reached. Stopping reconnection attempts.');
        }
    };

    const sendMessage = (message) => {
        if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify(message));
            return { status: 'success', message: 'Message sent' };
        } else {
            return { status: 'error', message: 'WebSocket not connected' };
        }
    };

    // Connect initially
    useEffect(() => {
        if (!socket) {
            connectWebSocket();
        }

        // Cleanup on component unmount
        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [url]); // Reconnect if the URL changes

    return { socket, sendMessage };
};

export default useWebSocket;
