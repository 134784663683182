import { darken, lighten, styled } from '@mui/system';

import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@mui/material/TextField';

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
    ...theme.applyStyles('dark', {
        backgroundColor: darken(theme.palette.primary.main, 0.8),
    }),
}));

const GroupItems = styled('ul')({
    padding: 0,
});

const options = [
    { title: 'MOHE-KW', group: 'Kuwait' },
    { title: 'Ministry of Defence', group: 'Kuwait' },
    { title: 'PAAET', group: 'Kuwait' },
    { title: 'KIA', group: 'Kuwait' },
    { title: 'MOHE-KSA', group: 'Saudi Arabia' },
    { title: 'Custodian of The Two Holy Mosques', group: 'Saudi Arabia' },
];

function SponsorAutoComplete({
    name,
    label,
    value,
    onChange,
    index,
    error = false,
    helperText = '',
    options: propOptions,
    disabled,
}) {
    const handleChange = (event, newValue) => {
        const syntheticEvent = {
            target: {
                name,
                value: newValue,
                type: "autocomplete",
                checked: null,
            },
        };
        onChange(syntheticEvent, index);
    };

    return (
        <Autocomplete
            multiple
            options={propOptions || options}
            groupBy={(option) => option.group}
            getOptionLabel={(option) => option.title}
            value={value}
            limitTags={2}
            onChange={handleChange}
            isOptionEqualToValue={(option, value) => option.title === value.title && option.group === value.group}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    error={error}
                    helperText={helperText}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "off", // disable autocomplete and autofill
                    }}
                />
            )}
            renderGroup={(params) => (
                <li key={params.key}>
                    <GroupHeader>{params.group}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                </li>
            )}
            disabled={disabled}
        />
    );
}

SponsorAutoComplete.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    index: PropTypes.number,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            group: PropTypes.string.isRequired,
        })
    ),
    disabled: PropTypes.bool,
};

export default SponsorAutoComplete;
