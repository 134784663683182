import { Box, Grid, TextField } from "@mui/material";
import React, { useCallback } from "react";

import { Autocomplete } from "@react-google-maps/api";
import CountrySelect from "../common/CountrySelect";

export default function SchoolForm({
	handleChange,
	handleChangeBulk,
	schoolData,
	errors,
}) {
	const [searchResult, setSearchResult] = React.useState(null);

	const getFirstLineOfAddress = (addressComponents) => {
		// Extract address components
		const streetNumberComponent = addressComponents.find((component) =>
			component.types.includes("street_number")
		);
		const routeComponent = addressComponents.find((component) =>
			component.types.includes("route")
		);

		// Combine street number and route to form the first line of the address
		const streetNumber = streetNumberComponent
			? streetNumberComponent.long_name
			: "";
		const route = routeComponent ? routeComponent.long_name : "";

		return `${streetNumber} ${route}`.trim();
	};

	const onPlaceChanged = useCallback(() => {
		if (searchResult) {
			const place = searchResult.getPlace();
			const addressComponents = place.address_components || [];
			const city = addressComponents.find((comp) => comp.types.includes("locality"))?.long_name || "";
			const postalCode = addressComponents.find((comp) => comp.types.includes("postal_code"))?.long_name || "";
			const country = addressComponents.find((comp) => comp.types.includes("country"))?.long_name || "";

			handleChangeBulk({
				name: place.name || "",
				google_place_id: place.place_id,
				addressLine1: getFirstLineOfAddress(addressComponents) || place.formatted_address || "",
				city,
				postalCode,
				country,
				website: place.website || "",
				google_maps_url: place.url || "",
				photos: [],
			});
		}
	}, [searchResult, getFirstLineOfAddress, handleChangeBulk]);

	return (
		<Box component="form" sx={{ width: "100%" }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Autocomplete
						onPlaceChanged={onPlaceChanged}
						onLoad={(autocomplete) => setSearchResult(autocomplete)}
					>
						<TextField
							fullWidth
							label="School Name"
							name="name"
							value={schoolData.name}
							onChange={handleChange}
							required
							error={Boolean(errors.name)}
							helperText={errors.name}
						/>
					</Autocomplete>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						label="Address Line 1"
						name="addressLine1"
						value={schoolData.addressLine1}
						onChange={handleChange}
						required
						error={Boolean(errors.addressLine1)}
						helperText={errors.addressLine1}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						label="Address Line 2"
						name="addressLine2"
						value={schoolData.addressLine2}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						label="City"
						name="city"
						value={schoolData.city}
						onChange={handleChange}
						required
						error={Boolean(errors.city)}
						helperText={errors.city}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						label="Postal Code "
						name="postalCode"
						value={schoolData.postalCode}
						onChange={handleChange}
						required
						error={Boolean(errors.postalCode)}
						helperText={errors.postalCode}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<CountrySelect
						name="country"
						label="Country"
						value={schoolData.country}
						onChange={handleChange}
						error={Boolean(errors.country)}
						helperText={errors.country}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						label="Website"
						name="website"
						value={schoolData.website}
						onChange={handleChange}
						required
						error={Boolean(errors.website)}
						helperText={errors.website}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						label="Google Maps URL"
						name="google_maps_url"
						value={schoolData.google_maps_url}
						onChange={handleChange}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}
