import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Chip,
	Divider,
	FormControlLabel,
	Grid,
	IconButton,
	Skeleton,
	Tooltip,
	Typography,
} from "@mui/material";
// CoursesSection.js
import React, { useCallback, useMemo } from "react";
import { deleteUniversityCourse, updateUniversityCourse } from "../../store/university.slice";
import { useDispatch, useSelector } from "react-redux";

import AddCourseDialog from "./AddCourseDialog";
import AddIcon from "@mui/icons-material/Add";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import EditCourseDialog from "./EditCourseDialog";
import EditIcon from "@mui/icons-material/Edit";
import IOSSwitch from "../common/IOSSwitch";
import { Link } from "@mui/material";
import { fetchExchangeRate } from "../../utils/currencyUtils";
import { useEdvantageContext } from "../../EdvantageProvider";

const CoursesSection = React.memo(({ universityId, courses }) => {
	const dispatch = useDispatch();

	const universityState = useSelector((state) => state.university);
	const { artifactLoading, uuid, currencyById, employeeById, intakeById, majorById, programById } = useEdvantageContext();

	const [selectedCourse, setSelectedCourse] = React.useState(null);
	const [openEditCourseDialog, setOpenEditCourseDialog] =
		React.useState(false);
	const [openAddCourseDialog, setOpenAddCourseDialog] = React.useState(false);
	const [openDeleteCourseDialog, setOpenDeleteCourseDialog] =
		React.useState(false);

	const getCourseDeleteConfirmationText = useCallback(
		(course) => (
			<>
				Are you sure you want to delete the course{" "}
				<Typography component="span" sx={{ fontWeight: "bold" }}>
					{majorById.get(course.major).name} - {programById.get(course.program).name}
				</Typography>
				? <em>Deleting this course will remove all the associated applications permanently.</em>
			</>
		),
		[majorById, programById]
	);

	const handleEditCourse = useCallback((courseId) => {
		setSelectedCourse(courses.find((course) => course.id === courseId));
		setOpenEditCourseDialog(true);
	}, [courses]);

	const handleAddCourse = useCallback(() => {
		setOpenAddCourseDialog(true);
	}, []);

	const handleDeleteCourse = useCallback((courseId) => {
		setSelectedCourse(courses.find((course) => course.id === courseId));
		setOpenDeleteCourseDialog(true);
	}, [courses]);

	const deleteCourse = useCallback((courseId) => {
		dispatch(deleteUniversityCourse({ id: courseId, uuid })).then((response) => {
			if (response.type === "university/deleteUniversityCourse/fulfilled") {
				setOpenDeleteCourseDialog(false);
			}
		});
	}, [dispatch, uuid]);

	const renderSkeleton = useMemo(
		() =>
			Array.from({ length: courses.length }).map((_, index) => (
				<Grid item xs={12} sm={6} md={4} key={index}>
					<Card sx={{ maxWidth: "100%", display: "flex", flexDirection: "column", maxHeight: 350 }}>
						<CardContent sx={{ flexGrow: 1, maxHeight: 250, overflowY: "auto" }}>
							<Skeleton variant="text" width="80%" />
							<Skeleton variant="text" width="60%" />
							<Skeleton variant="rectangular" height={100} />
						</CardContent>
						<CardActions>
							<Skeleton variant="rectangular" width={80} height={36} />
							<Skeleton variant="rectangular" width={40} height={36} />
						</CardActions>
					</Card>
				</Grid>
			)),
		[]
	);

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleToggleCourse = useCallback((courseId, newIsActive) => {
		dispatch(updateUniversityCourse({ id: courseId, course: { is_active: newIsActive }, uuid }))
	}, [dispatch, uuid]);

	const [conversionRates, setConversionRates] = React.useState({});

	React.useEffect(() => {
		const fetchRates = async () => {
			const ratePromises = courses.map(async (course) => {
				if (
					course.fee &&
					currencyById.get(course.currency) &&
					currencyById.get(course.currency).code !== "KWD"
				) {
					const rate = await fetchExchangeRate(
						currencyById.get(course.currency).code,
						"KWD"
					);
					return { courseId: course.id, rate };
				}
				return null;
			});

			const results = await Promise.all(ratePromises);
			const rates = results
				.filter(Boolean)
				.reduce((acc, { courseId, rate }) => ({ ...acc, [courseId]: rate }), {});

			setConversionRates(rates);
		};

		if (courses.length > 0 && currencyById.size > 0) {
			fetchRates();
		}
	}, [courses, currencyById]);

	return (
		<Box sx={{ mb: 2 }}>
			<Typography
				variant="h6"
				component="div"
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				Available Courses
				<Button
					aria-hidden="false"
					variant="outlined"
					color="primary"
					startIcon={<AddIcon />}
					onClick={handleAddCourse}
				>
					Add Course
				</Button>
			</Typography>
			<Divider sx={{ mt: 2, mb: 2 }} />
			<Grid container spacing={2}>
				{artifactLoading ? (
					renderSkeleton
				) : (
					courses.map((course) => (
						<Grid item xs={12} sm={6} md={4} key={course.id} sx={{ display: "flex", flexDirection: "column" }}>
							<Card
								sx={{
									maxWidth: "100%",
									display: "flex",
									flexDirection: "column",
									height: 350,
									opacity: course.is_active ? 1 : 0.6, // Reduce opacity if inactive
									filter: course.is_active ? "none" : "grayscale(100%)",
								}}>
								<CardHeader
									sx={{ pb: 0 }}
									title={
										<Typography variant="h6" component="div">
											{course.course_url ? (
												<Link
													href={course.course_url}
													target="_blank"
													rel="noopener noreferrer"
													color="primary"
													underline="hover"
												>
													{majorById.get(course.major).name} - {programById.get(course.program).name}
												</Link>
											) : (
												`${majorById.get(course.major).name} - ${programById.get(course.program).name}`
											)}
										</Typography>
									}
								/>
								<CardContent sx={{ flexGrow: 1, maxHeight: 250, overflowY: "auto" }}>
									<Typography
										variant="body2"
										color="text.secondary"
									>
										<strong>Intakes:</strong>{" "}
										{course.intakes.map(intakeId => intakeById.get(intakeId).text).join(", ")}
									</Typography>
									<Typography
										variant="body2"
										color="text.secondary"
									>
										<strong>Merit Status:</strong>{" "}
										{course.status}
									</Typography>
									<Typography
										variant="body2"
										color="text.secondary"
									>
										<strong>Application Method:</strong>{" "}
										{course.application_method}
									</Typography>
									<Typography
										variant="body2"
										color="text.secondary"
									>
										<strong>Partnership Status:</strong>{" "}
										{course.partnership_status}
									</Typography>
									<Typography
										variant="body2"
										color="text.secondary"
									>
										<strong>Fee:</strong>{" "}
										{course.fee ? (
											<>
												<span>
													{currencyById.get(course.currency).symbol} {course.fee}
												</span>{" "}
												{conversionRates[course.id] && (
													<span style={{ color: "#007BFF", fontWeight: 500 }}>
														(~ {(conversionRates[course.id] * course.fee).toFixed(2)} د.ك)
													</span>
												)}
											</>
										) : (
											"N/A"
										)}
									</Typography>
									<Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}>
										<Typography variant="body2" color="text.secondary" component="div">
											<strong>Sponsors:</strong>{" "}
											{course.is_sponsored && course.sponsors.length > 0 ?
												course.sponsors.map((sponsor, index) => (
													<Chip
														key={index}
														label={sponsor.title}
														size="small"
														variant="filled"
														sx={{
															backgroundColor: "#4CAF50", // Vibrant green
															color: "#FFFFFF", // White text for contrast
															fontWeight: "bold",
															mr: 1,
														}}
													/>
												)) : (
													"N/A"
												)}
										</Typography>
									</Box>
								</CardContent>
								<CardActions sx={{ px: 2, boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)" }}>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "space-between",
											alignItems: "flex-start",
											width: "100%",
										}}
									>
										<Typography
											variant="body2"
											color="text.secondary"
											sx={{ fontStyle: "italic" }}
										>
											<strong>Added By:</strong>{" "}
											{employeeById.get(course.added_by).full_name}
										</Typography>
										<Typography
											variant="body2"
											color="text.secondary"
											sx={{ fontStyle: "italic" }}
										>
											<strong>Updated By:</strong>{" "}
											{employeeById.get(course.updated_by).full_name}
										</Typography>
										<Typography
											variant="body2"
											color="text.secondary"
											sx={{ fontStyle: "italic" }}
										>
											<strong>Last Updated:</strong>{" "}
											{new Date(
												course.updated_at
											).toLocaleString()}
										</Typography>
										<Box
											sx={{
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
												width: "100%",
												mt: 2,
											}}
										>
											<Button
												aria-hidden="true"
												variant="contained"
												startIcon={<EditIcon />}
												onClick={() =>
													handleEditCourse(course.id)
												}
											>
												Edit
											</Button>
											<Tooltip title="Delete">
												<IconButton
													color="error"
													area-label="delete"
													onClick={() =>
														handleDeleteCourse(
															course.id
														)
													}
												>
													<DeleteIcon />
												</IconButton>
											</Tooltip>
											<FormControlLabel
												control={<IOSSwitch sx={{ m: 1 }} checked={course.is_active} onChange={() => handleToggleCourse(course.id, !course.is_active)} />}
												label=""
											/>
										</Box>
									</Box>
								</CardActions>
							</Card>
						</Grid>
					)))
				}
			</Grid>
			<AddCourseDialog
				open={openAddCourseDialog}
				handleClose={setOpenAddCourseDialog}
				universityId={universityId}
			/>
			{selectedCourse && (
				<>
					<EditCourseDialog
						open={openEditCourseDialog}
						handleClose={setOpenEditCourseDialog}
						course={selectedCourse}
					/>
					<DeleteConfirmationDialog
						open={openDeleteCourseDialog}
						handleClose={() => setOpenDeleteCourseDialog(false)}
						onConfirm={() => deleteCourse(selectedCourse.id)}
						contentText={getCourseDeleteConfirmationText(
							selectedCourse
						)}
						state={universityState}
					/>
				</>
			)}
		</Box>
	);
});

CoursesSection.displayName = "CoursesSection";

export default CoursesSection;
