import * as React from "react";

import AcUnitIcon from '@mui/icons-material/AcUnit';
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BarChartIcon from "@mui/icons-material/BarChart";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Collapse from "@mui/material/Collapse";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import DraftsIcon from '@mui/icons-material/Drafts';
import EventIcon from '@mui/icons-material/Event';
import ExpandMore from "@mui/icons-material/ExpandMore";
import GroupIcon from "@mui/icons-material/Group";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LayersIcon from "@mui/icons-material/Layers";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import { Profile } from "../../utils/Profile";
import SchoolIcon from "@mui/icons-material/School";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import WorkIcon from "@mui/icons-material/Work";
import { useSelector } from "react-redux";

// Helper to create menu items
const createMenuItem = (text, icon, selected, onClick, isCollapsible = false, isOpen = false) => (
	<ListItemButton key={text} selected={selected} onClick={onClick}>
		<ListItemIcon>{React.cloneElement(icon, { color: selected ? "primary" : "inherit" })}</ListItemIcon>
		<ListItemText
			primary={text}
			sx={{
				fontWeight: selected ? 500 : "normal",
				color: selected ? "primary.main" : "inherit",
			}}
		/>
		{/* Add Expand/Collapse icon for collapsible items */}
		{isCollapsible &&
			(isOpen ? <ExpandMore color={selected ? "primary" : "inherit"} /> : <KeyboardArrowRightIcon />)}
	</ListItemButton>
);

export const MainListItems = ({ selectedListItem, setUrlParams }) => {
	const [open, setOpen] = React.useState({});
	const authUserProfile = new Profile(useSelector((state) => state.auth));

	const toggleOpen = (key) => setOpen((prev) => ({ ...prev, [key]: !prev[key] }));
	const isSelected = (item) => selectedListItem === item;

	React.useEffect(() => {
		setOpen({
			Application: ["School Applications", "Uni Applications"].includes(selectedListItem),
			Leads: ["Leads", "Add Lead"].includes(selectedListItem),
			Institutions: ["Universities", "Schools"].includes(selectedListItem),
		});
	}, [selectedListItem]);

	return (
		<>
			{createMenuItem(
				"Appointments",
				<CalendarMonthIcon />,
				isSelected("Appointments"),
				() => setUrlParams({ tab: "Appointments", view: "default" })
			)}
			{createMenuItem(
				"Draft Leads",
				<DraftsIcon />,
				isSelected("DraftLeads"),
				() => setUrlParams({ tab: "DraftLeads", view: "default" })
			)}
			{createMenuItem(
				"Leads",
				<CrisisAlertIcon />,
				isSelected("Leads"),
				() => {
					setUrlParams({ tab: "Leads", view: "table" });
					toggleOpen("Leads");
				},
				true,
				open.Leads
			)}
			<Collapse in={open.Leads} timeout="auto" unmountOnExit>
				{createMenuItem(
					"Add Lead",
					<SubdirectoryArrowRightIcon sx={{ pl: 2, mb: 0.5 }} />,
					isSelected("Add Lead"),
					() => setUrlParams({ tab: "Add Lead", view: "default" })
				)}
			</Collapse>
			{createMenuItem(
				"Applications",
				<WorkIcon />,
				isSelected("Application"),
				() => toggleOpen("Application"),
				true,
				open.Application
			)}
			<Collapse in={open.Application} timeout="auto" unmountOnExit>
				{createMenuItem(
					"University Applications",
					<SubdirectoryArrowRightIcon sx={{ pl: 2, mb: 0.5 }} />,
					isSelected("Uni Applications"),
					() => setUrlParams({ tab: "Uni Applications", view: "table" })
				)}
				{createMenuItem(
					"School Applications",
					<SubdirectoryArrowRightIcon sx={{ pl: 2, mb: 0.5 }} />,
					isSelected("School Applications"),
					() => setUrlParams({ tab: "School Applications", view: "table" })
				)}
			</Collapse>
			{createMenuItem(
				"Institutions",
				<AccountBalanceIcon />,
				isSelected("Institutions"),
				() => toggleOpen("Institutions"),
				true,
				open.Institutions
			)}
			<Collapse in={open.Institutions} timeout="auto" unmountOnExit>
				{createMenuItem(
					"Universities",
					<SubdirectoryArrowRightIcon sx={{ pl: 2, mb: 0.5 }} />,
					isSelected("Universities"),
					() => setUrlParams({ tab: "Universities", view: "table" })
				)}
				{createMenuItem(
					"Schools",
					<SubdirectoryArrowRightIcon sx={{ pl: 2, mb: 0.5 }} />,
					isSelected("Schools"),
					() => setUrlParams({ tab: "Schools", view: "table" })
				)}
			</Collapse>
			{[
				{ text: "Programs", icon: <BookmarkIcon /> },
				{ text: "Majors", icon: <SchoolIcon /> },
				{ text: "Intakes", icon: <AcUnitIcon /> },
				{ text: "Services", icon: <MiscellaneousServicesIcon /> },
				{ text: "Events", icon: <EventIcon /> },
				{ text: "Reports", icon: <BarChartIcon /> },
				{ text: "Integrations", icon: <LayersIcon /> },
			].map(({ text, icon }) =>
				createMenuItem(
					text,
					icon,
					isSelected(text),
					() => setUrlParams({ tab: text, view: "default" })
				)
			)}
			{authUserProfile.hasGroups("Administrator") &&
				createMenuItem(
					"Users",
					<GroupIcon />,
					isSelected("Users"),
					() => setUrlParams({ tab: "Users", view: "default" })
				)}
		</>
	);
};

export const secondaryListItems = (
	<>
		<ListSubheader component="div" inset>
			Saved reports
		</ListSubheader>
		{["Current month", "Last quarter", "Year-end sale"].map((text) => (
			<ListItemButton key={text}>
				<ListItemIcon>
					<AssignmentIcon />
				</ListItemIcon>
				<ListItemText primary={text} />
			</ListItemButton>
		))}
	</>
);
