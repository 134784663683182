import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { CONSULTATION_CHOICES, ENGLISH_TEST_STATUS_CHOICES, GENDER_CHOICES, GRADE_SYSTEM_CHOICES, GRADE_TYPE_CHOICES, SOURCE_CHOICES } from '../../const';

import CountrySelect from '../common/CountrySelect';
import EdvantageAutocomplete from '../common/EdvantageAutocomplete';
import GenericMultiSelect from '../common/GenericMultiSelect';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { fetchExternalEvents } from '../../store/externalEvent.slice';
import { generateMenuItems } from '../../utils/Utils';
import { isEqual } from 'lodash';
import { useEdvantageContext } from '../../EdvantageProvider';
import useEdvantageFetch from '../../hooks/useEdvantageFetch';

const AddDraftLeadForm = ({
    cities,
    cityState,
    draftLeadState,
    formData,
    errors,
    handleInputChange,
    handleSwitchChange,
    handleAddSubject,
    handleRemoveSubject,
    handleSubjectGradeChange,
    handleSubmit,
    showScores,
    showTestDate,
    isEdit,
}) => {

    const today = new Date().toISOString().split("T")[0];
    const { countries, intakes, majors, programs, services } = useEdvantageContext();
    const { data: externalEvents, state: externalEventState } = useEdvantageFetch('externalEvent', 'events', fetchExternalEvents);

    const originalFormData = React.useRef(formData);
    const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);

    React.useEffect(() => {
        setHasUnsavedChanges(!isEqual(formData, originalFormData.current));
    }, [formData]);

    return (
        <form onSubmit={handleSubmit}>
            {/* Form Errors */}
            {draftLeadState.error && (
                <Typography variant="subtitle2" color="error" sx={{ mb: 2 }}>
                    {draftLeadState.error}
                </Typography>
            )}
            {/* About Section */}
            <Typography variant="h6" gutterBottom>
                About
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel>Source</InputLabel>
                        <Select
                            name="source"
                            value={formData.source}
                            label="Source"
                            onChange={handleInputChange}
                            fullWidth
                        >
                            {generateMenuItems(SOURCE_CHOICES)}
                        </Select>
                    </FormControl>
                </Grid>
                {formData.source === 'Events' && (
                    <Grid item xs={12} sm={6}>
                        <EdvantageAutocomplete
                            name="source_external_event"
                            label="Event"
                            value={formData.source_external_event}
                            onChange={handleInputChange}
                            error={Boolean(errors.source_external_event)}
                            helperText={errors.source_external_event}
                            options={externalEvents}
                            getOptionLabel={(option) =>
                                option.name
                            }
                            getOptionValue={(newValue) => newValue.id}
                            isOptionEqualToValue={(option, value) =>
                                option.id === value
                            }
                            loading={externalEventState.loading}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel>Consultation Type</InputLabel>
                        <Select
                            name="consultation_type"
                            value={formData.consultation_type}
                            label="Consultation Type"
                            onChange={handleInputChange}
                            fullWidth
                        >
                            {Object.entries(CONSULTATION_CHOICES).map(
                                ([value, label]) => (
                                    <MenuItem key={value} value={value}>
                                        {label}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                {formData.source === 'Events' && (
                    <Grid item xs={12} sm={6}>
                        <React.Fragment>
                        </React.Fragment>
                    </Grid>
                )}
            </Grid>
            {/* Student Section */}
            <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
                Student
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="First Name"
                        name="first_name"
                        fullWidth
                        value={formData.first_name}
                        onChange={handleInputChange}
                        error={!!errors.first_name}
                        helperText={errors.first_name}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Last Name"
                        name="last_name"
                        fullWidth
                        value={formData.last_name}
                        onChange={handleInputChange}
                        error={!!errors.last_name}
                        helperText={errors.last_name}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Email"
                        name="email"
                        fullWidth
                        value={formData.email}
                        onChange={handleInputChange}
                        error={!!errors.email}
                        helperText={errors.email}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={Boolean(errors.calling_code)}
                            >
                                <InputLabel id="select-country-code">
                                    ISD Code
                                </InputLabel>
                                <Select
                                    name="calling_code"
                                    value={formData.calling_code || ""}
                                    label="ISD Code"
                                    onChange={handleInputChange}
                                >
                                    <MenuItem key="0" value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {countries.map((country) => (
                                        <MenuItem
                                            key={country.code2}
                                            value={country.calling_code}
                                        >
                                            {country.code2} ({country.calling_code})
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>
                                    {errors.calling_code}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                label="Phone Number"
                                name="phone_number"
                                fullWidth
                                value={formData.phone_number}
                                onChange={handleInputChange}
                                error={!!errors.phone_number}
                                helperText={errors.phone_number}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Date of Birth"
                        name="dob"
                        type="date"
                        value={formData.date_of_birth}
                        onChange={handleInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        select
                        name="gender"
                        label="Gender"
                        value={formData.gender}
                        onChange={handleInputChange}
                        fullWidth
                    >
                        {generateMenuItems(GENDER_CHOICES)}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <CountrySelect
                        name="nationality"
                        label="Nationality"
                        value={formData.nationality}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </Grid>
            </Grid>

            {/* Academic Section */}
            <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
                Academia
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        select
                        name="grade_type"
                        label="Grade Type"
                        value={formData.grade_type}
                        onChange={handleInputChange}
                        error={Boolean(errors.grade_type)}
                        helperText={errors.grade_type}
                        fullWidth
                    >
                        {generateMenuItems(GRADE_TYPE_CHOICES)}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        select
                        name="grade_system"
                        label="Grade System"
                        value={formData.grade_system}
                        onChange={handleInputChange}
                        error={Boolean(errors.grade_system)}
                        helperText={errors.grade_system}
                        fullWidth
                    >
                        {generateMenuItems(GRADE_SYSTEM_CHOICES)}
                    </TextField>
                </Grid>
                {formData.grade_type === "SUB" && formData.grade_system && formData.grades_per_subject.map((item, index) => (
                    <React.Fragment key={index}>
                        <Grid item xs={6}>
                            <TextField
                                select
                                label="Subject"
                                value={item.subject}
                                onChange={(e) => handleSubjectGradeChange(index, "subject", e.target.value)}
                                error={Boolean(errors[`subject_${index}`])}
                                helperText={errors[`subject_${index}`]}
                                fullWidth
                            >
                                {/* Replace with actual subject options */}
                                <MenuItem value="Math">Math</MenuItem>
                                <MenuItem value="Science">Science</MenuItem>
                                <MenuItem value="English">English</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                label="Grade"
                                value={item.grade}
                                onChange={(e) => handleSubjectGradeChange(index, "grade", e.target.value)}
                                type="text"
                                error={Boolean(errors[`grade_${index}`])}
                                helperText={errors[`grade_${index}`]}
                                fullWidth
                            />
                        </Grid>
                        {/* Add/Remove Button */}
                        <Grid item xs={1}>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-end"
                            >
                                <IconButton
                                    color="secondary"
                                    onClick={() => handleRemoveSubject(index)}
                                >
                                    <RemoveCircleOutlineIcon sx={{ color: "#d32f2f" }} fontSize="large" />
                                </IconButton>
                            </Box>
                        </Grid>
                    </React.Fragment>
                ))}
                {formData.grade_system && formData.grade_type === "SUB" && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddSubject}
                        sx={{ mt: 2, ml: 2 }}
                        disabled={formData.grades_per_subject.length > 5}
                    >
                        Add more grades
                    </Button>
                )}
                <Grid item xs={12}>
                    <TextField
                        label="GPA"
                        name="gpa"
                        fullWidth
                        value={formData.gpa}
                        onChange={handleInputChange}
                        autoComplete="off"
                        disabled={!formData.grade_system || formData.grade_type === "SUB"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        name="english_test_status"
                        label="English Test Status"
                        value={formData.english_test_status}
                        onChange={handleInputChange}
                        error={Boolean(errors.english_test_status)}
                        helperText={errors.english_test_status}
                        fullWidth
                    >
                        {ENGLISH_TEST_STATUS_CHOICES.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                {showTestDate && (
                    <Grid item xs={12}>
                        <TextField
                            name="test_date"
                            label="Test Date"
                            type="date"
                            value={formData.test_date}
                            onChange={handleInputChange}
                            error={Boolean(errors.test_date)}
                            helperText={errors.test_date}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min:
                                    formData.english_test_status === "Scheduled" && !isEdit
                                        ? today
                                        : undefined,
                                max:
                                    formData.english_test_status === "Ready"
                                        ? today
                                        : undefined,
                            }}
                            fullWidth
                        />
                    </Grid>
                )}
                {showScores && (
                    <>
                        <Grid item xs={12}>
                            <TextField
                                name="toefl_score"
                                label="TOEFL Score"
                                type="number"
                                value={formData.toefl_score}
                                onChange={handleInputChange}
                                error={Boolean(errors.toefl_score)}
                                helperText={errors.toefl_score}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="ielts_score"
                                label="IELTS Score"
                                type="number"
                                value={formData.ielts_score}
                                onChange={handleInputChange}
                                error={Boolean(errors.ielts_score)}
                                helperText={errors.ielts_score}
                                fullWidth
                            />
                        </Grid>
                    </>
                )}
            </Grid>

            {/* Preferences Section */}
            <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
                Preferences
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <GenericMultiSelect
                        name='preferred_majors'
                        label='Preferred Majors'
                        value={formData.preferred_majors}
                        onChange={handleInputChange}
                        error={Boolean(errors.preferred_majors)}
                        helperText={errors.preferred_majors}
                        options={majors}
                        getOptionLabel={(option) => option.name}
                        optionValueKey="id"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <GenericMultiSelect
                        name='preferred_programs'
                        label='Preferred Programs'
                        value={formData.preferred_programs}
                        onChange={handleInputChange}
                        error={Boolean(errors.preferred_programs)}
                        helperText={errors.preferred_programs}
                        options={programs}
                        getOptionLabel={(option) => option.name}
                        optionValueKey="id"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <GenericMultiSelect
                        name="intakes"
                        label="Preferred Intakes"
                        value={formData.intakes}
                        onChange={handleInputChange}
                        error={Boolean(errors.intakes)}
                        helperText={errors.intakes}
                        options={intakes.filter((intake) => intake.is_active === true)}
                        getOptionLabel={(option) => option.season}
                        optionValueKey="id"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <GenericMultiSelect
                        name='preferred_countries'
                        label='Preferred Countries'
                        value={formData.preferred_countries}
                        limitTags={1}
                        onChange={handleInputChange}
                        error={Boolean(errors.preferred_countries)}
                        helperText={errors.preferred_countries}
                        options={countries}
                        getOptionLabel={(option) => option.name}
                        optionValueKey="id"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        multiple
                        name='preferred_cities'
                        options={cities}
                        loading={cityState.loading} // Show loading spinner if cities are being fetched
                        value={formData.preferred_cities}
                        onChange={(event, newValue) => handleInputChange({ target: { name: 'preferred_cities', value: newValue } })}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Preferred Cities"
                                error={Boolean(errors.preferred_cities)}
                                helperText={errors.preferred_cities}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {cityState.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        renderOption={(props, option) => {
                            // Destructure the key from the props
                            const { key, ...restProps } = props;
                            return (
                                <li
                                    key={`${option.countryCode}-${option.name}`} // Directly assign the key
                                    {...restProps} // Spread remaining props
                                >
                                    <ReactCountryFlag
                                        countryCode={option.countryCode}
                                        svg
                                        style={{
                                            width: "1.5em",
                                            height: "1.5em",
                                            marginRight: "8px",
                                        }}
                                    />
                                    {option.name}
                                </li>
                            );
                        }}
                        getOptionLabel={(option) => option.name}
                    />
                </Grid>
            </Grid>

            {/* Additional Services Section */}
            <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
                Additional Services
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <GenericMultiSelect
                        name="additional_services"
                        label="Additional Services"
                        value={formData.additional_services}
                        onChange={handleInputChange}
                        options={services}
                        getOptionLabel={(option) => option.name}
                        optionValueKey="id"
                        limitTags={5}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData.has_sponsorship}
                                onChange={handleSwitchChange}
                                name="has_sponsorship"
                            />
                        }
                        label="Has Sponsorship"
                    />
                </Grid>
            </Grid>

            {/* Notes Section */}
            <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
                Notes
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Notes"
                        name="notes"
                        fullWidth
                        multiline
                        rows={4}
                        value={formData.notes}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ marginTop: 4 }}>
                <Grid item>
                    <Button type="submit" variant="contained" color="primary" disabled={Object.keys(errors).length > 0 || (isEdit && !hasUnsavedChanges)}>
                        {isEdit ? "Update Draft Lead" : "Save Draft Lead"}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default AddDraftLeadForm;
