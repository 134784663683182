import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "../wrappers/fetchWrapper";

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/externalevent`;

const initialState = {
    action: "",
    events: [],
    loading: false,
    message: null,
    error: null,
    status: "",
};

const name = "externalEvent";

const externalEventSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        changeExternalEventState: (state, action) => {
            const { action: actionType, data } = action.payload;

            if (actionType === 'create') {
                // Add new lead to the list
                state.events = [...state.leads, data];
            } else if (actionType === 'update') {
                // Update the existing lead
                const index = state.events.findIndex(event => event.id === data.id);
                if (index !== -1) {
                    state.events[index] = data;
                }
            } else if (actionType === 'delete') {
                // Delete the lead by id
                state.events = state.events.filter(event => event.id !== data.id);
            }
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        clearMessage: (state) => {
            state.message = null;
        },
        clearExternalEventState: (state) => {
            state.events = [];
            state.loading = false;
            state.error = null;
        },
        setExternalEvents: (state, action) => {
            state.events = action.payload;
        },
        setStatus: (state, action) => {
            state.status = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchExternalEvents.pending, (state) => {
                state.action = "fetchExternalEvents";
                state.status = 'loading';
                state.loading = true;
                state.message = null;
                state.error = null;
            })
            .addCase(fetchExternalEvents.fulfilled, (state, action) => {
                state.events = action.payload;
                state.message = "ExternalEvents fetched successfully";
                state.status = 'succeeded';
                state.loading = false;
            })
            .addCase(fetchExternalEvents.rejected, (state, action) => {
                state.status = 'failed';
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(addExternalEvent.pending, (state) => {
                state.status = 'loading';
                state.action = "addExternalEvent";
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(addExternalEvent.fulfilled, (state, action) => {
                state.events = [...state.events, action.payload];
                state.message = "ExternalEvent added successfully";
                state.status = 'succeeded';
                state.loading = false;
            })
            .addCase(addExternalEvent.rejected, (state, action) => {
                state.loading = false;
                state.status = 'failed';
                if (Array.isArray(action.payload)) {
                    state.error = action.payload;
                } else if (typeof action.payload === "object") {
                    state.error = ["Unknown Error!"];
                } else {
                    state.error = action.payload;
                }
            })
            .addCase(deleteExternalEvent.pending, (state) => {
                state.action = "deleteExternalEvent";
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(deleteExternalEvent.fulfilled, (state, action) => {
                state.loading = false;
                state.message = "ExternalEvent deleted successfully";
                state.events = state.events.filter(
                    (event) => event.id !== action.payload.id
                );
            })
            .addCase(deleteExternalEvent.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateExternalEvent.pending, (state) => {
                state.action = "updateExternalEvent";
                state.loading = true;
                state.status = 'loading';
                state.error = null;
                state.message = null;
            })
            .addCase(updateExternalEvent.fulfilled, (state, action) => {
                state.loading = false;
                state.status = 'succeeded';
                state.message = "ExternalEvent updated successfully";
                state.events = state.events.map((event) =>
                    event.id === action.payload.id ? action.payload : event
                );
            })
            .addCase(updateExternalEvent.rejected, (state, action) => {
                state.loading = false;
                state.status = 'failed';
                if (Array.isArray(action.payload)) {
                    state.error = action.payload;
                } else if (typeof action.payload === "object") {
                    state.error = ["Unknown Error!"];
                } else {
                    state.error = action.payload;
                }
            })
            ;
    },
});

export const { changeExternalEventState, clearMessage, clearExternalEventState, setLoading, setExternalEvents, setStatus } =
    externalEventSlice.actions;
export const externalEventReducer = externalEventSlice.reducer;

export const fetchExternalEvents = createAsyncThunk(
    `${name}/fetchExternalEvents`,
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.get(
                `${baseUrl}/fetch?timestamp=${new Date().getTime()}`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const addExternalEvent = createAsyncThunk(
    `${name}/addExternalEvent`,
    async ({ event, uuid }, { rejectWithValue }) => {
        try {
            const payload = { ...event, uuid };
            const response = await fetchWrapper.post(`${baseUrl}/create`, payload);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateExternalEvent = createAsyncThunk(
    `${name}/updateExternalEvent`,
    async ({ id, event }, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.put(
                `${baseUrl}/update/${id}/`,
                event
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteExternalEvent = createAsyncThunk(
    `${name}/deleteExternalEvent`,
    async (id, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.delete(
                `${baseUrl}/delete/${id}`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
