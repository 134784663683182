import {
    Box,
    Chip,
    CircularProgress,
    Divider,
    IconButton,
    Link,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { memo, useCallback, useMemo, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddSchoolApplicationDialog from "../Application/AddSchoolApplicationDialog";
import AddUniversityApplicationDialog from "../Application/AddUniversityApplicationDialog";
import EditIcon from "@mui/icons-material/Edit";
import EditSchoolApplicationDialog from "../Application/EditSchoolApplicationDialog";
import EditUniversityApplicationDialog from "../Application/EditUniversityApplicationDialog";
import { fetchSchools } from "../../store/school.slice";
import { fetchUniversities } from "../../store/university.slice";
import { getStatusBackgroundColor } from "../../utils/Utils";
import { useEdvantageContext } from "../../EdvantageProvider";
import useEdvantageFetch from "../../hooks/useEdvantageFetch";

/**
 * Component to display a list of applications associated with a lead
 * @param {object} props component props
 * @param {array} props.applications list of applications associated with the lead
 * @param {object} props.applicationState state of the application
 * @param {string} props.applicationStatus status of the applications list, either "loading" or "success"
 * @param {function} props.setUrlParams callback to set URL parameters
 * @param {string} props.applicationType type of application
 * @param {string} props.uuid uuid of the client
 */
const ApplicationSection = memo(function ApplicationSection({
    applications,
    applicationState,
    applicationStatus,
    lead,
    setUrlParams,
    applicationType,
}) {

    const { data: universities, status: universityStatus } = useEdvantageFetch('university', 'universities', fetchUniversities);
    const { data: schools, status: schoolStatus } = useEdvantageFetch('school', 'schools', fetchSchools);

    const { intakeById, majorById, programById, uuid } = useEdvantageContext();

    const [selectedApplication, setSelectedApplication] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openAddApplicationDialog, setAddApplicationOpenDialog] = useState(false);

    const [isSchoolMapReady, setIsSchoolMapReady] = useState(false);
    const [isUniversityMapReady, setIsUniversityMapReady] = useState(false);

    const { schoolById, courseById } = React.useMemo(() => {
        const schoolMap = new Map();
        const courseMap = new Map();

        schools.forEach((school) => {
            schoolMap.set(school.id, school);
            school.courses.forEach((course) => {
                courseMap.set(course.id, course);
            });
        });

        if (schoolStatus === "succeeded") {
            setIsSchoolMapReady(true);
        }

        return { schoolById: schoolMap, courseById: courseMap };
    }, [schools, schoolStatus]);

    const { universityById, universityCourseById } = React.useMemo(() => {
        const universityMap = new Map();
        const universityCourseMap = new Map();

        universities.forEach((university) => {
            universityMap.set(university.id, university);
            university.courses.forEach((course) => {
                universityCourseMap.set(course.id, course);
            });
        });

        if (universityStatus === "succeeded") {
            setIsUniversityMapReady(true);
        }

        return { universityById: universityMap, universityCourseById: universityCourseMap };
    }, [universities, universityStatus]);

    const handleInstituteClick = useCallback((instituteId) => {
        setUrlParams((prevParams) => ({
            ...prevParams,
            tab: applicationType === "School" ? "Schools" : "Universities",
            view: "details",
            id: instituteId,
        }));
    }, [setUrlParams]);

    const onEditApplication = useCallback((application) => {
        setSelectedApplication(application);
        setOpenDialog(true);
    }, []);

    const handleCloseDialog = useCallback(() => {
        setOpenDialog(false);
        setSelectedApplication(null);
    }, []);

    const onAddApplication = useCallback(() => {
        setAddApplicationOpenDialog(true);
    }, []);

    const renderedApplications = useMemo(() => {
        if ((applicationType === "School" && !isSchoolMapReady) ||
            (applicationType === "University" && !isUniversityMapReady)) {
            return null;
        }

        return applications.map((application) => (
            <Box key={application.id} sx={{ mb: 2 }}>
                <Divider sx={{ borderColor: "rgba(0, 0, 0, 0.87)" }} />
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography variant="subtitle1">
                        {applicationType}:{" "}
                        <Link
                            href="#"
                            underline="hover"
                            onClick={(e) => {
                                e.preventDefault();
                                handleInstituteClick(application.university_id || application.school_id);
                            }}
                            sx={{ cursor: "pointer" }}
                        >
                            {applicationType === "University" ?
                                universityById.get(application.university_id).name :
                                schoolById.get(application.school_id).name
                            }
                        </Link>
                    </Typography>
                    <IconButton
                        onClick={() => onEditApplication(application)}
                        size="small"
                        sx={{ ml: 1 }}
                    >
                        <EditIcon />
                    </IconButton>
                </Box>
                <Typography variant="subtitle2" color="text.secondary">
                    Status:{" "}
                    <Chip
                        label={application.status}
                        variant="outlined"
                        size="small"
                        sx={{
                            borderColor: getStatusBackgroundColor(application.status),
                            color: getStatusBackgroundColor(application.status),
                        }}
                    />
                </Typography>
                {applicationType === "School" && (
                    <>
                        <Typography variant="subtitle2" color="text.secondary">
                            Course: {programById.get(courseById.get(application.school_course).program).name}
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary">
                            Start Date: {application.start_date}
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary">
                            End Date: {application.end_date || "-"}
                        </Typography>
                    </>
                )}
                {applicationType === "University" && (
                    <>
                        <Typography variant="subtitle2" color="text.secondary">
                            Course: {majorById.get(universityCourseById.get(application.university_course).major).name} - {programById.get(universityCourseById.get(application.university_course).program)?.name}
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary">
                            Intake: {intakeById.get(application.intake_id)?.text} {application.year}
                        </Typography>
                    </>
                )}
            </Box>
        ));
    }, [
        applications,
        handleInstituteClick,
        onEditApplication,
        applicationType,
        courseById,
        intakeById,
        majorById,
        programById,
        schoolById,
        universityById,
        isSchoolMapReady,
        isUniversityMapReady,
    ]);

    return (
        <React.Fragment>
            <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
                <Typography variant="h6" gutterBottom>
                    {applicationType} Applications
                </Typography>
                <Tooltip title="Add New Application">
                    <IconButton
                        color="primary"
                        onClick={onAddApplication}
                        size="small"
                        aria-label="Add new application"
                        sx={{ ml: 1, pt: 0 }}
                    >
                        <AddCircleOutlineIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            {applicationStatus === "loading" ||
                (applicationType === "School" && (schoolStatus === "loading" || !isSchoolMapReady)) ||
                (applicationType === "University" && (universityStatus === "loading" || !isUniversityMapReady)) ? (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                    <CircularProgress />
                </Box>
            ) : applications.length > 0 ? (
                <Box
                    sx={{
                        maxHeight: 400,
                        overflowY: "auto",
                        pr: 1,
                    }}
                >
                    {renderedApplications}
                </Box>
            ) : (
                <Typography variant="body1" gutterBottom sx={{ mt: 1 }}>
                    No applications found
                </Typography>
            )}
            {selectedApplication && applicationType === "University" && (
                <EditUniversityApplicationDialog
                    application={selectedApplication}
                    open={openDialog}
                    handleClose={handleCloseDialog}
                />
            )}
            {selectedApplication && applicationType === "School" && (
                <EditSchoolApplicationDialog
                    application={
                        {
                            ...selectedApplication,
                            school: schoolById.get(selectedApplication.school),
                            school_course: courseById.get(selectedApplication.school_course),
                        }
                    }
                    open={openDialog}
                    handleClose={handleCloseDialog}
                    programById={programById}
                    uuid={uuid}
                />
            )}
            {applicationType === "University" && (
                <AddUniversityApplicationDialog
                    open={openAddApplicationDialog}
                    setOpenDialog={setAddApplicationOpenDialog}
                    applicationState={applicationState}
                    lead={lead}
                    universities={universities}
                    uuid={uuid}
                />
            )}
            {applicationType === "School" && schools.length > 0 && (
                <AddSchoolApplicationDialog
                    open={openAddApplicationDialog}
                    setOpenDialog={setAddApplicationOpenDialog}
                    lead={lead}
                    schoolApplications={applications}
                    schoolApplicationState={applicationState}
                    schools={schools}
                    uuid={uuid}
                />
            )}
        </React.Fragment>
    );
});

export default ApplicationSection;
