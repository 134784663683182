import { Box, Container, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import {
	CONSULTATION_CHOICES,
	GRADE_SYSTEM_CHOICES,
	GRADE_TYPE_CHOICES,
	LEAD_STATUS_CHOICES,
	SOURCE_CHOICES,
} from "../../const";

import React from "react";
import { fetchExternalEvents } from "../../store/externalEvent.slice";
import { useEdvantageContext } from "../../EdvantageProvider";
import useEdvantageFetch from "../../hooks/useEdvantageFetch";

const LeadPreview = (props) => {
	const { leadData } = props;

	const { countriesById, intakeById, programById, majorById, serviceById } = useEdvantageContext();
	const { data: externalEvents } = useEdvantageFetch('externalEvent', 'events', fetchExternalEvents);

	const renderField = (fieldName, fieldValue, optValue) => (
		<React.Fragment>
			<Grid item xs={6}>
				<Typography variant="subtitle1">{fieldName}</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography variant="body1">
					{fieldValue}
					{optValue && ` (${optValue})`}
				</Typography>
			</Grid>
		</React.Fragment>
	);

	const getFieldNames = (ids, getObjById, field) => {
		return ids.map((id) => getObjById.get(id)[field]).join(", ");
	};

	const getExternalEventName = () => {
		const event = externalEvents.find(
			({ id }) => id === leadData.source_external_event
		);
		return event ? event.name : "Unknown";
	};

	const getStatusLabel = (statusValue) => {
		const status = LEAD_STATUS_CHOICES.find(
			({ value }) => value === statusValue
		);
		return status ? status.label : "Unknown";
	};

	return (
		<Container
			component="main"
			sx={{
				backgroundColor: "#FFFFFF",
				width: "100%",
				maxWidth: 600,
				margin: "0 auto",
				padding: 2,
			}}
		>
			<Typography variant="h6" gutterBottom>
				Preview Lead Data
			</Typography>
			<Grid container spacing={2}>
				{renderField("Source", SOURCE_CHOICES[leadData.source])}
				{leadData.source === "Events" && renderField("Event Name", getExternalEventName(leadData.source_external_event))}
				{renderField("Consultation Type", CONSULTATION_CHOICES[leadData.consultation_type])}
				{renderField("Student Full Name", leadData.student.full_name)}
				{renderField("Phone Number", `${leadData.student.calling_code}${leadData.student.phone_number}`)}
				{renderField("Nationality", leadData.student.nationality)}
				{renderField(
					"English Test Status",
					leadData.english_test_status
				)}
				{leadData.toefl_score && renderField("TOEFL Score", leadData.toefl_score)}
				{leadData.ielts_score && renderField("IELTS Score", leadData.ielts_score)}
				{leadData.test_date && renderField("Test Date", leadData.test_date)}
				{renderField("Grade Type", GRADE_TYPE_CHOICES[leadData.grade_type])}
				{leadData.grade_type === "SUB" && (
					<React.Fragment>
						{/* Grades Per Subject Header */}
						<Grid item xs={6}>
							<Box display="flex" height="100%" alignItems="center">
								<Typography variant="subtitle1">Grades Per Subject</Typography>
							</Box>
						</Grid>
						{/* Grades Table */}
						<Grid item xs={6}>
							<Table
								size="small"
								aria-label="grades table"
								stickyHeader
								sx={{
									border: "1px solid rgba(0, 0, 0, 0.12)",
								}}
							>
								<TableHead>
									<TableRow>
										<TableCell
											sx={{
												fontWeight: "bold",
												backgroundColor: "#cccccc", // Adjusted for better visibility
											}}
										>
											Subject
										</TableCell>
										<TableCell
											sx={{
												fontWeight: "bold",
												backgroundColor: "#cccccc", // Adjusted for better visibility
											}}
										>
											Grade
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{leadData.grades_per_subject.map((subject, index) => (
										<TableRow key={index}>
											<TableCell>{subject.subject}</TableCell>
											<TableCell>{subject.grade}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Grid>
					</React.Fragment>
				)}
				{renderField("GPA", leadData.gpa, GRADE_SYSTEM_CHOICES[leadData.grade_system])}
				{renderField(
					"Intakes",
					getFieldNames(leadData.intakes, intakeById, "text")
				)}
				{renderField(
					"Preferred Programs",
					getFieldNames(
						leadData.preferred_programs,
						programById,
						"name"
					)
				)}
				{renderField(
					"Preferred Majors",
					getFieldNames(leadData.preferred_majors, majorById, "name")
				)}
				{renderField(
					"Preferred Cities",
					leadData.preferred_cities.map(city => city.name).join(", ")
				)}
				{renderField(
					"Preferred Countries",
					getFieldNames(
						leadData.preferred_countries,
						countriesById,
						"name"
					)
				)}
				{renderField(
					"Documents",
					leadData.documents
						.map((doc) => doc.document_type)
						.join(", ")
				)}
				{renderField(
					"Additional Services",
					getFieldNames(
						leadData.additional_services,
						serviceById,
						"name"
					)
				)}
				{renderField("Status", getStatusLabel(leadData.status))}
			</Grid>
		</Container>
	);
};

export default LeadPreview;
