import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Step,
	StepLabel,
	Stepper,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
	addUniversity,
	clearMessage,
} from "../../store/university.slice";
import { isValidCurrency, isValidFee } from "../../utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { validateUniversityField, validateUniversityForm } from "./common";

import Alert from "@mui/material/Alert";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Snackbar from "@mui/material/Snackbar";
import UniversityCourseForm from "./UniversityCourseForm";
import UniversityForm from "./UniversityForm";
import { useEdvantageContext } from "../../EdvantageProvider";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";

const steps = ["Add University", "Add University Courses"];

export default function AddUniversity({ isGoogleMapsScriptLoaded, onBack }) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const { uuid } = useEdvantageContext();

	const dispatch = useDispatch();
	const universityState = useSelector((state) => state.university);

	const [activeStep, setActiveStep] = useState(0);

	const initialUniversityData = {
		name: "",
		google_place_id: "",
		addressLine1: "",
		addressLine2: "",
		city: "",
		postalCode: "",
		country: "",
		website: "",
		brochure_url: "",
		qs_world_ranking: "",
		google_maps_url: "",
		photos: [],
	};

	const [universityData, setUniversityData] = useState(initialUniversityData);
	const [universityErrors, setUniversityErrors] = useState({});
	const [isUniversityFormValid, setIsUniversityFormValid] = useState(false);

	const initialCourseData = {
		major: "",
		program: "",
		application_method: "",
		status: "",
		partnership_status: "",
		intakes: [],
		is_sponsored: false,
		sponsor: "",
		fee: "",
		currency: "",
	};
	const [coursesData, setCoursesData] = useState([initialCourseData]);
	const [coursesErrors, setCoursesErrors] = useState([]);
	const [isCourseFormValid, setIsCourseFormValid] = useState(false);
	const [isFormDirty, setIsFormDirty] = useState(false);

	useUnsavedChangesWarning(isFormDirty);

	useEffect(() => {
		const updatedCoursesErrors = coursesData.map((course) =>
			validateSingleCourse(course)
		);
		const allValid = updatedCoursesErrors.every(
			(errors) => Object.keys(errors).length === 0
		);
		setIsCourseFormValid(allValid);
	}, [coursesData]);

	const handleNext = () => {
		if (activeStep === 0) {
			const errors = validateUniversityForm(universityData);
			if (Object.keys(errors).length === 0) {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			} else {
				setUniversityErrors(errors);
			}
		} else if (activeStep === 1) {
			const errors = validateCoursesData();
			if (errors.length === 0) {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			} else {
				setCoursesErrors(errors);
			}
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleUniversityChange = (event) => {
		const { name, value } = event.target;
		setIsFormDirty(true);
		setUniversityData({ ...universityData, [name]: value });
		validateUniversityField(name, value, setUniversityErrors);
		setIsUniversityFormValid(
			Object.keys(validateUniversityForm(universityData)).length === 0
		);
	};

	const handleUniversityChangeBulk = (updates) => {
		// Update universityData with all the new values in the updates object

		const newUniversityData = { ...universityData, ...updates };
		setIsFormDirty(true);
		setUniversityData(newUniversityData);
		const accumulatedErrors = Object.keys(updates).reduce(
			(errors, field) => {
				const fieldError = validateUniversityField(
					field,
					newUniversityData[field]
				);
				if (fieldError) {
					errors[field] = fieldError;
				}
				return errors;
			},
			{}
		);
		setUniversityErrors(accumulatedErrors);
		setIsUniversityFormValid(
			Object.keys(validateUniversityForm(newUniversityData)).length === 0
		);
	};

	const handleCourseChange = (event, index) => {
		const { name, value, type, checked } = event.target;
		const updatedCourses = [...coursesData];
		updatedCourses[index] = {
			...updatedCourses[index],
			[name]: type === "checkbox" ? checked : value,
		};
		setCoursesData(updatedCourses);
		validateCourseField(name, value, index);
	};

	const addCourse = () => {
		setCoursesData([...coursesData, initialCourseData]);
		setCoursesErrors([...coursesErrors, {}]);
	};

	// Function to delete a course
	const handleDeleteCourse = (index) => {
		const updatedCourses = coursesData.filter((_, idx) => idx !== index);
		setCoursesData(updatedCourses);
		const updatedErrors = coursesErrors.filter((_, idx) => idx !== index);
		setCoursesErrors(updatedErrors);
	};

	const handleSubmit = () => {
		dispatch(
			addUniversity({
				address: {
					address_line_1: universityData.addressLine1,
					address_line_2: universityData.addressLine2,
					city: universityData.city,
					postal_code: universityData.postalCode,
					country: universityData.country,
				},
				...universityData,
				courses: coursesData,
				uuid: uuid,
			})
		).then((response) => {
			if (response.type === "university/addUniversity/fulfilled") {
				setUniversityData(initialUniversityData);
				setUniversityErrors({});
				setIsUniversityFormValid(false);
				setCoursesData([initialCourseData]);
				setCoursesErrors([]);
				setIsCourseFormValid(false);
				setActiveStep(0);
			}
		});
	};

	const validateCoursesData = () => {
		const errors = coursesData.map((course) => {
			return validateSingleCourse(course);
		});
		return errors;
	};

	const validateSingleCourse = (course) => {
		const courseErrors = {};
		if (!course.major) courseErrors.major = "Major is required";
		if (!course.program) courseErrors.program = "Program is required";
		if (course.intakes.length === 0) courseErrors.intakes = "Intake is required";
		// Validate fee and currency if one is set
		if (course.fee) {
			if (!isValidFee(course.fee)) {
				courseErrors.fee = "Invalid fee amount";
			}
			if (!course.currency || !isValidCurrency(course.currency)) {
				courseErrors.currency = "Currency is required when fee is set";
			}
		} else if (course.currency) {
			// If currency is set but fee is not, show error
			courseErrors.fee = "Fee is required when currency is set";
		}
		return courseErrors;
	};

	const validateCourseField = (name, value, index) => {
		const updatedErrors = [...coursesErrors];
		if (!updatedErrors[index]) updatedErrors[index] = {};

		let error = "";

		// Validation for 'major' and 'program'
		if (!value && (name === "major" || name === "program")) {
			error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
		}

		// Validation for 'intakes'
		else if (name === "intakes" && value.length === 0) {
			error = "At least one intake is required";
		}

		// Validation for 'fee'
		else if (name === "fee") {
			if (value) {
				if (!isValidFee(value)) {
					error = "Invalid fee amount";
				} else if (!isValidCurrency(coursesData[index].currency)) {
					updatedErrors[index]["currency"] = "Currency is required";
				}
			} else if (isValidCurrency(coursesData[index].currency)) {
				error = "Fee is required when currency is set";
			} else if (!isValidCurrency(coursesData[index].currency)) {
				updatedErrors[index]["currency"] = "";
			}
		}

		// Validation for 'currency'
		else if (name === "currency") {
			if (value) {
				if (!isValidCurrency(value)) {
					error = "Invalid currency";
				} else if (!isValidFee(coursesData[index].fee)) {
					updatedErrors[index]["fee"] = "Fee is required when currency is set";
				}
			} else if (isValidFee(coursesData[index].fee)) {
				error = "Currency is required when fee is set";
			} else if (!isValidFee(coursesData[index].fee)) {
				updatedErrors[index]["fee"] = "";
			}
		}

		// Update the error for the field
		updatedErrors[index][name] = error;
		setCoursesErrors(updatedErrors);
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		dispatch(clearMessage());
	};

	return (
		<React.Fragment>
			<Button
				variant="contained"
				onClick={onBack}
				startIcon={<ArrowBackIosIcon />}
				sx={{ mt: 2, mb: 2 }}
			>
				Go Back
			</Button>
			<Box
				sx={{
					width: isSmallScreen ? "100%" : "600px",
					maxWidth: "100%",
					margin: "0 auto",
				}}
			>
				{universityState.message && (
					<Snackbar
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={true}
						autoHideDuration={5000}
						onClose={handleCloseSnackbar}
					>
						<Alert sx={{ width: "100%", mb: 2 }} severity="success">
							{universityState.message}
						</Alert>
					</Snackbar>
				)}
				<Stepper activeStep={activeStep} alternativeLabel>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				<Box sx={{ mt: 2, mb: 2 }}>
					{activeStep === steps.length ? (
						<React.Fragment>
							<Typography sx={{ mt: 2, mb: 1 }}>
								All steps completed - your university and
								courses have been added!
							</Typography>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									pt: 2,
								}}
							>
								<Box sx={{ flex: "1 1 auto" }} />
								<Button onClick={() => setActiveStep(0)}>
									Reset
								</Button>
							</Box>
						</React.Fragment>
					) : (
						<React.Fragment>
							{universityState.error && (
								<Alert
									sx={{ width: "100%", mt: 2, mb: 2 }}
									severity="error"
								>
									{universityState.error}
								</Alert>
							)}
							{activeStep === 0 && isGoogleMapsScriptLoaded ? (
								<UniversityForm
									handleChange={handleUniversityChange}
									handleChangeBulk={
										handleUniversityChangeBulk
									}
									universityData={universityData}
									errors={universityErrors}
								/>
							) : (
								<UniversityCourseForm
									coursesData={coursesData}
									handleChange={handleCourseChange}
									handleDelete={handleDeleteCourse}
									addCourse={addCourse}
									errors={coursesErrors}
									isCourseFormValid={isCourseFormValid}
								/>
							)}
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									pt: 2,
								}}
							>
								<Button
									variant="outlined"
									color="primary"
									disabled={activeStep === 0}
									onClick={handleBack}
									sx={{ mr: 1 }}
								>
									Back
								</Button>
								<Box sx={{ flex: "1 1 auto" }} />
								{activeStep === steps.length - 1 ? (
									<Button
										variant="contained"
										color="primary"
										onClick={handleSubmit}
										disabled={!isCourseFormValid}
									>
										Submit
									</Button>
								) : (
									<Button
										variant="contained"
										color="primary"
										onClick={handleNext}
										disabled={!isUniversityFormValid}
									>
										Next
									</Button>
								)}
							</Box>
						</React.Fragment>
					)}
				</Box>
			</Box>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1000,
				}}
				open={universityState.loading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</React.Fragment>
	);
}
