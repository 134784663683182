import * as React from "react";

import { clearMessage, fetchExternalEvents } from "../../store/externalEvent.slice";

import AddExternalEventDialog from "../ExternalEvent/AddExternalEventDialog";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import ExternalEventTable from "../ExternalEvent/ExternalEventTable";
import ExternalEventTableSkeleton from "../ExternalEvent/ExternalEventTableSkeleton";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch } from "react-redux";
import { useEdvantageContext } from "../../EdvantageProvider";
import useEdvantageFetch from "../../hooks/useEdvantageFetch";

function ExternalEvent() {
    const dispatch = useDispatch();
    const { countriesById, employeeById, uuid } = useEdvantageContext();
    const { data: events, state: externalEventState } = useEdvantageFetch('externalEvent', 'events', fetchExternalEvents);

    const [openDialog, setOpenDialog] = React.useState(false);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        dispatch(clearMessage());
    };

    return (
        <Container component="main" sx={{ backgroundColor: "#FFFFFF" }}>
            {externalEventState.message && externalEventState.action !== "fetchExternalEvents" && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={true}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                >
                    <Alert sx={{ width: "100%", mb: 2 }} severity="success">
                        {externalEventState.message}
                    </Alert>
                </Snackbar>
            )}
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                {externalEventState.error &&
                    externalEventState.action === "updateMajor" && (
                        <Alert
                            sx={{ width: "100%", mt: 2, mb: 2 }}
                            severity="error"
                        >
                            {externalEventState.error}
                        </Alert>
                    )}
                {externalEventState.loading || employeeById.size === 0 || countriesById.size === 0 ? (
                    <ExternalEventTableSkeleton />
                ) : (
                    <ExternalEventTable
                        countryById={countriesById}
                        employeeById={employeeById}
                        externalEventState={externalEventState}
                        setOpenDialog={setOpenDialog}
                    />
                )}
                < AddExternalEventDialog
                    open={openDialog}
                    setOpenDialog={setOpenDialog}
                    externalEventState={externalEventState}
                    uuid={uuid}
                />
            </Box>
        </Container>
    );
}

export default ExternalEvent;
