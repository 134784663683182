import {
	Box,
	Button,
	Collapse,
	Grid,
	MenuItem,
	Paper,
	TextField,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { ENGLISH_TEST_STATUS_CHOICES, LEAD_STATUS_CHOICES } from "../../const";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import EdvantageAutocomplete from "../common/EdvantageAutocomplete";
import GenericMultiSelect from "../common/GenericMultiSelect";
import LeadTableSkeleton from "./LeadTableSkeleton";
import Refresh from "@mui/icons-material/Refresh";
import debounce from 'lodash/debounce';
import { fetchLeads } from "../../store/lead.slice";
import leadColumns from "./leadColumns";
import { useEdvantageContext } from "../../EdvantageProvider";

const LeadTable = React.memo((props) => {
	const { onEdit, onViewDetail } = props;
	const { artifactLoading, employees, intakes, intakeById, employeeById, employeesLoading, programs, programById, majorById, countries, countriesById, serviceById } = useEdvantageContext();
	const dispatch = useDispatch();
	const { leads, pagination, loading } = useSelector((state) => state.lead);
	const initalSearchFilters = {
		name: "",
		status: "",
		case_manager: "",
		intakes: [],
		english_test_status: "",
		preferred_programs: [],
		preferred_cities: [],
		preferred_countries: [],
		additional_services: [],
	}
	const [searchFilters, setSearchFilters] = useState(initalSearchFilters);

	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(25);

	const handleSearchChange = useCallback(
		(event) => {
			const { name, value } = event.target;
			setSearchFilters((prevFilters) => ({
				...prevFilters,
				[name]: value,
			}));
		},
		[]
	);

	// Debounced function for fetching leads
	const debouncedFetchLeads = useCallback(
		debounce((page, pageSize, filters, dispatch) => {
			dispatch(fetchLeads({ page, limit: pageSize, filters }));
		}, 500), // 500ms debounce delay
		[] // Dependencies are intentionally empty for consistent debounce
	);

	useEffect(() => {
		// Fetch leads whenever pagination or filters change
		debouncedFetchLeads(page, pageSize, searchFilters, dispatch);

		// Cleanup debounce on unmount to prevent memory leaks
		return () => debouncedFetchLeads.cancel();
	}, [page, pageSize, searchFilters, dispatch, debouncedFetchLeads]);

	const resetSearchFilter = () => {
		setSearchFilters(initalSearchFilters);
	};

	const filteredLeads = useMemo(() => {
		return leads.filter((lead) => {

			return (
				(lead.student.full_name
					.toLowerCase()
					.includes(searchFilters.name.toLowerCase()) ||
					searchFilters.name === "") &&
				(lead.status === searchFilters.status ||
					searchFilters.status === "") &&
				(lead.case_manager === searchFilters.case_manager ||
					searchFilters.case_manager === "") &&
				(searchFilters.intakes.length === 0 ||
					searchFilters.intakes.some((id) =>
						lead.intakes.includes(id)
					)) &&
				(lead.english_test_status ===
					searchFilters.english_test_status ||
					searchFilters.english_test_status === "") &&
				(searchFilters.preferred_programs.length === 0 ||
					searchFilters.preferred_programs.some((id) =>
						lead.preferred_programs.includes(id)
					)) &&
				(searchFilters.preferred_cities.length === 0 ||
					searchFilters.preferred_cities.some((city) =>
						lead.preferred_cities.includes(city)
					)) &&
				(searchFilters.preferred_countries.length === 0 ||
					searchFilters.preferred_countries.some((id) =>
						lead.preferred_countries.includes(id)
					)) &&
				(searchFilters.additional_services.length === 0 ||
					searchFilters.additional_services.some((service) =>
						lead.additional_services.includes(service)
					))
			);
		});
	}, [leads, searchFilters]);

	const handlePaginationModelChange = useCallback(
		(model) => {
			if (model.page !== page - 1) {
				setPage(model.page + 1);
			} else if (model.pageSize !== pageSize) {
				setPageSize(model.pageSize);
			}
		},
		[page, pageSize]
	);

	return (
		<React.Fragment>
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					alignItems: "center",
				}}
			>
				<Button startIcon={<Refresh />} onClick={resetSearchFilter}>
					Reset Filters
				</Button>
			</Box>
			<Collapse in={true}>
				<Paper sx={{ p: 2, mb: 2, position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff" }}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={1.5}>
							<TextField
								label="Student"
								name="name"
								variant="outlined"
								fullWidth
								value={searchFilters.name}
								onChange={handleSearchChange}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={1.5}>
							<TextField
								select
								name="status"
								label="Status"
								value={searchFilters.status}
								onChange={handleSearchChange}
								fullWidth
							>
								<MenuItem value="">
									<em>None</em>
								</MenuItem>
								{LEAD_STATUS_CHOICES.map((option) => (
									<MenuItem
										key={option.value}
										value={option.value}
									>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item xs={12} sm={6} md={1.5}>
							<EdvantageAutocomplete
								name="case_manager"
								label="Case Manager"
								value={searchFilters.case_manager}
								onChange={(e) =>
									handleSearchChange({ target: { name: "case_manager", value: e.target.value } })
								}
								options={employees}
								getOptionLabel={(option) => option.full_name}
								getOptionValue={(newValue) => newValue.id}
								isOptionEqualToValue={(option, value) =>
									option.id === value
								}
								loading={employeesLoading}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={1.5}>
							<GenericMultiSelect
								name="intakes"
								label="Intakes"
								value={searchFilters.intakes}
								onChange={handleSearchChange}
								options={intakes}
								getOptionLabel={(option) => option.text}
								optionValueKey="id"
								limitTags={1}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={2}>
							<TextField
								select
								name="english_test_status"
								label="English Test Status"
								value={searchFilters.english_test_status}
								onChange={handleSearchChange}
								fullWidth
							>
								<MenuItem value="">
									<em>None</em>
								</MenuItem>
								{ENGLISH_TEST_STATUS_CHOICES.map((option) => (
									<MenuItem
										key={option.value}
										value={option.value}
									>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item xs={12} sm={6} md={2}>
							<GenericMultiSelect
								name="preferred_programs"
								label="Preferred Programs"
								value={searchFilters.preferred_programs}
								onChange={handleSearchChange}
								options={programs}
								getOptionLabel={(option) => option.name}
								optionValueKey="id"
								limitTags={1}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={2}>
							<GenericMultiSelect
								name="preferred_countries"
								label="Preferred Countries"
								value={searchFilters.preferred_countries}
								limitTags={1}
								onChange={handleSearchChange}
								options={countries}
								getOptionLabel={(option) => option.name}
								optionValueKey="id"
							/>
						</Grid>
					</Grid>
				</Paper>
			</Collapse>

			{/* DataGrid in a scrollable Box */}
			<Box sx={{ maxHeight: 600, overflowY: "auto" }}>
				{artifactLoading || loading ? (
					<LeadTableSkeleton />
				) : (
					<DataGrid
						initialState={{
							columns: {
								columnVisibilityModel: {
									// Hide columns id, the other columns will remain visible
									id: true,
								},
							},
						}}
						rows={filteredLeads}
						columns={leadColumns(onEdit, onViewDetail, countriesById, employeeById, intakeById, majorById, programById, serviceById)}
						rowCount={pagination.total}
						pageSizeOptions={[10, 25, 50, 100]}
						page={page - 1}
						rowsPerPage={pageSize}
						paginationModel={{ page: pagination.page - 1, pageSize: pagination.limit }}
						onPaginationModelChange={handlePaginationModelChange}
						loading={loading}
						paginationMode="server"
						disableColumnMenu={true}
						disableColumnFilter={true}
						disableColumnSorting={true}
						disableColumnResize={true}
						autoHeight
						slots={{
							toolbar: GridToolbar,
						}}
						sx={{
							// Custom styles for sticky header
							"& .MuiDataGrid-columnHeaders": {
								whiteSpace: "nowrap", // Prevent text from wrapping
							},
							"& .MuiDataGrid-row.course-row": {
								backgroundColor: "#f5f5f5",
								"&:hover": {
									backgroundColor: "#e0e0e0",
								},
							},
							"& .MuiDataGrid-columnHeaderTitle": {
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
							},
						}}
					/>
				)}
			</Box>
		</React.Fragment>
	);
});

LeadTable.displayName = "LeadTable";

export default LeadTable;
