import * as React from "react";

import { useDispatch, useSelector } from "react-redux";

import AddIcon from "@mui/icons-material/Add";
import AddUniversityApplicationDialog from "../Application/AddUniversityApplicationDialog";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
import Snackbar from "@mui/material/Snackbar";
import UniversityApplicationTable from "../Application/UniversityApplicationTable";
import { clearMessage } from "../../store/universityApplication.slice";
import { fetchUniversities } from "../../store/university.slice";
import useApplication from "../../hooks/useApplication";
import { useEdvantageContext } from "../../EdvantageProvider";
import useEdvantageFetch from "../../hooks/useEdvantageFetch";

function UniversityApplication(props) {
	const dispatch = useDispatch();
	const applicationState = useSelector((state) => state.universityApplication);
	const universityState = useSelector((state) => state.university);
	const { applications } = useApplication();
	const { data: universities, status: universityStatus } = useEdvantageFetch('university', 'universities', fetchUniversities);
	const { artifactLoading, uuid } = useEdvantageContext();

	const [openDialog, setOpenDialog] = React.useState(false);
	const [isUniversityMapReady, setIsUniversityMapReady] = React.useState(false);

	const handleOpenDialog = () => {
		setOpenDialog(true);
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		dispatch(clearMessage());
	};

	const { universityById, universityCourseById } = React.useMemo(() => {
		const universityMap = new Map();
		const universityCourseMap = new Map();

		universities.forEach((university) => {
			universityMap.set(university.id, university);
			university.courses.forEach((course) => {
				universityCourseMap.set(course.id, course);
			});
		});

		if (universityStatus === "succeeded") {
			setIsUniversityMapReady(true);
		}


		return { universityById: universityMap, universityCourseById: universityCourseMap };
	}, [universities]);

	return (
		<Box sx={{ width: "100%", overflowX: "auto" }}>
			{applicationState.message && (
				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={true}
					autoHideDuration={5000}
					onClose={handleCloseSnackbar}
				>
					<Alert sx={{ width: "100%", mb: 2 }} severity="success">
						{applicationState.message}
					</Alert>
				</Snackbar>
			)}
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				{applicationState.error &&
					applicationState.action === "updateUniversityApplication" && (
						<Alert
							sx={{ width: "100%", mt: 2, mb: 2 }}
							severity="error"
						>
							{applicationState.error}
						</Alert>
					)}
				<Box
					sx={{
						width: "100%",
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "end",
						mb: 2,
					}}
				>
					<Button
						variant="contained"
						color="primary"
						startIcon={<AddIcon />} // Add icon before the text
						onClick={handleOpenDialog}
					>
						Add Application
					</Button>
				</Box>
				{applications.length && isUniversityMapReady ? (
					<UniversityApplicationTable
						applicationState={applicationState}
						setUrlParams={props.setUrlParams}
						universityById={universityById}
						universityCourseById={universityCourseById}
					/>
				) : (
					!artifactLoading &&
					!applicationState.loading &&
					!isUniversityMapReady && (
						<Alert
							sx={{ width: "100%", mt: 2, mb: 2 }}
							severity="warning"
						>
							No Application Found! Add a new application
						</Alert>
					)
				)}
				{!artifactLoading && universities.length > 0 && (
					<AddUniversityApplicationDialog
						open={openDialog}
						setOpenDialog={setOpenDialog}
						applicationState={applicationState}
						universities={universities}
						uuid={uuid}
					/>
				)}
			</Box>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1000,
				}}
				open={
					applicationState.loading ||
					artifactLoading ||
					universityState.loading
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Box>
	);
}

export default UniversityApplication;
