import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	FormControlLabel,
	Step,
	StepLabel,
	Stepper,
	Switch,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { addLead, clearMessage as clearLeadMessage } from "../../store/lead.slice";
import { addStudent, clearMessage as clearStudentMessage, updateStudent, updateStudentStatus } from "../../store/student.slice";
import { useDispatch, useSelector } from "react-redux";

import AboutLeadForm from "./AboutLeadForm";
import AcademiaForm from "./AcademiaForm";
import AdditionalServicesForm from "./AdditionalServicesForm";
import Alert from "@mui/material/Alert";
import LeadPreview from "./LeadPreview";
import LeadStatus from "./LeadStatus";
import PreferencesForm from "./PreferenceForm";
import Snackbar from "@mui/material/Snackbar";
import StudentAutocomplete from "../Student/StudentAutoComplete";
import StudentForm from "../Student/StudentForm";
import UploadDocumentForm from "./UploadDocumentForm";
import { getUpdatedFields } from "../../utils/Utils";
import { merge } from 'lodash';
import { useEdvantageContext } from "../../EdvantageProvider";
import useStudent from "../../hooks/useStudent";

const steps = [
	"About Lead",
	"Student",
	"Academia",
	"Preferences",
	"Upload Documents",
	"Additional Services",
	"Status",
	"Preview",
];

export default function AddLead(props) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const { uuid } = useEdvantageContext();

	const dispatch = useDispatch();
	const leadState = useSelector((state) => state.lead);
	const studentState = useSelector((state) => state.student);
	const [activeStep, setActiveStep] = useState(0);
	const { students } = useStudent();

	const newStudents = useMemo(
		() => students.filter((student) => student.status === "New"),
		[students]
	);

	const initialLeadData = useMemo(
		() => ({
			student: {
				id: "",
				user: { first_name: "", last_name: "", email: "" },
				full_name: "",
				phone_country_code: "",
				calling_code: "",
				phone_number: "",
				dob: "",
				gender: "",
				nationality: "",
				is_address_available: false,
				address: { address_line_1: "", address_line_2: "", city: "", postal_code: "", country: "" },
			},
			consultation_type: "",
			source: "",
			source_external_event: "",
			english_test_status: "",
			grade_type: "",
			grades_per_subject: [],
			grade_system: "",
			gpa: "",
			toefl_score: "",
			ielts_score: "",
			test_date: "",
			intakes: [],
			preferred_programs: [],
			preferred_majors: [],
			preferred_cities: [],
			preferred_countries: [],
			documents: [],
			additional_services: [],
			status: "",
		}),
		[]
	);

	const [leadData, setLeadData] = useState(
		useMemo(() => ({ ...initialLeadData, ...props.initialLeadData }), [props.initialLeadData, initialLeadData])
	);
	const [isDataChanged, setIsDataChanged] = useState(false);
	const [leadErrors, setLeadErrors] = useState({});
	const [isaboutLeadFormValid, setIsAboutLeadFormValid] = useState(false);
	const [isStudentFormValid, setIsStudentFormValid] = useState(false);
	const [isAcademiaFormValid, setIsAcademiaFormValid] = useState(false);
	const [isPreferenceFormValid, setIsPreferenceFormValid] = useState(false);
	const [isUploadDocumentFormValid, setIsUploadDocumentFormValid] = useState(true);
	const [isStatusFormValid, setIsStatusFormValid] = useState(false);
	const [isNewStudent, setIsNewStudent] = useState(true);

	const handleNext = useCallback(() => {
		if (activeStep !== 1) {
			setActiveStep((prev) => prev + 1);
			return;
		}

		if (leadData.student.id) {
			const existingStudent = studentState.students.find((s) => s.id === leadData.student.id);
			const updatedFields = getUpdatedFields(existingStudent, leadData.student);

			if (Object.keys(updatedFields).length > 0) {
				dispatch(updateStudent({ id: leadData.student.id, student: updatedFields })).then((response) => {
					if (response.type === "student/updateStudent/fulfilled") {
						dispatch(clearStudentMessage());
						setLeadData((prev) => ({ ...prev, student: response.payload }));
						setActiveStep((prev) => prev + 1);
					}
				});
			} else {
				setActiveStep((prev) => prev + 1);
			}
		} else if (isNewStudent) {
			dispatch(addStudent({ ...leadData.student, uuid })).then((response) => {
				if (response.type === "student/addStudent/fulfilled") {
					setLeadData((prev) => ({ ...prev, student: response.payload }));
					setActiveStep((prev) => prev + 1);
				}
			});
		}
	}, [activeStep, leadData, dispatch, studentState, isNewStudent, uuid]);

	const handleBack = useCallback(() => {
		setActiveStep((prev) => prev - 1);
	}, []);

	const handleLeadChange = (event) => {
		const { name, value } = event.target;
		const newLeadData = { ...leadData, [name]: value };
		setLeadData(newLeadData);

		if (name === "student" && value?.id) {
			setIsStudentFormValid(true);
		}
	};

	const handleSubmit = () => {
		const updatedLeadData = {
			...leadData,
			student: leadData.student.id,
			toefl_score: leadData.toefl_score
				? parseInt(leadData.toefl_score, 10)
				: null,
			ielts_score: leadData.ielts_score
				? parseInt(leadData.ielts_score, 10)
				: null,
			documents: leadData.documents.map((document) => document.id),
		};

		dispatch(
			addLead({
				leadData: updatedLeadData,
				uuid,
				draftlead_id: props.draftLeadId,
			})
		).then((response) => {
			if (response.type === "lead/addLead/fulfilled") {
				dispatch(
					updateStudentStatus({
						id: leadData.student.id,
						status: "Lead Created",
					})
				);
				setLeadData(initialLeadData);
				setLeadErrors({});
				setIsAboutLeadFormValid(false);
				setIsStudentFormValid(false);
				setIsAcademiaFormValid(false);
				setIsPreferenceFormValid(false);
				handleNext();
			}
		});
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		dispatch(clearLeadMessage());
		dispatch(clearStudentMessage());
	};

	const handleSwitchChange = useCallback(() => {
		setIsNewStudent((prev) => !prev);
	}, [initialLeadData]);


	useEffect(() => {
		// Deep merge to ensure only the keys that exist in props.initialLeadData are updated
		if (props.initialLeadData) {
			const updatedLeadData = merge({}, initialLeadData, props.initialLeadData);
			setLeadData(updatedLeadData);
		}
	}, [props.initialLeadData]);

	// Compare leadData with initialLeadData to detect changes
	useEffect(() => {
		const hasChanged = JSON.stringify(leadData) !== JSON.stringify(initialLeadData);
		setIsDataChanged(hasChanged);
	}, [leadData, initialLeadData]);

	// Handle the beforeunload event
	useEffect(() => {
		const handleBeforeUnload = (event) => {
			if (isDataChanged) {
				const message = "You have unsaved changes. Your progress will be lost if you leave. Are you sure you want to leave?";
				event.returnValue = message; // Standard for most browsers
				return message; // For Firefox
			}
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [isDataChanged]);

	return (
		<Box
			sx={{
				width: isSmallScreen ? "100%" : "800px",
				maxWidth: "100%",
				margin: "0 auto",
			}}
		>
			{(leadState.message || (studentState.action == "addStudent" && studentState.message)) && (
				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={true}
					autoHideDuration={5000}
					onClose={handleCloseSnackbar}
				>
					<Alert sx={{ width: "100%", mb: 2 }} severity="success">
						{leadState.message || studentState.message}
					</Alert>
				</Snackbar>
			)}
			<Stepper activeStep={activeStep} alternativeLabel>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
			<Box sx={{ mt: 2, mb: 2 }}>
				{activeStep === steps.length ? (
					<React.Fragment>
						<Alert severity="success">
							All steps completed - your lead has been added!
						</Alert>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								pt: 2,
							}}
						>
							<Button
								variant="contained"
								onClick={() => setActiveStep(0)}
							>
								Create another lead
							</Button>
						</Box>
					</React.Fragment>
				) : (
					<React.Fragment>
						{leadState.error && (
							<Alert
								sx={{ width: "100%", mt: 2, mb: 2 }}
								severity="error"
							>
								{leadState.error}
							</Alert>
						)}
						{activeStep === 0 && (
							<AboutLeadForm
								leadData={leadData}
								setLeadData={setLeadData}
								setIsFormValid={setIsAboutLeadFormValid}
							/>
						)}
						{activeStep === 1 && (
							<>
								<Box display="flex" alignItems="center" mb={2}>
									<FormControlLabel
										label="New Student"
										control={
											<Switch
												checked={isNewStudent}
												onChange={handleSwitchChange}
												name="newStudentSwitch"
												color="primary"
											/>
										}
										labelPlacement="start"
										sx={{ ml: 0 }}
									/>
								</Box>
								{isNewStudent ? (
									<StudentForm
										leadData={leadData}
										setLeadData={setLeadData}
										studentState={studentState}
										isNewStudent={isNewStudent}
										setIsStudentFormValid={
											setIsStudentFormValid
										}
										isDraftLead={Boolean(props.draftLeadId)}
									/>
								) : (
									<>
										<StudentAutocomplete
											students={newStudents}
											name="student"
											label="Student"
											value={leadData.student.full_name || ""}
											onChange={handleLeadChange}
											error={Boolean(leadErrors.student)}
											helperText={leadErrors.student}
										/>
										<StudentForm
											leadData={leadData}
											studentState={studentState}
											setIsStudentFormValid={
												setIsStudentFormValid
											}
										/>
									</>
								)}
							</>
						)}
						{activeStep === 2 && (
							<AcademiaForm
								leadData={leadData}
								setLeadData={setLeadData}
								setIsAcademiaFormValid={setIsAcademiaFormValid}
								isDraftLead={Boolean(props.draftLeadId)}
							/>
						)}
						{activeStep === 3 && (
							<PreferencesForm
								leadData={leadData}
								setLeadData={setLeadData}
								setIsPreferenceFormValid={
									setIsPreferenceFormValid
								}
							/>
						)}
						{activeStep === 4 && (
							<UploadDocumentForm
								studentId={leadData.student.id}
								setLeadData={setLeadData}
								setIsUploadDocumentFormValid={setIsUploadDocumentFormValid}
							/>
						)}
						{activeStep === 5 && (
							<AdditionalServicesForm
								leadData={leadData}
								setLeadData={setLeadData}
							/>
						)}
						{activeStep === 6 && (
							<LeadStatus
								leadData={leadData}
								setLeadData={setLeadData}
								setIsFormValid={setIsStatusFormValid}
							/>
						)}
						{activeStep === 7 && (
							<LeadPreview leadData={leadData} />
						)}
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								pt: 2,
							}}
						>
							<Button
								variant="outlined"
								color="primary"
								disabled={activeStep === 0}
								onClick={handleBack}
								sx={{ mr: 1 }}
							>
								Back
							</Button>
							<Box sx={{ flex: "1 1 auto" }} />
							{activeStep === steps.length - 1 ? (
								<Button
									variant="contained"
									color="primary"
									onClick={handleSubmit}
								>
									Submit
								</Button>
							) : (
								<Button
									variant="contained"
									color="primary"
									onClick={handleNext}
									disabled={
										(activeStep === 0 &&
											!isaboutLeadFormValid) ||
										(activeStep === 1 &&
											!isStudentFormValid) ||
										(activeStep === 2 &&
											!isAcademiaFormValid) ||
										(activeStep === 3 &&
											!isPreferenceFormValid) ||
										(activeStep === 4 &&
											!isUploadDocumentFormValid) ||
										(activeStep === 6 && !isStatusFormValid)
									}
								>
									Next
								</Button>
							)}
						</Box>
					</React.Fragment>
				)}
			</Box>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1000,
				}}
				open={leadState.loading || studentState.loading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Box>
	);
}
