import {
	Alert,
	Backdrop,
	Box,
	CircularProgress,
	Snackbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { clearError as cleadLeadError, clearMessage as cleadLeadMessage, fetchLead } from "../../store/lead.slice";
import { useDispatch, useSelector } from "react-redux";

import LeadDetail from "../Leads/LeadDetail";
import LeadEditDialog from "../Leads/LeadEditDialog";
import LeadTable from "../Leads/LeadTable";
import { clearMessage as clearApplicationMessage } from "../../store/universityApplication.slice";
import { clearMessage as clearDocumentMessage } from "../../store/document.slice";
import { clearMessage as clearSchoolApplicationMessage } from "../../store/schoolApplication.slice";
import { clearMessage as clearStudentMessage } from "../../store/student.slice";
import { useEdvantageContext } from "../../EdvantageProvider";

export default function Lead({ urlParams, setUrlParams }) {
	const dispatch = useDispatch();
	const leadState = useSelector((state) => state.lead);
	const documentState = useSelector((state) => state.document);
	const studentState = useSelector((state) => state.student);
	const applicationState = useSelector((state) => state.universityApplication);
	const schoolApplicationState = useSelector((state) => state.schoolApplication);
	const [selectedLead, setSelectedLead] = useState(null);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const { artifactLoading, countriesById, majorById, programById, serviceById } = useEdvantageContext();
	const [isLoading, setIsLoading] = useState(artifactLoading || leadState.loading || documentState.loading || studentState.loading);

	useEffect(() => {
		setIsLoading(artifactLoading || leadState.loading || documentState.loading || studentState.loading);
	}, [artifactLoading, leadState.loading, documentState.loading, studentState.loading]);

	const handleDialogClose = () => {
		setIsDialogOpen(false);
		setSelectedLead(null);
	};

	const handleEditLead = (lead) => {
		setSelectedLead({
			...lead,
			preferred_countries_objs: lead.preferred_countries.map(
				(countryId) => countriesById.get(countryId)
			),
			additional_services: lead.additional_services,
		});
		setIsDialogOpen(true);
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		dispatch(cleadLeadMessage());
		dispatch(clearStudentMessage());
		dispatch(clearDocumentMessage());
		dispatch(clearApplicationMessage());
	};

	const handleViewLeadDetail = (leadId) => {
		setUrlParams(prevParams => ({
			...prevParams,
			view: "details",
			id: leadId,
		}));
	};

	const handleBack = () => {
		setUrlParams((prevParams) => {
			const { id, ...restParams } = prevParams;
			return {
				...restParams,
				view: "table",
			};
		});
		setSelectedLead(null);
	};

	const fetchAndSetLead = async (leadId) => {
		let leadData;

		// Try to find the lead in the existing state
		const lead = leadState.leads.find((lead) => lead.id === leadId);

		if (lead) {
			leadData = lead;
		} else {
			// Dispatch the fetch lead action and wait for it to complete
			const response = await dispatch(fetchLead(leadId));

			if (response.type === "lead/fetchLead/fulfilled") {
				leadData = response.payload;
			}
		}

		// If leadData is found (either from state or API), process and set it
		if (leadData) {
			const {
				preferred_countries = [],
				preferred_majors = [],
				preferred_programs = [],
			} = leadData;

			setSelectedLead({
				...leadData,
				preferred_countries: preferred_countries.map((id) => countriesById.get(id)),
				preferred_majors: preferred_majors.map((id) => majorById.get(id)),
				preferred_programs: preferred_programs.map((id) => programById.get(id)),
				preferred_countries_objs: preferred_countries.map((id) => countriesById.get(id)),
			});
		}
	};

	useEffect(() => {
		if (urlParams.id && countriesById.size > 0 && majorById.size > 0 && programById.size > 0) {
			fetchAndSetLead(urlParams.id);
		}
	}, [urlParams.id, countriesById, majorById, programById]);


	// Clear message on component unmount
	React.useEffect(() => {
		return () => {
			dispatch(cleadLeadMessage());
			dispatch(clearStudentMessage());
			dispatch(clearDocumentMessage());
			dispatch(clearApplicationMessage());
			dispatch(clearSchoolApplicationMessage());
			dispatch(cleadLeadError());
		};
	}, [dispatch]);

	return (
		<Box sx={{ width: "100%", overflowX: "auto" }}>
			{(leadState.message || documentState.message || studentState.message || applicationState.message || schoolApplicationState.message) && (
				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={true}
					autoHideDuration={3000}
					onClose={handleCloseSnackbar}
				>
					<Alert sx={{ width: "100%", mb: 2 }} severity="success">
						{leadState.message || documentState.message || studentState.message || applicationState.message || schoolApplicationState.message}
					</Alert>
				</Snackbar>
			)}
			{urlParams.view === "table" && (
				<LeadTable
					onEdit={handleEditLead}
					onViewDetail={handleViewLeadDetail}
				/>
			)}
			{urlParams.view === "details" && selectedLead && !artifactLoading && (
				<LeadDetail
					lead={selectedLead}
					setUrlParams={setUrlParams}
					handleBack={handleBack}
					serviceById={serviceById}
				/>
			)}
			{selectedLead && (
				<LeadEditDialog
					open={isDialogOpen}
					onClose={handleDialogClose}
					lead={selectedLead}
				/>
			)}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1000,
				}}
				open={isLoading && urlParams.view !== "table"}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Box>
	);
}
