import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import EdvantageAutocomplete from "../common/EdvantageAutocomplete";
import PropTypes from "prop-types";
import React from "react";

/**
 * A component that renders a form for a school course.
 * @param {object} props Props passed to the component.
 * @param {array} props.coursesData An array of school course objects.
 * @param {function} props.handleChange A function called when a text field is changed.
 * @param {function} props.handleDelete A function called when the delete button is clicked.
 * @param {array} props.errors An array of error objects, one for each course.
 * @param {boolean} props.isCourseFormValid Whether the entire form is valid.
 * @param {function} props.addCourse A function called when the add course button is clicked.
 * @returns {object} JSX for the component.
 */
function SchoolCourseForm(props) {
    const {
        programs,
        currencies,
        coursesData,
        handleChange,
        handleDelete,
        errors,
        isCourseFormValid,
    } = props;

    return (
        <Box>
            {coursesData.map((course, index) => (
                <Paper
                    key={index}
                    elevation={3}
                    sx={{ mb: 2, borderRadius: "4px", padding: "16px" }}
                >
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>
                        Course {index + 1}
                        <IconButton
                            onClick={() => handleDelete(index)}
                            size="small"
                            sx={{ ml: 1 }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <EdvantageAutocomplete
                                name="program"
                                label="Program"
                                value={course.program}
                                onChange={handleChange}
                                error={Boolean(errors[index]?.program)}
                                helperText={errors[index]?.program}
                                index={index}
                                options={programs}
                                getOptionLabel={(option) =>
                                    option.name
                                }
                                getOptionValue={(newValue) => newValue.id}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="application-select-label">
                                    Application Method
                                </InputLabel>
                                <Select
                                    labelId="application-select-label"
                                    id="application-select"
                                    value={course.application_method}
                                    label="Application Method"
                                    name="application_method"
                                    onChange={(e) => handleChange(e, index)}
                                >
                                    <MenuItem key={0} value={"UCAS"}>
                                        UCAS
                                    </MenuItem>
                                    <MenuItem key={1} value={"Direct"}>
                                        Direct
                                    </MenuItem>
                                    <MenuItem key={2} value={"Pathway"}>
                                        Pathway
                                    </MenuItem>
                                    <MenuItem key={3} value={"Other"}>
                                        Other
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Fee"
                                name="fee"
                                type="number"
                                value={course.fee}
                                onChange={(e) => handleChange(e, index)}
                                error={Boolean(errors[index]?.fee)}
                                helperText={errors[index]?.fee}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                fullWidth
                                error={Boolean(errors[index]?.currency)}
                            >
                                <InputLabel id="currency-select-label">
                                    Currency
                                </InputLabel>
                                <Select
                                    labelId="currency-select-label"
                                    id="currency-select"
                                    value={course.currency}
                                    label="Currency"
                                    name="currency"
                                    onChange={(e) => handleChange(e, index)}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {currencies.map((currency) => (
                                        <MenuItem
                                            key={currency.code}
                                            value={currency.id}
                                        >
                                            {currency.name} ({currency.symbol})
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>
                                    {errors[index]?.currency}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            ))}
            <Button
                onClick={props.addCourse}
                sx={{ mt: 2 }}
                disabled={!isCourseFormValid}
            >
                Add Another Course
            </Button>
        </Box>
    );
}

SchoolCourseForm.propTypes = {
    coursesData: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    addCourse: PropTypes.func.isRequired,
    errors: PropTypes.array.isRequired,
    isCourseFormValid: PropTypes.bool,
};

export default SchoolCourseForm;
