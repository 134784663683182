import * as React from "react";

import { ALLOWED_FILE_TYPES, APPLICATION_STATUS_CHOICES, APPLICATION_YEAR_CHOICES } from "../../const";
import {
	Alert,
	Autocomplete,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import EdvantageAutocomplete from "../common/EdvantageAutocomplete";
import PropTypes from "prop-types";
import { addApplication } from "../../store/universityApplication.slice";
import debounce from 'lodash/debounce';
import { fetchLeads } from "../../store/lead.slice";
import { generateMenuItems } from "../../utils/Utils";
import useApplication from "../../hooks/useApplication";
import { useEdvantageContext } from "../../EdvantageProvider";

function AddUniversityApplicationDialog(props) {
	const dispatch = useDispatch();

	const leadState = useSelector((state) => state.lead);
	const { intakes, majorById, programById } = useEdvantageContext();
	const { applications } = useApplication();

	const [form, setForm] = React.useState({
		lead: props.lead?.id || "",
		intake: "",
		year: "",
		university: "",
		course: "",
		status: "",
		file: null,
	});
	const [filteredUniversities, setFilteredUniversities] = React.useState(
		props.universities
	);

	const getFilteredUniversities = (leadId, universities) => {
		const activeUniversityIds = applications
			.filter(
				(app) =>
					app.lead_id === leadId &&
					["Submitted", "Accepted", "Pending", "Conditional Offer", "Unconditional Offer"].includes(
						app.status
					)
			)
			.map((app) => app.university_id);

		// Filter out universities with active applications
		const filteredUniversities = universities.filter(
			(university) => !activeUniversityIds.includes(university.id)
		);
		return filteredUniversities;
	};

	React.useEffect(() => {
		if (props.lead) {
			// Filter out universities with active applications
			setFilteredUniversities(getFilteredUniversities(props.lead.id, props.universities));
		}
	}, [props.lead, props.universities]);

	const [formErrors, setFormErrors] = React.useState({});
	const [isFormValid, setIsFormValid] = React.useState(false);

	const [availableCourses, setAvailableCourses] = React.useState([]);

	const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

	React.useEffect(() => {
		if (form.university && form.intake) {
			const courses = filteredUniversities.find((uni) => uni.id === form.university).courses;
			setAvailableCourses(
				courses.filter((course) =>
					course.intakes.some((intake) => intake === form.intake)
				)
			);
		}
	}, [filteredUniversities, form.university, form.intake]);

	const handleSubmit = async (event) => {
		event.preventDefault();

		let errors = {};
		for (const field of Object.keys(form)) {
			const fieldErrors = validateForm(field, form[field]);
			errors = { ...errors, ...fieldErrors };
		}

		let isValid = true;
		for (let error of Object.values(errors)) {
			if (error) {
				isValid = false;
				break;
			}
		}

		setFormErrors(errors);
		setIsFormValid(isValid);

		if (isValid) {
			// Create FormData object
			const formData = new FormData();
			formData.append("lead", form.lead);
			formData.append("intake", form.intake);
			formData.append("year", form.year);
			formData.append("university", form.university);
			formData.append("university_course", form.course);
			formData.append("status", form.status);
			formData.append("uuid", props.uuid);

			// Append file if it exists
			if (form.file) {
				formData.append("offer_letter", form.file);
			}

			// Pass FormData to addApplication action
			dispatch(addApplication(formData)).then((response) => {
				if (response.type === "universityApplication/addApplication/fulfilled") {
					handleCloseDialog();
				}
			});
		}
	};

	const validateForm = (fieldName, value) => {
		let errors = {};

		switch (fieldName) {
			case "lead":
				errors.lead = value ? "" : "Lead is required";
				break;
			case "intake":
				errors.intake = value ? "" : "Intake is required";
				break;
			case "year":
				errors.year = value ? "" : "Year is required";
				break;
			case "university":
				errors.university = value ? "" : "University is required";
				break;
			case "course":
				errors.course = value ? "" : "Course is required";
				break;
			case "status":
				errors.status = value.trim() ? "" : "Status is required";
				break;
			case "file":
				if (["Conditional Offer", "Unconditional Offer"].includes(form.status) && !value) {
					errors.file = "Offer letter is required for this status";
				} else {
					errors.file = "";
				}
				break;
			default:
				break;
		}

		return errors;
	};

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		const newErrors = { ...formErrors };

		if (file) {
			if (file.size > MAX_FILE_SIZE) {
				newErrors.file = "File size exceeds the maximum limit.";
			} else if (!ALLOWED_FILE_TYPES.includes(file.type)) {
				newErrors.file = "Invalid file type. Please upload a PDF or image.";
			} else {
				delete newErrors.file; // Clear any previous file errors if the file is valid
			}

			// Update form state if no errors exist
			if (!newErrors.file) {
				setForm((prevForm) => ({ ...prevForm, file }));
			}
		} else {
			setForm((prevForm) => ({ ...prevForm, file: null }));
			newErrors.file = "Offer letter is required for this status";
		}

		setFormErrors(newErrors);

		let isValid = true;
		for (let error of Object.values(newErrors)) {
			if (error) {
				isValid = false;
				break;
			}
		}
		setIsFormValid(isValid);
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		if (name === "status" && !["Conditional Offer", "Unconditional Offer"].includes(value)) {
			setForm({ ...form, [name]: value, file: null });
		} else {
			setForm({ ...form, [name]: value });
		}
		const fieldErrors = validateForm(name, value);
		const newFormErrors = { ...formErrors, ...fieldErrors };

		let isValid = true;
		for (let error of Object.values(newFormErrors)) {
			if (error) {
				isValid = false;
				break;
			}
		}
		setIsFormValid(isValid);
		setFormErrors(newFormErrors);

		// If the lead changes, filter universities based on active applications
		if (name === "lead") {
			// Filter out universities with active applications
			setFilteredUniversities(getFilteredUniversities(value, props.universities));

			// Update form with filtered universities
			setForm({ ...form, lead: value, university: "", course: "" });
		}
	};

	const handleCloseDialog = (evt, reason) => {
		if (reason === "backdropClick") {
			return;
		}

		props.setOpenDialog(false);
		// Reset form state when dialog is closed
		setForm({
			lead: props.lead?.id || "",
			year: "",
			intake: "",
			university: "",
			course: "",
			status: "",
			file: null,
		});
		setFormErrors({});
		setIsFormValid(false);
	};

	// Debounced handleInputChange function
	const debouncedFetchLeads = React.useCallback(
		debounce((newInputValue) => {
			dispatch(fetchLeads({ page: 1, limit: 10, filters: { name: newInputValue } }));
		}, 300), // 300ms delay
		[dispatch]
	);

	const handleInputChange = (event, newInputValue) => {
		if (event?.type === "change") {
			debouncedFetchLeads(newInputValue); // Use debounced function
		}
	};

	return (
		<Dialog
			open={props.open}
			onClose={handleCloseDialog}
			aria-labelledby="form-dialog-title"
			sx={{
				"& .MuiDialog-paper": {
					minWidth: {
						xs: "100%", // full width on small screens
						sm: "600px", // 600px on medium screens and up
					},
				},
			}}
		>
			<DialogTitle id="form-dialog-title">Add Application</DialogTitle>
			<Divider />
			<DialogContent>
				<DialogContentText>
					Please fill out the details below to add a new application.
				</DialogContentText>
				<Box
					component="form"
					noValidate
					onSubmit={handleSubmit}
					sx={{ mt: 3 }}
				>
					{props.applicationState.error &&
						props.applicationState.action === "addApplication" && (
							<Alert
								sx={{ width: "100%", mt: 2, mb: 2 }}
								severity="error"
							>
								{props.applicationState.error}
							</Alert>
						)}
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Autocomplete
								label="Lead"
								value={props.lead || leadState.leads.find((option) => option.id === form.lead) || null}
								onChange={(event, newValue) => {
									handleChange({
										target: {
											name: "lead",
											value: newValue.id,
											type: "text",
										},
									});
								}}
								options={props.lead ? [props.lead] : leadState.leads}
								getOptionLabel={(option) => option.student?.full_name || ""}
								onInputChange={handleInputChange}  // Trigger search when user types
								loading={leadState.loading}  // Show loading indicator when fetching
								noOptionsText="No leads found"  // Custom message if no options match
								renderOption={(props, option) => (
									<li {...props} key={option.id}>
										{option.student?.full_name || ""}
									</li>
								)}
								disabled={Boolean(props.lead)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Search Leads"
										variant="outlined"
										error={Boolean(formErrors.lead)}
										helperText={formErrors.lead}
										autoComplete="off"
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<>
													{leadState.loading ? <CircularProgress color="inherit" size={20} /> : null}
													{params.InputProps.endAdornment}
												</>
											),
										}}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={6}>
							<EdvantageAutocomplete
								name="intake"
								label="Intake"
								value={form.intake}
								onChange={handleChange}
								error={Boolean(formErrors.intake)}
								helperText={formErrors.intake}
								options={intakes.filter((intake) => intake.is_active === true)}
								getOptionLabel={(option) => option.text}
								getOptionValue={(newValue) => newValue.id}
								isOptionEqualToValue={(option, value) =>
									option.id === value
								}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								select
								fullWidth
								label="Year"
								name="year"
								value={form.year}
								onChange={handleChange}
								error={Boolean(formErrors.year)}
								helperText={formErrors.year}
							>
								{generateMenuItems(APPLICATION_YEAR_CHOICES)}
							</TextField>
						</Grid>
						<Grid item xs={12}>
							<EdvantageAutocomplete
								name="university"
								label="University"
								value={form.university}
								onChange={handleChange}
								error={Boolean(formErrors.university)}
								helperText={formErrors.university}
								options={filteredUniversities}
								getOptionLabel={(option) => option.name}
								getOptionValue={(newValue) => newValue.id}
								isOptionEqualToValue={(option, value) =>
									option.id === value
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<EdvantageAutocomplete
								name="course"
								label="Course"
								value={form.course}
								onChange={handleChange}
								error={Boolean(formErrors.course)}
								helperText={formErrors.course}
								options={availableCourses}
								getOptionLabel={(option) =>
									`${programById.get(option.program).name} - ${majorById.get(option.major).name}`
								}
								getOptionValue={(newValue) => newValue.id}
								isOptionEqualToValue={(option, value) =>
									option.id === value
								}
								noOptionsText={
									availableCourses.length === 0
										? "No courses found for the selected intake and university."
										: "No options available"
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								select
								fullWidth
								label="Status"
								name="status"
								value={form.status}
								onChange={handleChange}
								error={Boolean(formErrors.status)}
								helperText={formErrors.status}
							>
								{generateMenuItems(APPLICATION_STATUS_CHOICES)}
							</TextField>
						</Grid>
						{["Conditional Offer", "Unconditional Offer"].includes(form.status) && (
							<Grid item xs={12}>
								<Button variant="outlined" component="label" fullWidth>
									Upload Offer Letter
									<input type="file" hidden onChange={handleFileChange} />
								</Button>
								{form.file && <p>{form.file.name}</p>}
								{formErrors.file && <p style={{ fontSize: "0.75rem", color: '#d32f2f' }}>{formErrors.file}</p>}
							</Grid>
						)}
					</Grid>
					<DialogActions sx={{ justifyContent: "flex-end" }}>
						<Button
							type="button"
							variant="contained"
							color="secondary"
							sx={{ mt: 3, mb: 2 }}
							onClick={handleCloseDialog}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							sx={{ mt: 3, mb: 2 }}
							disabled={!isFormValid}
						>
							Add Application
						</Button>
					</DialogActions>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

AddUniversityApplicationDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpenDialog: PropTypes.func.isRequired,
	applicationState: PropTypes.object.isRequired,
};

export default AddUniversityApplicationDialog;
