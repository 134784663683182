import * as React from "react";

import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
	Typography,
} from "@mui/material";
import { isValidCurrency, isValidFee, isValidURL } from "../../utils/Utils";
import { useDispatch, useSelector } from "react-redux";

import EdvantageAutocomplete from "../common/EdvantageAutocomplete";
import GenericMultiSelect from "../common/GenericMultiSelect";
import { PARTNERSHIP_STATUS_CHOICES } from "../../const";
import PropTypes from "prop-types";
import SponsorAutoComplete from "./SponsorAutoComplete";
import { updateUniversityCourse } from "../../store/university.slice";
import { useEdvantageContext } from "../../EdvantageProvider";

function EditCourseDialog(props) {
	const dispatch = useDispatch();

	const { open, course, handleClose } = props;
	const universityState = useSelector((state) => state.university);
	const { currencies, intakes, majors, programs, uuid, majorById, programById } = useEdvantageContext();

	const initialFormState = {
		major: course.major,
		program: course.program,
		application_method: course.application_method ?? "",
		status: course.status,
		partnership_status: course.partnership_status ?? "",
		intakes: course.intakes,
		is_sponsored: course.is_sponsored,
		sponsors: course.sponsors,
		fee: course.fee ?? "",
		currency: course.currency ?? "",
		course_url: course.course_url ?? "",
	};

	const [form, setForm] = React.useState(initialFormState);
	const [formErrors, setFormErrors] = React.useState("");
	const [isFormValid, setIsFormValid] = React.useState(true);
	const [isFormChanged, setIsFormChanged] = React.useState(false);

	React.useEffect(() => {
		if (open) {
			setForm({
				major: course.major,
				program: course.program,
				application_method: course.application_method ?? "",
				status: course.status,
				partnership_status: course.partnership_status ?? "",
				intakes: course.intakes,
				is_sponsored: course.is_sponsored,
				sponsors: course.sponsors,
				fee: course.fee ?? "",
				currency: course.currency ?? "",
				course_url: course.course_url ?? "",
			});
		}
	}, [open, course]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		dispatch(updateUniversityCourse({ id: course.id, course: form, uuid })).then(
			(response) => {
				if (
					response.type ===
					"university/updateUniversityCourse/fulfilled"
				) {
					handleClose(false);
					setIsFormChanged(false);
				}
			}
		);
	};

	const validateCourseForm = (form) => {
		const errors = {};

		// Required fields and their corresponding error messages
		const requiredFields = {
			major: "Major is required",
			program: "Program is required",
			status: "Merit status is required",
		};

		// Validate required fields
		for (const field in requiredFields) {
			if (!form[field]) {
				errors[field] = requiredFields[field];
			}
		}

		if (form.intakes.length === 0) {
			errors.intakes = "At least one intake is required";
		}

		// Validate fee and currency together
		if ((form.fee && !form.currency) || (!form.fee && form.currency)) {
			if (!form.fee) {
				errors.fee = "Fee is required when currency is set";
			}
			if (!form.currency) {
				errors.currency = "Currency is required when fee is set";
			}
		}

		// Validate the fee field if it's present
		if (form.fee && !isValidFee(form.fee)) {
			errors.fee = "Invalid fee amount";
		}

		// Validate the currency field if it's present
		if (form.currency && !isValidCurrency(form.currency)) {
			errors.currency = "Invalid currency";
		}

		if (form.course_url && !isValidURL(form.course_url)) {
			errors.course_url = "Invalid course URL";
		}

		if (form.is_sponsored && form.sponsors.length === 0) {
			errors.sponsors = "Sponsor is required when the course is sponsored";
		}

		return errors;
	};

	const validateCourseField = (name, value) => {
		let error = "";
		if (!value) {
			if (name === "major") error = "Major is required";
			if (name === "program") error = "Program is required";
			if (name === "intake") error = "Intake is required";
			if (name === "status") error = "Status is required";
		}

		if (name === "intakes" && value.length === 0) error = "At least one intake is required";

		if (name === "fee") {
			if (value && !isValidFee(value)) {
				error = "Invalid fee amount";
			} else if (value && !isValidCurrency(form.currency)) {
				setFormErrors((prevErrors) => ({
					...prevErrors,
					currency: "Currency is required",
				}));
			} else if (!value && isValidCurrency(form.currency)) {
				error = "Fee is required when currency is set";
			} else if (!value && !isValidCurrency(form.currency)) {
				setFormErrors((prevErrors) => ({
					...prevErrors,
					currency: "",
				}));
			}
		}

		if (name === "currency") {
			if (value && !isValidCurrency(value)) {
				error = "Invalid currency";
			} else if (value && !isValidFee(form.fee)) {
				setFormErrors((prevErrors) => ({
					...prevErrors,
					fee: "Fee is required when currency is set",
				}));
			} else if (!value && isValidFee(form.fee)) {
				error = "Currency is required when fee is set";
			} else if (!value && !isValidFee(form.fee)) {
				setFormErrors((prevErrors) => ({
					...prevErrors,
					fee: "",
				}));
			}
		}

		if (name === "course_url") {
			if (value && !isValidURL(value)) {
				error = "Invalid URL";
			}
		}

		setFormErrors((prevErrors) => ({
			...prevErrors,
			[name]: error,
		}));
	};

	const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		const newForm = {
			...form,
			[name]: type === "checkbox" ? checked : value,
		};

		// Reset the sponsor field if is_sponsored is toggled off
		if (name === "is_sponsored" && !checked) {
			newForm.sponsors = []; // Clear the sponsor field
			setFormErrors((prevErrors) => ({
				...prevErrors,
				sponsors: "", // Clear sponsor validation error
			}));
		}

		setForm(newForm);
		validateCourseField(name, value);
		setIsFormValid(Object.keys(validateCourseForm(newForm)).length === 0);
		setIsFormChanged(
			JSON.stringify(newForm) !== JSON.stringify(initialFormState)
		);
	};

	const handleCloseDialog = () => {
		handleClose(false);
		setFormErrors("");
		setIsFormValid(true);
		setIsFormChanged(false);
	};

	return (
		<Dialog
			open={open}
			onClose={handleCloseDialog}
			aria-labelledby="form-dialog-title"
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle id="form-dialog-title">
				Edit Course:{" "}
				<em>
					{majorById.get(course.major).name} - {programById.get(course.program).name}
				</em>
			</DialogTitle>
			<DialogContent>
				<Box sx={{ mt: 3 }}>
					{universityState.error && (
						<Alert
							sx={{ width: "100%", mt: 2, mb: 2 }}
							severity="error"
						>
							{universityState.error}
						</Alert>
					)}
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<EdvantageAutocomplete
								name="major"
								label="Major"
								value={form.major}
								onChange={handleChange}
								error={Boolean(formErrors?.major)}
								helperText={formErrors?.major}
								options={majors}
								getOptionLabel={(option) => option.name}
								getOptionValue={(newValue) => newValue.id}
								isOptionEqualToValue={(option, value) =>
									option.id === value
								}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<EdvantageAutocomplete
								name="program"
								label="Program"
								value={form.program}
								onChange={handleChange}
								error={Boolean(formErrors?.program)}
								helperText={formErrors?.program}
								options={programs}
								getOptionLabel={(option) => option.name}
								getOptionValue={(newValue) => newValue.id}
								isOptionEqualToValue={(option, value) =>
									option.id === value
								}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControl fullWidth>
								<InputLabel id="application-select-label">
									Application Method
								</InputLabel>
								<Select
									labelId="application-select-label"
									id="application-select"
									value={form.application_method}
									label="Application Method"
									name="application_method"
									onChange={handleChange}
								>
									<MenuItem key={0} value={"UCAS"}>
										UCAS
									</MenuItem>
									<MenuItem key={1} value={"Direct"}>
										Direct
									</MenuItem>
									<MenuItem key={2} value={"Pathway"}>
										Pathway
									</MenuItem>
									<MenuItem key={3} value={"Other"}>
										Other
									</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6}>
							<GenericMultiSelect
								name="intakes"
								label="Intakes"
								value={form.intakes}
								onChange={handleChange}
								error={Boolean(formErrors?.intakes)}
								helperText={formErrors?.intakes}
								options={intakes}
								getOptionLabel={(option) => option.season}
								optionValueKey="id"
								limitTags={2}
								required
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControl fullWidth required>
								<InputLabel id="status-select-label">
									Merit Status
								</InputLabel>
								<Select
									labelId="status-select-label"
									id="status-select"
									value={form.status}
									label="Status"
									name="status"
									onChange={handleChange}
								>
									<MenuItem key={0} value={"Unknown"}>
										Unknown
									</MenuItem>
									<MenuItem key={1} value={"Merit"}>
										Merit
									</MenuItem>
									<MenuItem key={2} value={"Non-Merit"}>
										Non-Merit
									</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								select
								fullWidth
								label="Partnership Status"
								name="partnership_status"
								value={form.partnership_status}
								onChange={handleChange}
							>
								{PARTNERSHIP_STATUS_CHOICES.map(
									(option, index) => (
										<MenuItem
											key={index}
											value={option.value}
										>
											{option.label}
										</MenuItem>
									)
								)}
							</TextField>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Fee"
								name="fee"
								type="number"
								value={form.fee}
								onChange={handleChange}
								error={Boolean(formErrors?.fee)}
								helperText={formErrors?.fee}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControl
								fullWidth
								error={Boolean(formErrors?.currency)}
							>
								<InputLabel id="currency-select-label">
									Currency
								</InputLabel>
								<Select
									labelId="currency-select-label"
									id="currency-select"
									value={form.currency}
									label="Currency"
									name="currency"
									onChange={handleChange}
								>
									<MenuItem value="">
										<em>None</em>
									</MenuItem>
									{currencies.map((currency) => (
										<MenuItem
											key={currency.code}
											value={currency.id}
										>
											{currency.name} ({currency.symbol})
										</MenuItem>
									))}
								</Select>
								<FormHelperText>
									{formErrors?.currency}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label="Course URL"
								name="course_url"
								type="url"
								value={form.course_url}
								onChange={handleChange}
								error={Boolean(formErrors?.course_url)}
								helperText={formErrors?.course_url}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography
								component="div"
								variant="body1"
								gutterBottom
							>
								Sponsored
								<Switch
									checked={form.is_sponsored}
									onChange={handleChange}
									name="is_sponsored"
									inputProps={{ "aria-label": "controlled" }}
								/>
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6}>
							<SponsorAutoComplete
								name="sponsors"
								label="Sponsors"
								value={form.sponsors}
								onChange={handleChange}
								error={Boolean(formErrors?.sponsors)}
								helperText={formErrors?.sponsors}
								disabled={!form.is_sponsored}
							/>
						</Grid>
					</Grid>
					<DialogActions sx={{ justifyContent: "flex-start", px: 0 }}>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							sx={{ mt: 3, mb: 2 }}
							disabled={!isFormValid || !isFormChanged}
							onClick={handleSubmit}
						>
							Save
						</Button>
						<Button
							type="button"
							variant="contained"
							color="error"
							sx={{ mt: 3, mb: 2 }}
							onClick={handleCloseDialog}
						>
							Cancel
						</Button>
					</DialogActions>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

EditCourseDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	course: PropTypes.object.isRequired,
};

export default EditCourseDialog;
