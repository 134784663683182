import * as React from "react";

import {
	ClickAwayListener,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
} from "@mui/material";
import {
	IconLogout,
	IconSettings,
} from "@tabler/icons-react";
import { styled, useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Avatar from '@mui/material/Avatar';
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MainCard from "../extended/MainCard";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsPopper from "../AppBar/NotificationsPopper";
import Paper from "@mui/material/Paper";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Popper } from "@mui/base/Popper";
import Toolbar from "@mui/material/Toolbar";
import Transitions from "../extended/Transitions";
import Typography from "@mui/material/Typography";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { fetchNotifications } from "../../store/notification.slice";
import { history } from "../../helper/history";
import { logout } from "../../store/auth.slice";
import useMediaQuery from "@mui/material/useMediaQuery";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));


function AppBarComponent({ open, selectedListItem, toggleDrawer, setUrlParams }) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const dispatch = useDispatch();

	const authUser = useSelector(x => x.auth.employee.profile);
	const customization = useSelector((state) => state.customization);
	const notificationState = useSelector(state => state.notification);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [openAccountPopper, setOpenAccountPopper] = React.useState(false);
	const [openNotifications, setOpenNotifications] = React.useState(false);
	const [selectedIndex, setSelectedIndex] = React.useState(-1);

	const anchorRef = React.useRef(null);
	const prevOpen = React.useRef(openAccountPopper);

	React.useEffect(() => {
		if (prevOpen.current === true && openAccountPopper === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = openAccountPopper;
	}, [openAccountPopper]);

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpenAccountPopper(false);
	};

	const handleLogout = async () => {
		dispatch(logout());
	};

	const handleListItemClick = (event, index, route = "") => {
		setSelectedIndex(index);
		handleClose(event);

		if (route && route !== "") {
			history.navigate(route);
		}
	};

	const handleToggle = () => {
		setOpenAccountPopper((prevOpen) => !prevOpen);
	};

	const handleToggleNotifications = (event) => {
		setAnchorEl(event.currentTarget);
		setOpenNotifications((prevOpen) => !prevOpen);
	};

	const handleCloseNotifications = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpenNotifications(false);
	};

	const navigateToChat = () => {
		history.navigate("/chat");
	};

	const handleScroll = (e) => {
		const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
		if (bottom && notificationState.has_more) {
			dispatch(fetchNotifications());
		}
	};

	return (
		<AppBar position="absolute" open={open} sx={{ boxShadow: "none" }}>
			<Toolbar
				sx={{
					pr: "24px", // keep right padding when drawer closed
					backgroundColor: "#f9fafb",
					display: "flex",
					justifyContent: isMobile ? "space-between" : "flex-end",
				}}
			>
				<IconButton
					edge="start"
					color="primary"
					aria-label="open drawer"
					onClick={toggleDrawer}
					sx={{
						marginRight: "36px",
						...(open && !isMobile && { display: "none" }),
					}}
				>
					<MenuIcon />
				</IconButton>
				<Typography
					component="h1"
					variant="h6"
					color="primary"
					noWrap
					sx={{ flexGrow: 1 }}
				>
					{selectedListItem}
				</Typography>
				<IconButton color="primary" onClick={navigateToChat}>
					<WhatsAppIcon />
				</IconButton>
				<IconButton color="primary" ref={anchorRef} onClick={handleToggleNotifications}>
					<Badge badgeContent={notificationState.unseen_count} color="secondary">
						<NotificationsIcon />
					</Badge>
				</IconButton>
				<NotificationsPopper
					theme={theme}
					open={openNotifications}
					anchorEl={anchorEl}
					handleClose={handleCloseNotifications}
					notificationState={notificationState}
					handleScroll={handleScroll}
					setUrlParams={setUrlParams}
				/>
				<IconButton
					edge="end"
					color="primary"
					aria-label="account"
					ref={anchorRef}
					onClick={handleToggle}
				>
					<AccountCircleIcon />
				</IconButton>
				<Popper
					placement="bottom-end"
					open={openAccountPopper}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					disablePortal
					popperOptions={{
						modifiers: [
							{
								name: "offset",
								options: {
									offset: [0, 14],
								},
							},
						],
					}}
				>
					{({ TransitionProps }) => (
						<Transitions
							in={openAccountPopper}
							{...TransitionProps}
						>
							<Paper>
								<ClickAwayListener onClickAway={handleClose}>
									<MainCard
										border={false}
										elevation={16}
										content={false}
										boxShadow
										shadow={theme.shadows[16]}
									>
										<Box sx={{ padding: "20px 10px 8px 16px" }}>
											<Grid container spacing={0}>
												<Grid size={10} width="80%">
													<Stack
														direction="row"
														spacing={0.5}
														alignItems="center"
													>
														<Typography
															variant="h4"
															sx={{
																fontWeight: 600,
																fontFamily: `'Roboto', sans-serif !important`,
																fontSize:
																	"1rem !important",
																color: "rgb(18, 25, 38) !important",
																lineHeight: `1.235 !important`,
															}}
														>
															{authUser.user.first_name || ""} {authUser.user.last_name || ""}
														</Typography>
													</Stack>
													<Stack
														direction="row"
														spacing={0.5}
														alignItems="center"
													>
														<Typography
															variant="h4"
															sx={{
																fontWeight: 300,
																fontFamily: `'Roboto', sans-serif !important`,
																fontSize:
																	"1rem !important",
																color: "rgb(18, 25, 38) !important",
																lineHeight: `1.235 !important`,
															}}
														>
															{authUser.user.email || ""}
														</Typography>
													</Stack>
													<Typography variant="subtitle2">
														Groups: {authUser.user.groups.map(group => group.name)}
													</Typography>
												</Grid>
												<Grid size={2} sx={{ p: "0px 5px 0px 0px" }}>
													<div>
														<Avatar sx={{ bgcolor: "ff0000", height: "50px", width: "50px" }} aria-label="recipe">
															{authUser.user.first_name[0] || ""}{authUser.user.last_name[0] || ""}
														</Avatar>
													</div>
												</Grid>
											</Grid>
										</Box>
										<Divider
											sx={{
												margin: 0,
												flexShrink: 0,
												borderWidth:
													"0px 0px thin",
												borderStyle: "solid",
												borderColor:
													"rgb(227, 232, 239)",
												opacity: 1,
											}}
										/>
										<PerfectScrollbar
											style={{
												height: "100%",
												maxHeight:
													"calc(100vh - 250px)",
												overflowX: "hidden",
											}}
										>
											<Box sx={{ p: 2 }} style={{ padding: "0px 5px" }}>

												<List
													component="nav"
													sx={{
														padding: "0px",
														width: "100%",
														maxWidth: 350,
														minWidth: 300,
														backgroundColor:
															theme.palette
																.background
																.paper,
														borderRadius: "10px",
														[theme.breakpoints.down(
															"md"
														)]: {
															minWidth: "100%",
														},
														"& .MuiListItemButton-root":
														{
															mt: 0.5,
														},
													}}
												>
													<ListItemButton
														sx={{
															borderRadius: `${customization.borderRadius}px`,
														}}
														selected={
															selectedIndex === 0
														}
														onClick={(event) =>
															handleListItemClick(
																event,
																0,
																"/settings"
															)
														}
													>
														<ListItemIcon>
															<IconSettings
																stroke={1.5}
																size="1.3rem"
															/>
														</ListItemIcon>
														<ListItemText
															primary={
																<Typography variant="body2">
																	Profile
																</Typography>
															}
														/>
													</ListItemButton>

													<ListItemButton
														sx={{
															borderRadius: `${customization.borderRadius}px`,
														}}
														selected={
															selectedIndex === 4
														}
														onClick={handleLogout}
													>
														<ListItemIcon>
															<IconLogout
																stroke={1.5}
																size="1.3rem"
															/>
														</ListItemIcon>
														<ListItemText
															primary={
																<Typography variant="body2">
																	Logout
																</Typography>
															}
														/>
													</ListItemButton>
												</List>
											</Box>
										</PerfectScrollbar>
									</MainCard>
								</ClickAwayListener>
							</Paper>
						</Transitions>
					)}
				</Popper>
			</Toolbar>
		</AppBar>
	);
}

export default AppBarComponent;
